import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {getCategoryGroups, getPredefinedProductByCategory} from "../redux/action/product";
import {CategoryGroup, PredefinedProduct, productActions} from "../redux/productSlice";
import {RootState} from "../redux/store";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Footer from "../components/Footer";
import {useNavigate, useSearchParams} from "react-router-dom";
import isEmpty from "../utils/isEmpty";
import {Helmet} from "react-helmet";

const PredefinedProductBox = (props: { product: PredefinedProduct }) => {

    const navigate = useNavigate();
    const theme = useTheme();

    return (

        <Box
            sx={{mr: 2, pb: 2}}
        >
            <Paper
                sx={styles(theme).paper}
            >
                <Stack
                    sx={{p: 1}}
                    alignItems="center"
                >
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, fontWeight: "bold", fontSize: 16, textAlign: "center",
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 14,
                            },
                        }}
                    >
                        {props.product.name}
                    </Typography>

                    <img
                        src={props.product.fileURL}
                        style={{width: "80%", borderRadius: 8}}
                    />

                    <Button
                        variant="contained"
                        sx={{mt: 2}}
                        onClick={() => navigate("/termek?productId=" + props.product.id)}
                    >
                        Kiválaszt
                    </Button>
                </Stack>
            </Paper>
        </Box>
    );
}

const PredefinedProductRows = ({categoryGroups}: { categoryGroups: CategoryGroup[] }) => {

    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <>
            <Stack
                direction='row'
                justifyContent='center'
                sx={{width: '100%'}}
            >
                <Typography
                    variant='subtitle2'
                    fontWeight='bold'
                    fontSize={22}
                    sx={{mt: 1, mb: 1,
                        [theme.breakpoints.down('sm')]: {
                            fontSize: 20,
                        },
                    }}
                >
                    Előre megtervezett minták
                </Typography>
            </Stack>

            {categoryGroups?.map((category, index) => {
                return (
                    <Stack
                        direction='column'
                        sx={{width: '100%'}}
                        key={index}
                    >

                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ml: 4, mr: 4, mt: 1}}
                            alignItems='center'
                        >
                            <Typography
                                variant='subtitle2'
                                fontWeight='bold'
                                sx={{
                                    fontSize: 18,
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: 16,
                                    }
                                }}

                            >
                                {category.categoryName}
                            </Typography>

                            <Button
                                onClick={() => navigate("/termekek?category=" + category.category)}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <Typography
                                        sx={{mr: 1,
                                            [theme.breakpoints.down('sm')]: {
                                                fontSize: 14,
                                            }
                                        }}
                                    >
                                        Továbbiak megtekintése
                                    </Typography>
                                    <ArrowRightAltIcon/>
                                </Stack>
                            </Button>
                        </Stack>

                        <Stack
                            direction="row"
                            sx={{pb: 1, pl: 2,
                                overflowX: 'auto',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                    height: '8px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: "rgba(32,104,104,0.32)",
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(32,104,104,0.82)',
                                    borderRadius: '10px'
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#206868',
                                }
                            }}
                            justifyContent='flex-start'
                        >
                            {category.products.map((product, index) => {
                                return (
                                    <PredefinedProductBox product={product} key={index}/>
                                );
                            })}
                        </Stack>
                    </Stack>
                );
            })}
        </>
    );
}

const PredefinedProductAllInACategory = ({category}: { category: string | null }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const selectedCategory: CategoryGroup = useSelector<RootState, CategoryGroup>(rootState => rootState.product.selectedCategory);

    useEffect(() => {
        if (category != null) {
            dispatch(getPredefinedProductByCategory(category))
        }

        return () => {
            dispatch(productActions.clearSelectedCategory());
        }
    }, [category, dispatch])

    return (
        <Grid container justifyContent="center">

            <Helmet>
                <title>{`${selectedCategory?.categoryName !== undefined ? selectedCategory?.categoryName : ""} kategória - Egyedi póló, bögre és ajándék az A.I. segítségével - AI Merch`}</title>
                <meta name="description" content={`Előre kitalált ${selectedCategory?.categoryName !== undefined ? selectedCategory?.categoryName?.toLowerCase() : ""} termékek. Egyedi póló, bögre és egyéb ajándékok tervezése és nyomtatása az AI Merch segítségével.`} />
            </Helmet>

            <Stack
                direction='row'
                justifyContent='center'
                sx={{width: '100%', mb: 1, mt: 1, flexWrap: 'wrap'}}
                alignItems="center"
            >

                <Box
                    sx={{
                        position: "absolute",
                        left: 10,
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        }
                    }}
                >
                    <Button
                        onClick={() => navigate("/termekek")}
                    >
                        <KeyboardBackspaceIcon />

                        <Stack direction='row' alignItems='center'>
                            <Typography
                                sx={{ml: 1}}
                            >
                                Vissza az összes kategóriához
                            </Typography>
                        </Stack>
                    </Button>
                </Box>

                <Typography
                    variant='subtitle2'
                    fontWeight='bold'
                    fontSize={22}
                    sx={{mt: 1, mb: 1,
                        [theme.breakpoints.down('sm')]: {
                            fontSize: 20,
                        }
                    }}
                >
                    Előre megtervezett minták
                </Typography>
            </Stack>

            {!isEmpty(selectedCategory) && selectedCategory.products.map(product => {
                return (
                    <PredefinedProductBox product={product}/>
                );
            })}
        </Grid>
    );
}

const ProductsPage = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams]: [URLSearchParams, Function] = useSearchParams();

    const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>("");

    const categoryGroups: CategoryGroup[] = useSelector<RootState, CategoryGroup[]>(rootState => rootState.product.categoryGroups);

    useEffect(() => {
        const category: string | null = searchParams.get("category");
        setSelectedCategoryName(category);
    }, [searchParams])

    useEffect(() => {
        dispatch(getCategoryGroups())
    }, [dispatch])

    return (
        <Grid container className='container' sx={{
            color: theme.palette.text.primary,
            display: 'flex',
            height: 'calc(100vh - 64px)',
        }}>

            <Helmet>
                <title>Termékek - Egyedi póló, bögre és ajándékok az A.I. segítségével - AI Merch</title>
                <meta name="description" content="Előre kitalált és elkészített egyedi póló, bögre és egyéb ajándékok az A.I. segítségével." />
            </Helmet>

            {isEmpty(selectedCategoryName) && (
                <PredefinedProductRows categoryGroups={categoryGroups}/>
            )}

            {!isEmpty(selectedCategoryName) && (
                <PredefinedProductAllInACategory category={selectedCategoryName}/>
            )}

            <Stack
                sx={{width: '100%', mt: 4}}
            >
                <Footer/>
            </Stack>
        </Grid>
    );
};

const styles = (theme: any) => ({
    paper: {
        borderRadius: 5,
        width: 270,
        height: 330,
        [theme.breakpoints.down('sm')]: {
            width: 180,
            height: 260,
        },
    },
});

export default ProductsPage;