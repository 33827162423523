import axios, {AxiosResponse} from 'axios'
import {CategoryGroup, PredefinedProduct, Product, productActions} from '../productSlice'


export const getProducts = (): any => {
    return async (dispatch: any) => {
        axios.get<Product[]>('/product').then(res => {
            if (res.status !== 200) {
                throw new Error('Getting products failed')
            }
            dispatch(productActions.setProductState({products: res.data}))
        }).catch(error => {
            console.log(error)
        })
    }
}

export interface CategoryGroupResponse {
    categories: CategoryGroup[]
}

export const getCategoryGroups = (): any => {
    return async (dispatch: any) => {
        axios
            .get<CategoryGroupResponse>('/product/predefined')
            .then((res: AxiosResponse<CategoryGroupResponse>) => {
                dispatch(productActions.setCategoryGroup(res.data.categories))
            }).catch(error => {
                console.log(error)
            })
    }
}

export const getPredefinedProductByCategory = (category: string): any => {
    return async (dispatch: any) => {
        axios
            .get('/product/predefined/' + category)
            .then((res: AxiosResponse<CategoryGroup>) => {
                dispatch(productActions.setSelectedCategory(res.data))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getSelectedProduct = (productId: string): any => {
    return async (dispatch: any) => {
        axios
            .get('/product/' + productId)
            .then((res: AxiosResponse<PredefinedProduct>) => {
                dispatch(productActions.setSelectedProduct(res.data))
            })
            .catch(error => {

            })
    }
}

