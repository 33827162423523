import React, {useState} from 'react';
import {Box, Button, Divider, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {orderActions, OrderItem} from "../../redux/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import OrderItems from "./OrderItems";
import CouponCodeDialog from "./CouponCodeDialog";
import isEmpty from "../../utils/isEmpty";
import {useTheme} from "@mui/material/styles";

const ProductOverview = ({ increaseOrderStep }: { increaseOrderStep: () => void }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const couponCode: string = useSelector<RootState, string>(state => state.order.couponCode);
    const [couponCodeOpen, setCouponCodeOpen] = useState<boolean>(false);

    const cartSize: number = useSelector<RootState, OrderItem[]>(state => state.order.items).length;

    const handleAddCouponCode = () => {
        setCouponCodeOpen(true);
    }

    return (
        <React.Fragment>
            {cartSize === 0 && (
                <Grid item xs={12} sx={{width: '100%', marginTop: 12, textAlign: 'center'}}>
                    <Typography
                        variant="h5"
                    >
                        Úgy látszik még üres a kosarad. Tervezz egy pólót.
                    </Typography>
                    <Box sx={{marginTop: 2}}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                navigation('/tervezes')
                            }}
                        >
                            <Typography sx={{
                                color: 'white',
                            }} fontSize={20} fontWeight={600} fontFamily={'Lexend'}>
                                Tervezek
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            )}

            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>

                {cartSize > 0 && (
                    <>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            sx={{width: '80%', position: 'relative'}}
                        >
                            <Typography
                                variant='h5'
                                sx={{fontWeight: 'bold'}}
                            >
                                Kosár tartalma
                            </Typography>

                            <Button
                                sx={{position: 'absolute', right: 20,
                                    [theme.breakpoints.down('sm')]: {
                                        top: 24,
                                    }
                                }}
                                onClick={handleAddCouponCode}
                            >
                                {isEmpty(couponCode) ? (
                                    "Kuponkód megadása"
                                ) : (
                                    couponCode
                                )}
                            </Button>
                        </Stack>

                        <Grid item xs={12}
                            sx={{pt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', pl: 4, pr: 4,
                                [theme.breakpoints.down('sm')]: {
                                    width: '100%',
                                    mt: 2
                                }
                            }}
                        >
                             <OrderItems textFontSize={14} titleFontSize={18} buttonFontSize={15} smallDesign={false} orderButtonHandler={increaseOrderStep}/>
                        </Grid>
                    </>
                )}

            </Grid>

            <CouponCodeDialog open={couponCodeOpen} onClose={() => setCouponCodeOpen(false)} />
        </React.Fragment>
    );
};

export default ProductOverview;
