import {Grid, useTheme} from "@mui/material"
import './PlanningPage.css'
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useSearchParams} from "react-router-dom"
import {RootState} from "../redux/store"
import {GenerationState, planActions} from "../redux/planSlice"
import {generateImages} from "../redux/action/generate"
import {OrderAlertDialog} from "../components/planning/OrderAlertDialog"
import {PlanningFooter} from "../components/planning/PlanningFooter"
import {getProducts} from "../redux/action/product"
import ProductDesignWindow from "../components/planning/ProductDesignWindow";
import {Helmet} from "react-helmet";
import {CanvasProvider} from "../components/planning/CanvasContext";

const PlaningPage = () => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const [searchParams]: [URLSearchParams, Function] = useSearchParams()

    const [orderAlertOpen, setOrderAlertOpen] = useState(false)

    const generation = useSelector((state: RootState) => state.plan.generation)
    const premiumGeneration = useSelector((state: RootState) => state.plan.premiumGeneration)

    const generationState = useSelector((state: RootState) => state.plan.generationState)
    const premiumGenerationState = useSelector((state: RootState) => state.plan.premiumGenerationState)


    useEffect(() => {
        var fb = document.getElementById("hud");

        if(fb) fb.setAttribute("style", "visibility:hidden");

        return () => {
            var fb = document.getElementById("hud");
            if(fb) fb.setAttribute("style", "visibility:visible");
        }
    }, [])

    useEffect(() => {
        const generationId: string | null = searchParams.get('generationId')
        const generationIdPremium: string | null = searchParams.get('generationIdPremium')
        // if (generation?.generationId !== generationId && premiumGeneration?.generationId !== premiumGeneration.generationId) {
            dispatch(generateImages('', {generationId}))
            dispatch(generateImages('', {generationId: generationIdPremium, premium: true}))
        // }
    }, [dispatch, searchParams]);

    useEffect(() => {

        if(generationState === GenerationState.Done && premiumGenerationState === GenerationState.Done) {
            // @ts-ignore
            const url = new URL(window.location);

            if (generation.generationId) {
                url.searchParams.set("generationId", generation.generationId);
            }
            if (premiumGeneration.generationId) {
                url.searchParams.set("generationIdPremium", premiumGeneration.generationId);
            }

            window.history.pushState({}, "", url);
        }

    }, [generation.generationId, generationState, premiumGeneration.generationId, premiumGenerationState])

    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])

    const reset = () => {
        dispatch(planActions.resetState())
    }

    return (
        <Grid container sx={{
            color: theme.palette.text.primary,
        }}>

            <Helmet>
                <title>AI Tervező - Póló tervezés - Egyedi póló, bögre és ajándékok - AI Merch</title>
                <meta name="description" content="Használd egyedi mesterséges intelligencia tervezőnket, hogy nyomtass teljesen egyedi póló, egyedi bögre vagy egyéb egyedi ajándék termékeket. Az AI Merch mesterséges intelligencia tervezőjével." />
            </Helmet>

            <OrderAlertDialog orderAlertOpen={orderAlertOpen} setOrderAlertOpen={setOrderAlertOpen} resetState={reset}/>

            <CanvasProvider>
                <ProductDesignWindow />

                <Grid item xs={12} sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    width: '100%',
                    height: 100,
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 5
                }}>
                    <PlanningFooter setOrderAlertOpen={setOrderAlertOpen} />
                </Grid>
            </CanvasProvider>
        </Grid>
    )
}

export default PlaningPage
