import {useEffect, useState} from 'react';
import {
    Grid,
    Paper,
    Stack,
    Step,
    StepButton,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getOrderStatusByOrderId} from "../redux/action/order";
import {useSearchParams} from "react-router-dom";
import isEmpty from "../utils/isEmpty";
import {OrderProgress, OrderState} from "../redux/orderSlice";
import {RootState} from "../redux/store";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {LuPackageCheck, LuPackageOpen} from 'react-icons/lu';
import {styled} from "@mui/system";
import LinearProgressIndicator from "../components/LinearProgressIndicator";
import Footer from "../components/Footer";

const OrderStatusPage = () => {
    const dispatch = useDispatch();
    const [searchParams]: [URLSearchParams, Function] = useSearchParams();

    const orderState = useSelector<RootState, OrderState>(state => state.order)
    const [stateStepper, setStateStepper] = useState<number>(-1);

    useEffect(() => {
        const orderId : string | null = searchParams.get('orderId');
        if (!isEmpty(orderId) && orderId != null) {
            dispatch(getOrderStatusByOrderId(orderId));
        }
    }, [dispatch, searchParams]);

    useEffect(() => {

        console.log("ITTTTTT")
        console.log(orderState.orderProgress)

        if (orderState.orderProgress === OrderProgress.ORDER_PAYMENT_SUCCESS) {
            setStateStepper(0);
        }
        if (orderState.orderProgress === OrderProgress.ORDER_PRODUCTION) {
            setStateStepper(1);
        }
        if (orderState.orderProgress === OrderProgress.ORDER_PREPARATION) {
            setStateStepper(2);
        }
        if (orderState.orderProgress === OrderProgress.ORDER_POSTED) {
            setStateStepper(3);
        }
    }, [orderState.orderProgress])

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 11,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    `linear-gradient( 95deg, #206868 0%, #206868 50%, #206868 100%)`,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg, #206868 0%, #206868 50%, #206868 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    return (
        <Grid container sx={{height: "calc(100vh - 64px)"}}>
            {isEmpty(orderState.orderId) ? (
                <LinearProgressIndicator />
            ) : (
                <Grid item xs={12}>
                    <Stack direction='column' sx={{width: '100%'}} alignItems='center'>
                        <Paper sx={{marginTop: 5, borderRadius: 3}}>
                            <Grid item xs={12} sx={{pt: 5}}>
                                <Stepper activeStep={stateStepper} alternativeLabel connector={<ColorlibConnector />}>
                                    <Step key={0}>
                                        <StepButton
                                            color="inherit"
                                            icon={<AssignmentIcon />}
                                        >
                                            <StepLabel>Rendelés feldolgozva</StepLabel>
                                        </StepButton>
                                    </Step>
                                    <Step key={1}>
                                        <StepButton
                                            color="inherit"
                                            icon={<LuPackageOpen size={'24px'}/>}
                                        >
                                            <StepLabel>Elkészítés folyamatban</StepLabel>
                                        </StepButton>
                                    </Step>
                                    <Step key={2}>
                                        <StepButton
                                            color="inherit"
                                            icon={<LuPackageCheck size={'24px'}/>}
                                        >
                                            <StepLabel>Rendelés csomagolása</StepLabel>
                                        </StepButton>
                                    </Step>
                                    <Step key={3}>
                                        <StepButton
                                            color="inherit"
                                            icon={<LocalShippingIcon />}
                                        >
                                            <StepLabel>A csomag feladásra került</StepLabel>
                                        </StepButton>
                                    </Step>
                                </Stepper>
                            </Grid>

                            <Grid container xs={12} sx={{display: 'flex', pl: 5, pr: 5, pt: 4, pb: 5}}>

                                <Stack direction='row' sx={{width: '100%'}}>
                                    <Grid item xs={3}>
                                        Rendelés dátuma
                                    </Grid>
                                    <Grid item xs={3}>
                                        Átvétel
                                    </Grid>
                                    <Grid item xs={3}>
                                        Végösszeg
                                    </Grid>
                                    <Grid item xs={3}>
                                        Rendelés azonosító
                                    </Grid>
                                </Stack>

                                <Stack direction='row' sx={{width: '100%', mt: 1, backgroundColor: 'rgba(32,104,104,0.07)', borderRadius: 3}}>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{p: 1}}
                                        >
                                            {orderState.orderDate?.slice(0, 10)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{pb: 1, pt: 1}}
                                        >
                                            {orderState.personalPickup ? 'Személyes átvétel' : 'Kiszállítás'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{pb: 1, pt: 1}}
                                        >
                                            {orderState.totalPrice} Ft
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{pb: 1, pt: 1}}
                                        >
                                            {orderState.orderId.slice(0, 8)}
                                        </Typography>
                                    </Grid>
                                </Stack>

                            </Grid>
                        </Paper>
                    </Stack>
                </Grid>
            )}

            <Footer />
        </Grid>
    );
};

export default OrderStatusPage;
