import {
    Accordion, AccordionDetails, AccordionSummary, Avatar,
    Box,
    Button,
    Grid,
    Paper, Slide,
    Stack,
    Typography, useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ImageStepper from "../components/home/ImageStepper";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './homepage.css'
import {TypeAnimation} from "react-type-animation";
import {useEffect, useRef, useState} from "react";
import useOnScreen from "../hooks/useOnScreen";
import {useNavigate} from "react-router-dom";
import { Close } from "@mui/icons-material";
import { getCookie, setCookie } from 'typescript-cookie'
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Helmet} from "react-helmet";

//Import cartoon images
import cartoon1 from '../assets/home-page/cartoon/anime.png'
import cartoon2 from '../assets/home-page/cartoon/giraffe.png'
import cartoon3 from '../assets/home-page/cartoon/monster.png'
import cartoon4 from '../assets/home-page/cartoon/pikachu.png'

//Import futuristic images
import futuristic1 from '../assets/home-page/futuristic/ciberpunk-city.png'
import futuristic2 from '../assets/home-page/futuristic/futuristic-city.png'
import futuristic3 from '../assets/home-page/futuristic/house.png'
import futuristic4 from '../assets/home-page/futuristic/new-york.png'

//Import landscape images
import landscape1 from '../assets/home-page/landscape/german-castle.png'
import landscape2 from '../assets/home-page/landscape/palmtree.png'
import landscape3 from '../assets/home-page/landscape/pyramid.png'
import landscape4 from '../assets/home-page/landscape/waterfall.png'

//Import painting images
import painting1 from '../assets/home-page/painting/colosseum.png'
import painting2 from '../assets/home-page/painting/painting.png'
import painting3 from '../assets/home-page/painting/piano-painting.png'
import painting4 from '../assets/home-page/painting/picasso.png'

const VIDEO_HEIGHT = '25vw'

const HomePage = () => {

    const theme = useTheme();
    const navigation = useNavigate();
    const isSmallerThanSmBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    const [visibleParagraphNumber, setVisibleParagraphNumber] = useState<0 | 1 | 2 | 3 | 4>(0)
    const [selectedButton, setSelectedButton] = useState('futuristic')
    // const [ infoDialogOpen, setInfoDialogOpen ] = useState(false)

    const firstParagraphRef = useRef<HTMLDivElement>(null)
    const secondParagraphRef = useRef<HTMLDivElement>(null)
    const thirdParagraphRef = useRef<HTMLDivElement>(null)
    const isFirstParagraphVisible = useOnScreen(firstParagraphRef)
    const isSecondParagraphVisible = useOnScreen(secondParagraphRef)
    const isThirdParagraphVisible = useOnScreen(thirdParagraphRef)

    const videoUrl = {
        0: 'https://ik.imagekit.io/aimerch/aimerch_video_1.mp4',
        1: 'https://ik.imagekit.io/aimerch/aimerch_video_1.mp4',
        2: 'https://ik.imagekit.io/aimerch/aimerch_video_2.mp4',
        3: 'https://ik.imagekit.io/aimerch/aimerch_video_3.mp4',
        4: 'https://ik.imagekit.io/aimerch/aimerch_video_3.mp4'
    }

    const questions = [
        {
            question: "A képen látszódni fog az AI Merch felirat?",
            answer: "Természetesen nem, az csak a tervezőben látszik"
        },
        {
            question: "Ha bármilyen problémába/hibába ütközök, hol tudok segítséget kérni?",
            answer: (
                <>
                    Honlapunk nemrég indult el, még nem minden funkció érhető el, illetve hibák is előfordulhatnak a használata során.<br /><br />
                    Ha bármilyen rendellenességet észleltek, vagy egyéb meglátásotok van a honlappal kapcsolatban, kérjük jelezzétek felénk a <a style={{ color: theme.palette.primary.main, fontWeight: '600' }} href = "mailto: kapcsolat@aimerch.hu">kapcsolat@aimerch.hu</a> címen!<br /><br />
                    Köszönjük segítségeteket!
                </>
            )
        },
        {
            question: "Mennyi idő a kiszállítás?",
            answer: "Igyekszünk minden terméket 1 hét alatt legyártani, amihez a szállítási idő általában csomagpontra rendelés esetén a Foxpost számára.még 2 napot vesz igénybe."
        },
        {
            question: "Elvesznek a képek?",
            answer: "Jelenleg sajnos igen. Már dolgozunk azon, hogy akik regisztrálva belépnek, mentésre kerüljenek a képek."
        },
        {
            question: "Meg lehet vásárolni a képet is?",
            answer: "Még nem, de már dolgozunk rajta."
        },
        {
            question: "Hogyan lehet mosni a terméket?",
            answer: (
                <>
                    1. Mosás mosógépben 30/40 C-os hőmérsékleten <br/>
                    2. Kifordítva mosható, kifordítva vasalható is <br/>
                    3. A textilt nem szabad klórral tisztítani <br/>
                    4. Nem szabad szárítógépben, vagy forgódobban szárítani <br/>
                    5. Bögréket nem szabad mosogatógépben elmosni <br/>
                </>
            )
        },
        {
            question: "Hogyan működnek a kupon kódok?",
            answer: (
                <>
                    Kuponkód használatakor minden termék árából lejön az adott kedvezmény, kivéve a szállításból.
                </>
            )
        },
        {
            question: "Termék visszaküldése lehetséges?",
            answer: (
                <>
                    Minden termékünk egyedi tervezésű és az ügyfél igényei alapján készül. Ezért nem tudjuk biztosítani a cserét.
                </>
            )
        },
        {
            question: "Hogyan kerülhetők el a mérethibából adódó problémák?",
            answer: (
                <>
                    Minden termékünk pontos centimétereit feltüntetjük az oldalunkon, így könnyedén választhatsz méretet.
                </>
            )
        },
    ];

    useEffect(() => {
        if (isFirstParagraphVisible) {
            setVisibleParagraphNumber(1)
        } else if (isSecondParagraphVisible) {
            setVisibleParagraphNumber(2)
        } else if (isThirdParagraphVisible) {
            setVisibleParagraphNumber(3)
        } else {
            setVisibleParagraphNumber(current => {
                if (current === 1 || current === 0) return 0
                else return 4
            })
        }
    }, [isFirstParagraphVisible, isSecondParagraphVisible, isThirdParagraphVisible])

    useEffect(() => {
        const visited = getCookie('visited')
        if (!visited) {
            // setInfoDialogOpen(true)
            setCookie('visited', true, { expires: 7 })
        }
    }, [])

    const getImages = () : string[] => {

        if (selectedButton === 'futuristic') {
            return [futuristic1, futuristic2, futuristic3, futuristic4]
        }

        if (selectedButton === 'oil-art') {
            return [painting1, painting2, painting3, painting4]
        }

        if (selectedButton === 'cartoon') {
            return [cartoon1, cartoon2, cartoon3, cartoon4]
        }

        if (selectedButton === 'landscape') {
            return [landscape1, landscape2, landscape3, landscape4]
        }

        return []
    }

    return (
        <Grid container className='container' sx={{
            color: theme.palette.text.primary
            // background: "linear-gradient(135deg, rgba(175,222,220,0.7) 0%, rgba(199,191,189,0.7) 100%)"
        }}>

            <Helmet>
                <title>AI Merch - Egyedi póló, bögre és ajándékok az A.I. segítségével</title>
                <meta name="description" content="Egyedi póló, bögre és ajándék termék nyomtatás az AI Merch-nél. Használd mesterséges intelligencia tervezőnket az egyedi póló tervezéséhez. Egyedi póló nyomtatás és prémium minőségű ajándékok széles választéka." />
            </Helmet>

            {/*<Dialog open={false} onClose={() => setInfoDialogOpen(false)}>
                <DialogTitle>
                    <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>Tesztelés alatt</Typography>
                    <IconButton
                    aria-label="close"
                    onClick={() => setInfoDialogOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Box sx={{ 
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    maxWidth: 512,
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <Typography sx={{textAlign: 'center'}}>
                        Honlapunk jelenleg tesztelés alatt áll. Még nem minden funkció érhető el, illetve hibák is előfordulhatnak a használata során.<br /><br />
                        Ha bármilyen rendellenességet észleltek, vagy egyéb meglátásotok van a honlappal kapcsolatban, kérjük jelezzétek felénk a <a style={{ color: theme.palette.primary.main, fontWeight: '600' }} href = "mailto: kapcsolat@aimerch.hu">kapcsolat@aimerch.hu</a> címen!<br /><br />
                        Köszönjük segítségeteket!
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        marginTop: 3,
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button variant='contained' onClick={() => setInfoDialogOpen(false)}><Typography>Megértettem</Typography></Button>
                    </Box>
                </Box>
            </Dialog>*/}

            <Grid item xs={12} sm={12} md={7}
                  sx={{
                        [theme.breakpoints.up('md')]: {
                            height: "calc(100vh - 64px)"
                        },
                       [theme.breakpoints.down('md')]: {
                            mb: 2
                       },
                  }}
            >

                <Box sx={{
                    width: '95px',
                    height: '95px',
                    borderRadius: '50%',
                    backgroundColor: '#206868',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    position: 'absolute',
                    right: '35%',
                    top: '30%',
                    textAlign: "center",
                    fontWeight: "bold",
                    zIndex: 100,
                    [theme.breakpoints.down('md')]: {
                        visibility: 'hidden'
                    },
                }}>
                    Már <br/>
                    3999 Ft-tól
                </Box>

                <Box
                    sx={{
                        padding: 12,
                        [theme.breakpoints.down('md')]: {
                            padding: 3
                        },
                        [theme.breakpoints.up('md')]: {
                            paddingRight: 0
                        }
                    }}
                >

                    <Typography
                        className="main-title"
                        sx={{
                            // [theme.breakpoints.up('xs')]: {
                            //     fontSize: '26px'
                            // },
                            // [theme.breakpoints.up('sm')]: {
                            //     fontSize: '26px'
                            // },
                            // [theme.breakpoints.up('md')]: {
                            fontSize: '44px',
                            // },
                            // [theme.breakpoints.up('lg')]: {
                            //     fontSize: '54px'
                            // },
                            // [theme.breakpoints.up('xl')]: {
                            //     fontSize: '70px'
                            // },
                            fontFamily: "Lexend",
                            fontWeight: 300,
                            '& .highlighted-text': {
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <span className="highlighted-text">Te</span> megálmodod,<br/>
                        <span className="highlighted-text">M.I.</span> elkészítjük.
                    </Typography>

                        <Typography
                            sx={{marginTop: 3, marginBottom: 12}}
                        >
                            Ne te tölts időt a tervezéssel, a mesterséges
                            intelligencia <br/> megoldja helyetted.
                            <br/>
                            Így az új kedvenc pólódnak csak a képzeleted szab határt.
                        </Typography>


                        <Typography
                            sx={{
                                fontSize: 18,
                                mt: 4,
                                fontFamily: "Lexend",
                                fontWeight: 400,
                                '& .bold': {
                                    fontWeight: "bold",
                                },
                                [theme.breakpoints.down('md')]: {
                                    height: '180px'
                                },
                                [theme.breakpoints.up('md')]: {
                                    height: '150px'
                                },
                            }}
                        >
                            <span className="bold" style={{fontSize: 24}}>Hogyan működik?</span> <br/>

                            <TypeAnimation
                                sequence={[
                                    200,
                                    '1. Fogalmazd meg mit szeretnél látni a pólón',
                                    500,
                                    '1. Fogalmazd meg mit szeretnél látni a pólón\n 2. Válaszd ki a kedvenced a lehetőségek közül', // Deletes 'One' and types 'Two'
                                    500,
                                    '1. Fogalmazd meg mit szeretnél látni a pólón\n 2. Válaszd ki a kedvenced a lehetőségek közül\n 3. Mi elkészítjük és kiszállítjuk neked!', // Types 'Three' without deleting 'Two'
                                    5000,
                                ]}
                                wrapper="span"
                                cursor={true}
                                speed={55}
                                style={{
                                    whiteSpace: 'pre-line',
                                    fontSize: 20,
                                    display: 'inline-block',
                                    fontFamily: "Lexend",
                                }}
                            />
                        </Typography>

                        <Box sx={{
                            mt: 2
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    navigation('/tervezes')
                                }}
                            >
                                <Typography sx={{
                                    color: "white",
                                }} fontSize={20} fontWeight={600} fontFamily={'Lexend'}>
                                    Kipróbálom
                                </Typography>
                            </Button>
                        </Box>
                    </Box>

                {!isSmallerThanSmBreakpoint && (
                    <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1500}>
                        <Stack
                            sx={{position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)"}}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography
                                variant='subtitle2'
                            >
                                Görgess!
                            </Typography>
                            <Avatar
                                sx={{width: 35, height: 35}}
                            >
                                <KeyboardArrowDownIcon />
                            </Avatar>
                        </Stack>
                    </Slide>
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
                <Grid item sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <ImageStepper/>
                </Grid>
            </Grid>

            <Grid container
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: 3,
                      paddingRight: 3,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: 'white'
                  }}
            >
                <Grid item xs={12} sm={6} md={4}
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            p: 0.5
                        }
                    }}
                >
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        <AccessTimeIcon sx={{marginRight: 2}}/>
                        <Typography color='white'>
                            Gyors, egyszerű tervezés
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                          p: 0.5
                      }
                    }}
                >
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        <VerifiedIcon sx={{marginRight: 2}}/>
                        <Typography color='white'>
                            Minőségi anyaghasználat
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4}
                    sx={{
                      [theme.breakpoints.up('sm')]: {
                          p: 0.5
                      }
                    }}
                >
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        <StorefrontIcon sx={{marginRight: 2}}/>
                        <Typography color='white'>
                            Gyors rendelés kiszállítás
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>


            <Grid container sx={{margin: 1}}>

                <Grid container sx={{marginTop: 3}}>
                    <Stack direction='row' alignItems='center' justifyContent='center' sx={{width: '100%'}}>
                        <Typography
                            sx={{
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                                fontSize: isSmallerThanSmBreakpoint ? 22 : 32
                            }}
                        >
                            AI Merch
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Lexend',
                                fontSize: isSmallerThanSmBreakpoint ? 22 : 32
                            }}
                        >
                            - el generált képek
                        </Typography>
                    </Stack>

                    <Typography
                        variant='subtitle1'
                        sx={{marginTop: 2, marginLeft: {xs: 4, md: 10}, marginRight: {xs: 4, md: 10}, textAlign: 'center'}}
                    >
                        A mesterséges intelligencia segítségével bárki számára elérhetővé válik a saját egyedi műalkotások létrehozása.
                        A képek készítése során, legyen az futurisztikus vagy realisztikus, a végeredmény teljesen rajtunk múlik, attól függően, hogyan fogalmazzuk meg kéréseinket a generálási folyamat során.
                        <br/> Így az új kedvenc pólódnak csak a képzeleted szab határt.
                    </Typography>

                    <Stack direction='row' justifyContent='center'
                           sx={{
                               marginTop: 1,
                               width: '100%',
                               flexWrap: 'wrap'
                            }}
                    >

                        <Button
                                variant='outlined'
                                className={selectedButton === 'futuristic' ? 'selectedButton' : 'notSelectedButton'}
                                onClick={() => {
                                    setSelectedButton('futuristic')
                                }}
                                sx={{m: '5px !important'}}
                        >
                            Futurisztikus
                        </Button>

                        <Button variant='outlined'
                                className={selectedButton === 'oil-art' ? 'selectedButton' : 'notSelectedButton'}
                                onClick={() => {
                                    setSelectedButton('oil-art')
                                }}
                                sx={{m: '5px !important'}}
                        >
                            Festmény
                        </Button>

                        <Button variant='outlined'
                                className={selectedButton === 'cartoon' ? 'selectedButton' : 'notSelectedButton'}
                                onClick={() => {
                                    setSelectedButton('cartoon')
                                }}
                                sx={{m: '5px !important'}}
                        >
                            Rajzfilm
                        </Button>

                        <Button variant='outlined'
                                className={selectedButton === 'landscape' ? 'selectedButton' : 'notSelectedButton'}
                                onClick={() => {
                                    setSelectedButton('landscape')
                                }}
                                sx={{m: '5px !important'}}
                        >
                            Tájkép
                        </Button>
                    </Stack>

                    <Grid container justifyContent='center' sx={{marginLeft: 4, marginRight: 4}}>

                        {getImages().map(url => {
                            return (
                                <Grid item key={url} xs={6} sm={6} md={3} sx={{display: 'flex', justifyContent: 'center', marginTop: 4}}>
                                    <img src={url} alt='' style={{
                                        height: isSmallerThanSmBreakpoint ? 135 : 250,
                                        width: isSmallerThanSmBreakpoint ? 135 : 250,
                                        borderRadius: 12
                                    }} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>

            </Grid>


            {/*Demo working*/}
            <Grid container
                  sx={{marginLeft: 2, marginRight: 2}}
            >

                <Grid container sx={{marginTop: 6}}>
                    <Stack direction='row' alignItems='center' justifyContent='center' sx={{width: '100%'}}>
                        <Typography
                            variant='h4'
                            sx={{fontWeight: 'bold'}}
                        >
                            Hogyan is működik mindez?
                        </Typography>
                    </Stack>
                </Grid>

                {!isSmallerThanSmBreakpoint && (
                    <Grid item xs={7}>

                        <Stack sx={{position: 'sticky', top: 150, marginTop: 6, width: '100%', height: '35vw'}}
                               alignItems='center'>
                            <Box sx={{
                                position: 'absolute',
                                display: 'flex',
                                marginRight: 5,
                                marginLeft: 5,
                                paddingTop: 8,
                                paddingBottom: 8,
                                backgroundColor: '#21686914',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5,
                                width: '90%',
                                height: VIDEO_HEIGHT
                            }}>

                                <Box sx={{
                                    width: '90%',
                                    position: 'absolute',
                                    opacity: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <video autoPlay loop muted preload="auto" playsInline
                                           style={{height: VIDEO_HEIGHT, borderRadius: 10}}>
                                        <source src={videoUrl[1]} type='video/mp4'/>
                                    </video>
                                </Box>

                                <Box sx={{
                                    width: '90%',
                                    position: 'absolute',
                                    opacity: visibleParagraphNumber === 2 ? 1 : 0,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <video autoPlay loop muted preload="auto" playsInline
                                           style={{height: VIDEO_HEIGHT, borderRadius: 10}}>
                                        <source src={videoUrl[2]} type='video/mp4'/>
                                    </video>
                                </Box>

                                <Box sx={{
                                    width: '90%',
                                    position: 'absolute',
                                    opacity: visibleParagraphNumber === 3 || visibleParagraphNumber === 4 ? 1 : 0,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <video autoPlay loop muted preload="auto" playsInline
                                           style={{height: VIDEO_HEIGHT, borderRadius: 10}}>
                                        <source src={videoUrl[3]} type='video/mp4'/>
                                    </video>
                                </Box>

                            </Box>
                        </Stack>
                    </Grid>
                )}

                <Grid item xs={12} sm={5} md={5}
                      sx={{
                          [theme.breakpoints.up('sm')]: {
                              paddingRight: 5,
                          },
                          [theme.breakpoints.down('sm')]: {
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center'
                          }
                      }}
                >
                    <Stack
                        direction='column'
                        justifyContent='center'
                        sx={{
                            [theme.breakpoints.up('lg')]: {
                                height: '75vh'
                            },
                            [theme.breakpoints.up('md')]: {
                                height: '55vh'
                            },
                            [theme.breakpoints.up('sm')]: {
                                height: '45vh',
                                marginBottom: 10
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                mt: 4,
                                fontFamily: "Lexend",
                                fontWeight: 400,
                                '& .bold': {
                                    fontWeight: "bold",
                                }
                            }}
                        >
                            <span className="bold"
                                  style={{fontSize: 24}}>Válaszd ki milyen termékre szeretnél tervezni</span> <br/>

                        </Typography>

                        <Typography>
                            Széles termékkínálatból választhatsz, férfi és női pólókat, pulcsikat, bögréket, vászontáskákat. Folyamatosan bővítjük kínálatunkat, hogy mindig megtaláld azt, amire szükséged van az egyedi ötleteid megvalósításához. Válassz a kínálatunkból és alkoss egyedülálló termékeket!
                        </Typography>
                        <div ref={firstParagraphRef}></div>
                    </Stack>

                    {isSmallerThanSmBreakpoint && (
                        <video autoPlay loop muted preload="auto" playsInline
                               style={{width: '100%', borderRadius: 10, marginTop: 15}}>
                            <source src={videoUrl[1]} type='video/mp4'/>
                        </video>
                    )}

                    <Stack
                        direction='column'
                        sx={{
                            [theme.breakpoints.up('lg')]: {
                                height: '75vh'
                            },
                            [theme.breakpoints.up('md')]: {
                                height: '55vh'
                            },
                            [theme.breakpoints.up('sm')]: {
                                height: '45vh'
                            }
                        }}
                        justifyContent='flex-start'
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                mt: 4,
                                fontFamily: "Lexend",
                                fontWeight: 400,
                                '& .bold': {
                                    fontWeight: "bold",
                                }
                            }}
                        >
                            <span className="bold" style={{fontSize: 24}}>Készen állsz a tervezésre?</span> <br/>

                        </Typography>

                        <Typography>
                            Fogalmazd meg röviden, hogy milyen képet szeretnél készíteni, és kezdődhet is a
                            tervezés!<br/>
                            Az Elkészít gombra kattintva pár másodperc múlva készen is vannak a tervek. Egy kéréshez
                            egyszerre két képet készítünk, hogy ki tudd választani amelyik neked tetszik!
                        </Typography>
                        <div ref={secondParagraphRef}></div>
                    </Stack>

                    {isSmallerThanSmBreakpoint && (
                        <video autoPlay loop muted preload="auto" playsInline
                               style={{width: '100%', borderRadius: 10, marginTop: 15}}>
                            <source src={videoUrl[2]} type='video/mp4'/>
                        </video>
                    )}

                    <Stack
                        direction='column'
                        sx={{
                            [theme.breakpoints.up('lg')]: {
                                height: '45vh'
                            },
                            [theme.breakpoints.up('md')]: {
                                height: '45vh'
                            },
                            [theme.breakpoints.up('sm')]: {
                                height: '35vh'
                            }
                        }}
                        justifyContent='flex-start'
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                mt: 4,
                                fontFamily: "Lexend",
                                fontWeight: 400,
                                '& .bold': {
                                    fontWeight: "bold",
                                }
                            }}
                        >
                            <span className="bold" style={{fontSize: 24}}>Az utolsó simítások</span> <br/>

                        </Typography>

                        <Typography>
                            Az elkészült képek közül válaszd ki amelyik neked tetszik, és az egyből megjelenik a póló
                            terven! Helyezd el, és méretezd át a képet kedvedre a jelölt téglalapon belül!
                        </Typography>
                        <div ref={thirdParagraphRef}></div>
                    </Stack>

                    {isSmallerThanSmBreakpoint && (
                        <video autoPlay loop muted preload="auto" playsInline
                               style={{width: '100%', borderRadius: 10, marginTop: 15}}>
                            <source src={videoUrl[3]} type='video/mp4'/>
                        </video>
                    )}

                </Grid>
            </Grid>

            <Paper
                sx={{
                    marginTop: 4,
                    backgroundColor: theme.palette.primary.main,
                    width: '100%',
                    borderTopLeftRadius: 40,
                    borderTopRightRadius: 40,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }}
            >
                <Stack direction='row'>
                    <Typography
                        variant='h4'
                        sx={{
                            textAlign: 'center',
                            width: '100%',
                            marginTop: 4,
                            fontWeight: 'bold',
                            color: 'white',
                            marginBottom: 4
                        }}
                    >
                        Kezdj el most tervezni!
                    </Typography>

                </Stack>

                {/*<Typography>*/}
                {/*    Legyen egyedi a stílusod*/}
                {/*</Typography>*/}

            </Paper>

            <Grid
                container
                id="gyakori-kerdesek"
                sx={{marginLeft: 2, mb: 5, marginRight: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
            >

                <Typography
                    variant='h5'
                    sx={{fontWeight: 'bold', marginTop: 4, marginBottom: 1}}
                >
                    Gyakran feltett kérdések
                </Typography>

                <Grid
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            width: '60%'
                        },
                        [theme.breakpoints.down('md')]: {
                            width: '100%'
                        }
                    }}
                >

                    {questions.map((question, index) => {
                        return (
                            <Accordion
                                key={index}
                                square={true}
                                sx={{margin: 2, borderRadius: 5, '&:before': {backgroundColor: 'transparent !important'}}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        variant='subtitle2'
                                        sx={{fontSize: 16}}
                                    >
                                        {question.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {question.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}

                </Grid>
            </Grid>

            <Footer />

        </Grid>
    );
};

export default HomePage;
