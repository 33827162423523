import React from 'react';
import Navigation from "./navigation/Navigation";
import {Box} from "@mui/material";

const Layout = ({children}: {children: React.ReactNode}) => {
    return (
        <Box sx={{
            height: "100%"
        }}>
            <Navigation />
            {children}
        </Box>
    );
};

export default Layout;
