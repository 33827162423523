import { useSelector } from "react-redux";
import {DesignTypeOption, ItemType} from "../../../types";
import { CustomSelect } from "./CustomSelect";
import { RootState } from "../../../redux/store";
import { designPriceSelector } from "../../../redux/productSelectors";
import { useEffect, useState } from "react";

export function DesignTypeSelect({ selected, setSelected }: {
    selected?: DesignTypeOption
    setSelected: (value?: DesignTypeOption) => void 
}) {
    const itemType = useSelector((state: RootState) => state.plan.itemType)
    const getDesignPrice = useSelector((state: RootState) => designPriceSelector(state.product))
    const [ productType, setProductType ] = useState<ItemType>()

    useEffect(() => {
        setProductType(itemType)
    }, [ itemType ])

    const designTypeParams = {
        title: 'Minta',
        names: {
            'seasonal': 'Szezonális',
            'custom': 'Egyedi minta'
        },
        descriptions: {
            'seasonal': 'Hamarosan!',
            'custom': productType && getDesignPrice(productType) ? '+' + getDesignPrice(productType) + ' Ft' : ''
        }
    }

    return (
        <CustomSelect
            selected={selected}
            setSelected={setSelected}
            params={designTypeParams} />
    )
}
