import React from 'react';
import {
    AppBar,
    Badge,
    Box,
    Container,
    IconButton,
    ListItem,
    ListItemButton, 
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import {ConstantParams} from "../../config/Constants";
import MenuIcon from "@mui/icons-material/Menu";
import {CustomList, CustomNavLink} from "./CustomDrawer";
import {MenuItems} from "./MenuItems";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import CustomShoppingCartIcon from "./CustomShoppingCartIcon";

const CustomAppBar = ({navigationOpenClicked}: {navigationOpenClicked : () => void}) => {

    const { pathname } = useLocation();

    return (
        <AppBar position="sticky" sx={{boxShadow: 1, background: 'white'}} >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src="/logo-no-background.png" style={{height: 32, marginRight: 8}} alt='' />
                    <Typography
                        variant="h6"
                        noWrap
                        component={NavLink}
                        to="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            textDecoration: 'none',
                        }}
                    >
                        {ConstantParams.COMPANY_NAME}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={navigationOpenClicked}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Box>
                            <Typography
                                variant="h5"
                                noWrap
                                component={NavLink}
                                to="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    textDecoration: 'none',
                                }}
                            >
                                {ConstantParams.COMPANY_NAME}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                        <CustomList direction={"row"} sx={{pt: 0}}>
                            {MenuItems.map((menuItem, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{pl: 1, pr: 1}}
                                    >
                                        <ListItemButton
                                            selected={menuItem.path === pathname}
                                            sx={{borderRadius: 3}}
                                            component={CustomNavLink}
                                            to={menuItem.path}
                                        >
                                            <ListItemText primary={menuItem.title} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </CustomList>
                    </Box>

                    <CustomShoppingCartIcon />

                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default CustomAppBar;
