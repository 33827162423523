import React, {useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, ClickAwayListener, Box} from "@mui/material";
import {useDispatch} from "react-redux";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {planActions} from "../../redux/planSlice";
import {HexColorPicker} from 'react-colorful'
import { Circle } from '@mui/icons-material';

const fontOptions = [
    "Arial",
    "Abril Fatface",
    "Abril",
    "Alegreya",
    "Allerta Stencil",
    "Architects Daughter",
    "Audiowide",
    "Verdana", 
    "Helvetica",
    "Alfa Slab One",
    "Bad Script",
    "Berkshire Swash",
    "Tahoma", 
    "Trebuchet MS",
    "Alex Brush",
    "Times New Roman", 
    "Georgia", 
    "Garamond", 
    "Courier New", 
    "Brush Script MT"
]

const AdditionalTextEditor = () => {

    const dispatch = useDispatch()

    const [selectedFont, setSelectedFont] = useState('Arial')
    const [selectedColor, setSelectedColor] = useState('black')
    const [text, setText] = useState<string>('')
    const [colorPickerOpen, setColorPickerOpen] = useState(false);

    const togglePicker = () => {
        setColorPickerOpen(current => !current)
    }

    return (
        <Box sx={{
            width: '100%',
        }}>
            <Grid container sx={{
                display: 'flex',
                maxWidth: 500,
            }}>
                <Grid item
                    sx={{
                        top: 105,
                        width: '100%',
                        padding: 2,
                        borderRadius: 8,
                        backgroundColor: '#ffffff66',
                        boxShadow: 1,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 19,
                            marginBottom: 1
                        }}
                    >
                        Szerkesztés
                    </Typography>

                    {/*<Button
                        onClick={() => console.log('File upload pressed')}
                        sx={{
                            width: '100%',
                            marginBottom: 2
                        }}
                        variant="contained" startIcon={<CloudUploadIcon />}
                    >
                        Kép Feltöltés
                    </Button>*/}

                    <TextField
                        label='Extra szöveg'
                        placeholder='Extra szöveg'
                        sx={{
                            width: '100%',
                            marginBottom: 2
                        }}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value)
                        }}
                    />

                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                        <Grid item xs={12}>
                            <FormControl sx={{ width: '100%'}}>
                                <InputLabel sx={{ color: 'black' }}>Betűtípus</InputLabel>
                                <Select
                                    label="Betűtípus"
                                    value={selectedFont}
                                    onChange={event => setSelectedFont(event.target.value)}
                                    sx={{
                                        width: '100%',
                                        fontFamily: selectedFont
                                    }}
                                >
                                    {fontOptions.map((font, index) => (
                                        <MenuItem key={index} value={font} sx={{fontFamily: font}}>
                                            {font}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <ClickAwayListener onClickAway={() => setColorPickerOpen(false)}>
                            <Grid item xs={12} sx={{display: 'flex', width: '100%', height: 0, transform: 'translateY(-48px)', justifyContent: 'flex-end', zIndex: 10}}>
                                <Circle sx={{fontSize: 40, marginRight: 5}} htmlColor={selectedColor} onClick={togglePicker}/>
                                {colorPickerOpen && 
                                    <HexColorPicker style={{
                                        height: 200,
                                        margin: 5,
                                        marginTop: '55px',
                                        position: 'absolute',
                                        zIndex: 3,
                                        fontSize: 12,
                                        boxShadow: '2px -5px 10px 1px rgba(0, 0, 0, 0.3)',
                                        borderRadius: 10
                                    }} color={selectedColor} onChange={setSelectedColor} />}
                            </Grid>
                        </ClickAwayListener>
                    </Grid>

                    

                    <Button
                        onClick={() => dispatch(planActions.setText({value: text, color: selectedColor, font: selectedFont}))}
                        sx={{
                            width: '100%',
                            zIndex: 0
                        }}
                        variant='contained'
                        startIcon={<TextFieldsIcon />}
                    >
                        <Typography
                            sx={{
                                color: "white",
                                fontSize: 16,
                                borderRadius: 15
                            }}
                        >
                            Hozzáadás
                        </Typography>
                    </Button>

                </Grid>
            </Grid>
        </Box>
    );
};

export default AdditionalTextEditor;
