const colors = {
    primary: '#206868',
    primaryDark: '#fff',
    primaryLight: 'rgb(33,43,54)',
    secondary: '#3E68FF',
    secondaryDark: '#0077c2',
    secondaryLight: 'rgba(81, 150, 209, 0.7)',

    lightBackground: '#fdfdfe',

    darkBackground: '#22272f',
    lightDarkBackground: '#161c24',

    white: '#fff',
    lightDivider: 'rgba(0, 0, 0, 0.12)',
    darkDivider: 'rgba(255, 255, 255, 0.12)',
    lightText1: '#212B36',
    lightText2: 'rgb(99, 115, 129)',
    lightText3: 'rgba(0, 0, 0, 0.38)',
    lightText4: 'rgba(0, 0, 0, 0.5)',

    primaryText: '#02183c',
    darkText1: '#fff',
    darkText2: 'rgba(255, 255, 255, 0.7)',
    darkText3: 'rgba(255, 255, 255, 0.5)',

    //button
    lightButtonPrimaryBackgroundColor: "#212b37",
    lightButtonPrimaryTextColor: "#fff",
    darkButtonPrimaryBackgroundColor: "#fff",
    darkButtonPrimaryTextColor: "#212b37",

    //input text color
    lightHighContrastText: "#000000",
    darkHighContrastText: "#627280",

    //app bar
    lightAppBar: "rgba(255, 255, 255, 0.9)",
    darkAppBar: "#161c24",

    //new notification color
    lightNewNotification: "rgba(235,237,239,0.87)",
    darkNewNotificationColor: "rgb(51,61,71)"
}

export default colors
