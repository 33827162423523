import axios, {AxiosError, AxiosResponse} from "axios"
import { GenerationState, Image, planActions } from "../planSlice"
import {GenerateImageResponse, RequestError} from "./GeneralResponses"
import isEmpty from "../../utils/isEmpty"
import ReactGA from "react-ga4";

export const generateImages = (prompt: string, options: { generationId?: string | null, premium?: boolean }): any => {
    const generationId = options.generationId
    const premium = options.premium
    let timer: NodeJS.Timer
    let progress = 0
    let generationSuccess = false

    ReactGA.event({category: "conversion", action: "userCalledImageGeneration"});

    const startLinearProgress = (time: number, generationId: string, dispatch: any, cb: (generationId: string) => void, premium?: boolean) => {
        let diff = 100 / time / 2
        timer = setInterval(() => {
            if (progress === 100) {
                clearInterval(timer)
                cb(generationId)
            }
            progress = Math.min(progress + diff, 100)
            dispatch(planActions.setProgress({ progress, premium }))
        }, 500)
    }

    const startRandomProgress = (dispatch: any) => {
        timer = setInterval(() => {
            if (progress === 100) {
                dispatch(planActions.setGenerationState({ state: GenerationState.Done }))
                progress = 0
                clearInterval(timer)
            }
            let diff = Math.random() * 10
            if (progress + diff >= 80) diff /= 10
            progress = Math.min(progress + diff, 95)
            dispatch(planActions.setProgress({ progress }))
          }, 500);
    }

    const getImageById = async (generationId: string, dispatch: any, premium?: boolean) => {
        const url = `/image/generate/${generationId}`
        axios.get<GenerateImageResponse>(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }).then(res => {
            progress = 0
            dispatch(planActions.setProgress({ progress: 0, premium }))
            if (res.status !== 200 || res.data.status !== 'SUCCESS') {
                throw new Error('Generation image failed')
            }
            const images: Image[] = res.data.images.map(image =>  ({
                id: image.imageId,
                link: image.fileURL
            }))
            dispatch(planActions.addGeneration({ id: res.data.generationId, images, premium, prompt: res.data.prompt }))
            dispatch(planActions.setGenerationState({ state: GenerationState.Done, premium }))
        }).catch(err => {
            dispatch(planActions.setGenerationState({ state: GenerationState.Error, premium }))
        })
    }

    const processing = async (eta: number, generationId: string, dispatch: any) => {
        if (!eta) {
            eta = 15
        }
        dispatch(planActions.setEta(eta))
        startLinearProgress(eta, generationId, dispatch, (generationId: string) => {
            getImageById(generationId, dispatch)
        })
    }

    return async (dispatch: any) => {
        if (generationId && !isEmpty(generationId)) {
            getImageById(generationId, dispatch, premium)
            return
        }
        if (prompt === '') return
        dispatch(planActions.setGenerationState({ state: GenerationState.Started, premium }))
        dispatch(planActions.resetGeneration({ premium }))
        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
        const url = premium ? '/image/premium/generate' : '/image/generate'
        if(premium) {
            startLinearProgress(30, '', dispatch, () => {
                if (generationSuccess) {
                    dispatch(planActions.setGenerationState({ state: GenerationState.Done, premium }))
                } else {
                    progress = 0
                    dispatch(planActions.setGenerationState({ state: GenerationState.Warning, premium }))
                    startLinearProgress(30, '', dispatch, () => {
                        if (generationSuccess) {
                            dispatch(planActions.setGenerationState({ state: GenerationState.Done, premium }))
                        } else {
                            dispatch(planActions.setGenerationState({ state: GenerationState.Error, premium }))
                        }
                    }, premium)
                }
            }, true)
        } else {
            startRandomProgress(dispatch)
        }
        axios.post<GenerateImageResponse>(url, { prompt }, { headers }).then(res => {
            if (res.status !== 200) {
                throw new Error('Generating image failed')
            }
            if (res.data.status === 'PROCESSING') {
                clearInterval(timer)
                progress = 0
                dispatch(planActions.setProgress({ progress: 0 }))
                dispatch(planActions.setGenerationState({ state: GenerationState.Warning }))
                processing(res.data.eta, res.data.generationId, dispatch)
            } else {
                const images: Image[] = res.data.images.map(image =>  ({
                    id: image.imageId,
                    link: image.fileURL
                }))
                dispatch(planActions.addGeneration({ id: res.data.generationId, images, premium, prompt }))
                generationSuccess = true              
                progress = 100
                dispatch(planActions.setProgress({ progress, premium }))
            }
        }).catch(err => {
            progress = 100
            dispatch(planActions.setProgress({ progress, premium }))
            dispatch(planActions.setGenerationState({ state: GenerationState.Error, premium }))
        })
    }
}

interface ExamplePromptResponse {
    prompt: string
}

export const getExamplePrompt = (onRequestFinished: (prompt: string) => void): any => {
    return async (dispatch: any) => {
        axios.get('/example-prompt')
            .then((res: AxiosResponse<ExamplePromptResponse>) => {
                onRequestFinished(res.data.prompt);
                ReactGA.event({category: "conversion", action: "userGetExamplePrompt"});
            })
            .catch((err: AxiosError<RequestError>) => {
            });
    }
}
