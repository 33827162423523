import { Button } from "@mui/material"
import {ColorOption, ItemTypeOption, OptionParams, SizeOption} from "../../../types"
import { CustomSelect } from "./CustomSelect"
import {colors} from "./ItemTypeSelect";

export function SizeSelect({ selected, setSelected, openSizeChart, itemType }: {
    selected?: SizeOption
    setSelected: (value?: SizeOption) => void
    openSizeChart: () => void,
    itemType?: ItemTypeOption
}) {

    const sizeParams: OptionParams<SizeOption> = {
        title: 'Méret',
        names: {},
    }
    
    let sizeOptionsNumber = 0
    
    if (itemType) {
        for (const size of colors[itemType].availableSizes) {
            sizeParams.names[size] = size.toUpperCase();
            sizeOptionsNumber++
        }
    }

    return (
        
        <CustomSelect
            selected={selected}
            setSelected={setSelected}
            params={sizeParams}
            horizontal
            SizeChartButton={
                <Button variant='outlined' onClick={openSizeChart}>Mérettáblázat</Button>
            }
            nrInRow={sizeOptionsNumber}
        />
    )
}
