//TODO !!IMPORTANT!!! to refactor currently product are also stored in the planning.ts.
// When creatingnew product need to create it in that file too
// Item images are stored in ItemTypeSelect.tsx

import { colors } from "../components/planning/selectors/ItemTypeSelect";

export enum ItemType {
    TSHIRT = 'tshirt',
    FEMALE_TSHIRT = 'female_tshirt',
    FEMALE_V_NECK_TSHIRT = 'female_v_neck_tshirt',
    HOODIE = 'hoodie',
    SWEATER = 'sweater',
    MUG = 'mug',
    CANVAS_BAG = "canvas_bag",
    DELIVERY = 'delivery',
    BOX_DELIVERY = 'box_delivery',
    CUSTOM_DESIGN = 'custom_design'
    // PUZZLE = 'puzzle',
    // MOUSE_PAD = 'mouse_pad',
}

export type ItemTypeOption =
    | ItemType.TSHIRT
    | ItemType.FEMALE_TSHIRT
    | ItemType.FEMALE_V_NECK_TSHIRT
    | ItemType.HOODIE
    | ItemType.SWEATER
    | ItemType.MUG
    | ItemType.CANVAS_BAG
;
    // | ItemType.MOUSE_PAD
    // | ItemType.PUZZLE;

export type DesignTypeOption = 'seasonal' | 'custom';

export type PlanNumberOption = '1' | '2' | '3' | '4';

export type ColorOption = 'white' | 'black' | 'red' |
    'blue' | 'royal_blue' | 'navy_blue' | 'sky_blue' |
    'green' | 'kelly_green' | 'bottle_green' |
    'grey' | 'heather_grey' | 'sport_grey' |
    'sunflower' |
    'orange' |
    'fuchsia' |
    'light_pink' | 'pink' |
    'graphite' |
    'natural';
    

export type SizeOption = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

//TODO remove partial
export type ImagePaths = Partial<Record<ColorOption, string>>;

export type ProductDetails = {
    availableColors: ColorOption[],
    colorCodes: Partial<Record<ColorOption, string>>,
    availableSizes: SizeOption[],
}

export interface OptionParams<Options extends string> {
    title: string;
    names: Partial<Record<Options, string>>;
    descriptions?: Partial<Record<Options, string>>;
    images?: Record<Options, ImagePaths>;
}

export const hasMultipleColor = (itemType?: ItemTypeOption | ItemType): boolean => {
    const itemTypeOption = itemType as ItemTypeOption
    return itemTypeOption ? colors[itemTypeOption].availableColors.length > 1 : false
}

export const hasMultipleSize = (itemType?: ItemTypeOption | ItemType): boolean => {
    const itemTypeOption = itemType as ItemTypeOption
    return itemTypeOption ? colors[itemTypeOption].availableSizes.length > 1 : false
}
