import {useState} from 'react';
import {Button, Chip, Grid, Stack, useTheme} from "@mui/material";
import ProductAndImageSelectorView from "./ProductAndImageSelectorView";
import AdditionalTextEditor from "./AdditionalTextEditor";
import EditorView from "./EditorView";

enum EditMenu {
    SelectProduct,
    AddText,
}


const ProductDesignWindow = () => {

    const theme = useTheme()

    const [selectedEditMenu, setSelectedEditMenu] = useState<EditMenu>(EditMenu.SelectProduct)

    return (
        <Grid container sx={{pb: '100px'}}>
            <Grid item xs={false} xl={1}/>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={{
                display: 'flex',
                zIndex: 4,
                padding: 2,
                paddingRight: 5,
                paddingLeft: 5,
                width: '100%',
                justifyContent: 'space-around',
                [theme.breakpoints.down('md')]: {
                    justifyContent: 'center !important'
                }
            }}>
                <EditorView />

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                [theme.breakpoints.down('md')]: {
                    alignItems: 'center'
                },
                paddingRight: 5,
                paddingLeft: 5,
            }}>

                <Stack
                    direction="row"
                    spacing={5}
                    sx={{
                        paddingTop: '24px',
                        paddingBottom: 2,
                        position: 'sticky',
                        top: '64px',
                        zIndex: 3,
                        background: '#eee',
                        backdropFilter: 'blur(8px)',
                        maxWidth: 500,
                        width: '100%'
                    }}
                    justifyContent="center"
                >
                    <Chip
                        label="Termék és kép választás"
                        color='primary'
                        component={Button}
                        variant={selectedEditMenu === EditMenu.SelectProduct ? 'filled' : 'outlined'}
                        sx={{fontSize: 15, '&:hover': {cursor: "pointer", backgroundColor: '#206868'}}}
                        onClick={() => setSelectedEditMenu(EditMenu.SelectProduct)}
                    />
                    <Chip
                        label="Szöveg hozzáadása"
                        color="primary"
                        component={Button}
                        variant={selectedEditMenu === EditMenu.AddText ? 'filled' : 'outlined'}
                        sx={{fontSize: 15, '&:hover': {cursor: "pointer", backgroundColor: '#206868'}}}
                        onClick={() => setSelectedEditMenu(EditMenu.AddText)}
                    />
                </Stack>
                    {selectedEditMenu === EditMenu.SelectProduct && (
                        <ProductAndImageSelectorView />
                    )}

                    {selectedEditMenu === EditMenu.AddText && (
                        <AdditionalTextEditor />
                    )}

            </Grid>
            <Grid item xs={false} xl={1}/>
        </Grid>
    );
};

export default ProductDesignWindow;
