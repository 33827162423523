
import { styled } from "@mui/material/styles";
import { NavLink as NavLinkBase, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import {
    ListItem,
    ListItemButton,
    ListItemText, 
    Stack,
} from "@mui/material";
import { MenuItems } from "./MenuItems";
import React from "react";

const drawerWidth = 280;

export const CustomList = styled(Stack)(({ theme }) => ({

    // hover states
    '& .MuiListItemButton-root': {
        // marginTop: 1,
    },

    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: "rgb(32, 104, 104, 0.08)",
        // '&, & .MuiListItemIcon-root': {
        //     color: theme.custom.selectedMenuItemTextColor,
        //     fontWeight: "bold",
        //     fontSize: "14px"
        // },
    },

    // hover states
    '& .MuiListItemButton-root:hover': {
        backgroundColor: "rgba(145, 158, 171, 0.08)",
        '&, & .MuiListItemIcon-root': {
            // color: 'yellow',
        },
    },

    //Selected menu item text
    '&& .Mui-selected': {
        '& .MuiListItemText-primary': {
            // color: theme.custom.selectedMenuItemTextColor,
            fontWeight: 500,
            fontSize: "15px",
        }},

    //Menu item text color
    '& .MuiListItemText-primary': {
        color: theme.palette.text.primary,
        fontWeight: 400,
        fontSize: "15px"
    },

    //List item icon
    '& .MuiListItemIcon-root': {
        minWidth: "30px"
    }
}));

export const CustomNavLink = React.forwardRef((props: any, ref) => (
    <NavLinkBase
        // ref={ref}
        {...props}
        // className={props.activeClassName}
    />
));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: 50
}));

const CustomDrawer = ({open, onClose} : {open: boolean, onClose:() => void}) => {

    const { pathname } = useLocation();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            // variant={isGreaterThanSmallBreakpoint ? "persistent" : "temporary"}
            anchor="left"
            open={open}
            onClose={() => onClose()}
        >

            <DrawerHeader sx={{marginBottom: 2}}></DrawerHeader>

            <CustomList sx={{pt: 0}}>
                {MenuItems.map((menuItem, index) => {
                    return (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{pl: 2, pr: 2}}
                        >
                            <ListItemButton
                                selected={menuItem.path === pathname}
                                sx={{borderRadius: 3}}
                                component={CustomNavLink}
                                to={menuItem.path}
                            >
                                <ListItemText primary={menuItem.title} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </CustomList>
        </Drawer>
    );
};

export default CustomDrawer;
