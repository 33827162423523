import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ColorOption, ItemType, SizeOption} from "../types";

//TODO !!IMPORTANT!!! to refactor currently product are also stored in the planning.ts.
// When creatingnew product need to create it in that file too
// Item images are stored in ItemTypeSelect.tsx

export interface TShirtVariant {
    id: number
    size: SizeOption
    color: ColorOption
}

export interface PuzzleVariants {
    id: number
    numberOfPieces: number
    price: number
}

export type Product = {
    id: string
    name: string
} & ({
    productType: ItemType.TSHIRT
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.FEMALE_TSHIRT
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.FEMALE_V_NECK_TSHIRT
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.HOODIE
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.SWEATER
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.MUG
    price: number
    generationPrice: number
    variants: TShirtVariant
} | {
    productType: ItemType.DELIVERY
    price: number
    generationPrice: null
} | {
    productType: ItemType.BOX_DELIVERY
    price: null
    generationPrice: number
    variants: PuzzleVariants
})
// | {
//     productType: ItemType.MOUSE_PAD
//     price: number
//     generationPrice: number
// } | {
//     productType: ItemType.PUZZLE
//     price: null
//     generationPrice: number
//     variants: PuzzleVariants
// }

export interface PredefinedProduct {
    id: string,
    name: string,
    price: number,
    category: string,
    localImageId: string,
    fileURL: string
}

export interface CategoryGroup {
    category: string,
    categoryName: string,
    products: PredefinedProduct[]
}

export interface ProductState {
    products: Product[],
    categoryGroups: CategoryGroup[],
    selectedCategory: CategoryGroup,
    selectedProduct: PredefinedProduct
}

const initialProductState: ProductState = {
    products: [] as Product[],
    categoryGroups: [] as CategoryGroup[],
    selectedCategory: {} as CategoryGroup,
    selectedProduct: {} as PredefinedProduct
}

const productSlice = createSlice({
    name: 'product',
    initialState: initialProductState,
    reducers: {
        setProductState(state, action: PayloadAction<{ products: Product[] }>) {
            state.products = action.payload.products
        },
        setCategoryGroup(state, action: PayloadAction<CategoryGroup[]>) {
            state.categoryGroups = action.payload
        },
        setSelectedCategory(state, action: PayloadAction<CategoryGroup>) {
            state.selectedCategory = action.payload
        },
        clearSelectedCategory(state, action: PayloadAction) {
            state.selectedCategory = {} as CategoryGroup
        },
        setSelectedProduct(state, action: PayloadAction<PredefinedProduct>) {
            state.selectedProduct = action.payload
        },
        clearSelectedProduct(state, action: PayloadAction) {
            state.selectedProduct = {} as PredefinedProduct
        }
    }
})

export const productActions = productSlice.actions
export default productSlice
