import React, {useRef} from 'react';
import {Grid, IconButton, Tooltip} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DesignCanvas from "./DesignCanvas";

// @ts-ignore
import {colors, images} from "./selectors/ItemTypeSelect";

const EditorView = () => {

    const canvasRef = useRef<any>();

    const itemType = useSelector((state: RootState) => state.plan.itemType)
    const color = useSelector((state: RootState) => state.plan.color)

    const removeObjectFromCanvasClicked = () => {
        canvasRef.current.callRemoveObject()
    }

    return (
        <Grid
            container
            sx={{
                position: 'sticky',
                top: 80,
                height: 'fit-content',
                width: '100%',
                borderRadius: 8,
                backgroundColor: '#ffffff66',
                boxShadow: '#BFBFBE 0 -13px 50px',
                maxWidth: 'calc(100vh - 200px)'
            }}
        >

            <Grid item xs={12}>

                <div
                    id="edited-product"
                    style={{'position': 'relative'}}
                >
                    <img src="./tee_and_mug.png" width='100%' alt="" hidden={!!itemType}/>

                    {itemType && color &&
                        <img src={colors[itemType].availableColors.includes(color) ? images[itemType][color] : images[itemType]['white']} width='100%' className="product" alt="" />}
                    {itemType && (
                        <DesignCanvas
                            ref={canvasRef}
                        />
                    )}
                    <div id="remove-icon" style={{position: 'absolute', top: 0, right: 0, margin: 12}}>
                        <Tooltip title='Kijelölt elem törlése'>
                            <IconButton
                                onClick={removeObjectFromCanvasClicked}
                            >
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default EditorView;
