import {ColorOption, ItemTypeOption, OptionParams} from "../../../types"
import { CustomSelect } from "./CustomSelect"
import {colors} from "./ItemTypeSelect";

export function ColorSelect({ selected, setSelected, itemType }: {
    selected: ColorOption
    setSelected: (value?: ColorOption) => void
    itemType?: ItemTypeOption,
}) {

    const colorParams: OptionParams<ColorOption> = {
        title: 'Szín',
        names: {},
        descriptions: {}
    }

    let colorOptionsNumber = 0

    if (itemType) {
        for (const color of colors[itemType].availableColors) {
            colorParams.names[color] = '.';
            if (colorParams.descriptions !== undefined) {
                colorParams.descriptions[color] = colors[itemType].colorCodes[color];
                colorOptionsNumber++
            }
        }
    }

    const nrInRow = Math.max(colorOptionsNumber < 8 ? colorOptionsNumber : Math.ceil(colorOptionsNumber / 2), 6)

    return (
        <CustomSelect
            selected={selected}
            setSelected={setSelected}
            params={colorParams}
            color 
            horizontal
            nrInRow={nrInRow} />
    )
}
