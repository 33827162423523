import React, {useState} from 'react';
import {ItemTypeSelect} from "./selectors/ItemTypeSelect";
import {GenerationState, planActions} from "../../redux/planSlice";
import {DesignTypeSelect} from "./selectors/DesignTypeSelect";
import {Alert, AlertTitle, Box, Button, LinearProgress, TextField, Typography, useTheme} from "@mui/material";
import {generateImages, getExamplePrompt} from "../../redux/action/generate";
import {HiOutlineSparkles} from "react-icons/hi2";
import {CustomSelect} from "./selectors/CustomSelect";
import {ColorSelect} from "./selectors/ColorSelect";
import {SizeSelect} from "./selectors/SizeSelect";
import SizeTable from "./SizeTable";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {OptionParams, PlanNumberOption, hasMultipleColor, hasMultipleSize} from '../../types';

const ProductAndImageSelectorView = () => {

    const theme = useTheme()
    const dispatch = useDispatch()

    const [isSizeTableOpen, setSizeTableOpen] = useState<boolean>(false);

    const itemType = useSelector((state: RootState) => state.plan.itemType)
    const designType = useSelector((state: RootState) => state.plan.designType)
    const size = useSelector((state: RootState) => state.plan.size)
    const color = useSelector((state: RootState) => state.plan.color)
    const generationState = useSelector((state: RootState) => state.plan.generationState)
    const premiumGenerationState = useSelector((state: RootState) => state.plan.premiumGenerationState)
    const generation = useSelector((state: RootState) => state.plan.generation)
    const premiumGeneration = useSelector((state: RootState) => state.plan.premiumGeneration)
    const selectedPlan = useSelector((state: RootState) => state.plan.selectedPlan)
    const prompt = useSelector((state: RootState) => state.plan.prompt)

    const handleExamplePromptButtonHandler = () => {
        dispatch(getExamplePrompt((prompt: string) => {
            dispatch(planActions.setPrompt(prompt))
        }))
    }

    const createPlanNumberParams = (image1: string, image2: string): OptionParams<PlanNumberOption> => ({
        title: 'Elkészült képek',
        names: {
            '1': image1,
            '2': image2
        }
    })

    const createPlanNumberParamsPremium = (image1: string, image2: string) => ({
        title: 'Prémium képek',
        names: {
            '3': image1,
            '4': image2
        }
    })

    const startImageGeneratePremium = (prompt: string) => {
        dispatch(planActions.setSelectedPlan(undefined))
        dispatch(generateImages(prompt, { premium: true }))
    }

    const startImageGenerate = (prompt: string) => {
        dispatch(planActions.setSelectedPlan(undefined))
        dispatch(generateImages(prompt, {}))
    }

    return (
        <>
            <Box
                sx={{
                    mt: 2,
                    marginBottom: 5,
                    maxWidth: 500,
                    color: theme.palette.text.primary
                }}
            >

                <ItemTypeSelect
                    selected={itemType}
                    setSelected={value => dispatch(planActions.setItemType({itemType: value, color: color}))}
                    color={color}
                />

                <DesignTypeSelect
                    selected={designType}
                    setSelected={value => dispatch(planActions.setDesignType(value))}
                />

                {designType === 'custom' && (
                    <Box
                        sx={{
                            width: '100%'
                        }}>
                        <Box sx={{width: '100%', marginBottom: 4}}>
                            <Typography
                                sx={{
                                    fontSize: 19,
                                    marginBottom: 1
                                }}
                            >
                                Generáld le saját képed
                            </Typography>

                            <TextField
                                label='Mi legyen a képen?'
                                multiline
                                value={prompt}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        window.scrollTo({
                                            top: 350,
                                            behavior: 'smooth',
                                        })
                                        startImageGenerate(prompt)
                                        startImageGeneratePremium(prompt)
                                    }
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch(planActions.setPrompt(event.target.value))
                                }}
                                rows={6}
                                sx={{
                                    width: '100%',
                                    marginBottom: 1
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <div style={{position: 'absolute', width: '100%', bottom: 10, left: 10}}>
                                            <Button
                                                startIcon={<HiOutlineSparkles/>}
                                                onClick={handleExamplePromptButtonHandler}
                                            >
                                                Példa bemenet
                                            </Button>
                                        </div>
                                    ),

                                }}
                            />

                            <Button
                                variant='contained'
                                sx={{
                                    width: '100%'
                                }}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 500,
                                        behavior: 'smooth',
                                    })
                                    setTimeout(() => {
                                        startImageGeneratePremium(prompt)
                                        startImageGenerate(prompt)
                                    }, 800)
                                }}
                                disabled={
                                    generationState === GenerationState.Started || 
                                    generationState === GenerationState.Warning || 
                                    premiumGenerationState === GenerationState.Started ||
                                    premiumGenerationState === GenerationState.Warning}>
                                <Typography
                                    sx={{
                                        color: "white",
                                        fontSize: 16,
                                        borderRadius: 15
                                    }}
                                >
                                    {generationState === GenerationState.Done && premiumGenerationState === GenerationState.Done ? 'Új képek készítése' : 'Elkészít'}
                                </Typography>
                            </Button>
                        </Box>

                        {(generationState === GenerationState.Started || generationState === GenerationState.Warning) && (
                            <Box width='100%'>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        marginBottom: 1
                                    }}
                                >
                                    Kép generálás...
                                </Typography>
                                <LinearProgress
                                    variant='determinate'
                                    value={generation.progress}
                                    sx={{marginBottom: 4}}
                                />
                            </Box>
                        )}

                        {generationState === GenerationState.Error && (
                            <Alert severity="error" variant="outlined" sx={{marginBottom: 4}}>
                                <AlertTitle>Hiba</AlertTitle>
                                A kép elkészítése sikertelen — <strong>kérjük próbálkozzon újra hamarosan!</strong>
                            </Alert>
                        )}

                        {generationState === GenerationState.Warning && (
                            <Alert severity="warning" variant="outlined" sx={{marginBottom: 4}}>
                                <AlertTitle>Hupsz!</AlertTitle>
                                A kép készítése a vártnál több ideig tart (kb. {generation.eta} mp), egy kávészünet után
                                nézz vissza — <strong>köszönjük a türelmed!</strong>
                            </Alert>
                        )}


                        {generationState === GenerationState.Done && (
                            <Box width='100%'>
                                <CustomSelect
                                    selected={selectedPlan}
                                    setSelected={value => dispatch(planActions.setSelectedPlan(value))}
                                    params={createPlanNumberParams(generation.images[0]?.link, generation.images[1]?.link)}
                                    horizontal
                                    image
                                    noMargin
                                />
                            </Box>
                        )}

                        {(premiumGenerationState === GenerationState.Started || generationState === GenerationState.Warning) && (
                            <Box width='100%'>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        marginBottom: 1
                                    }}
                                >
                                    Prémium kép generálás...
                                </Typography>
                                <LinearProgress
                                    variant='determinate'
                                    value={premiumGeneration.progress}
                                    sx={{marginBottom: 4}}
                                />
                            </Box>
                        )}

                        {premiumGenerationState === GenerationState.Error && (
                            <Alert severity="error" variant="outlined" sx={{marginBottom: 4}}>
                                <AlertTitle>Hiba</AlertTitle>
                                A kép elkészítése sikertelen — <strong>kérjük próbálkozzon újra hamarosan!</strong>
                            </Alert>
                        )}

                        {premiumGenerationState === GenerationState.Warning && (
                            <Alert severity="warning" variant="outlined" sx={{marginBottom: 4}}>
                                <AlertTitle>Hupsz!</AlertTitle>
                                A kép készítése a vártnál több ideig tart (kb. 30 mp), egy kávészünet után
                                nézz vissza — <strong>köszönjük a türelmed!</strong>
                            </Alert>
                        )}

                        {premiumGenerationState === GenerationState.Done && (
                            <Box width='100%'>
                                <CustomSelect
                                    selected={selectedPlan}
                                    setSelected={value => dispatch(planActions.setSelectedPlan(value))}
                                    params={createPlanNumberParamsPremium(premiumGeneration.images[0]?.link, premiumGeneration.images[1]?.link)}
                                    horizontal
                                    image
                                />
                            </Box>
                        )}
                    </Box>
                )}
                <Box width='100%'>
                    {hasMultipleColor(itemType) && (
                        <ColorSelect
                            selected={color}
                            setSelected={value => value && dispatch(planActions.setColor(value))}
                            itemType={itemType}
                        />
                    )}

                    {hasMultipleSize(itemType) && (
                        <SizeSelect
                            selected={size}
                            setSelected={value => dispatch(planActions.setSize(value))}
                            openSizeChart={() => setSizeTableOpen(true)}
                            itemType={itemType}
                        />
                    )}
                </Box>

            </Box>

            <SizeTable
                open={isSizeTableOpen}
                selectedItemType={itemType}
                onClose={() => setSizeTableOpen(false)}
            />
        </>
    );
};

export default ProductAndImageSelectorView;
