import { createSelector } from "@reduxjs/toolkit"
import { PlanState } from "./planSlice"
import {ColorOption, DesignTypeOption, ItemTypeOption, SizeOption} from "../types";
import {names} from "../components/planning/selectors/ItemTypeSelect";

export const getItemNames = (itemType: ItemTypeOption | undefined, designType: DesignTypeOption | undefined, color: ColorOption | undefined, size: SizeOption | undefined) => {
    let row1, row2 = ''

    const designTypes = {
        'custom': 'Egyedi tervezésű',
        'seasonal': 'Szezonális'
    };

    const productTypes = {
        'tshirt': 'póló',
        'mug': 'bögre',
        'female_tshirt': 'női környakas póló',
        'female_v_neck_tshirt': 'női V-nyakú póló',
        'hoodie': 'kapucnis pulóver',
        'sweater': 'pulóver',
        'canvas_bag': 'vászontáska'
    };

    if (itemType) {
        const design = designType ? designTypes[designType] : '';
        const product = productTypes[itemType];
        row1 = `${design} ${product}`;
    }

    if (color) {
        const colorName = {
            black: "Fekete",
            blue: "Kék",
            green: "Zöld",
            grey: "Szürke",
            red: "Piros",
            white: "Fehér",
            royal_blue: "Királykék",
            navy_blue: "Tengerészkék",
            sky_blue: "Ég kék",
            kelly_green: "Kelly zöld",
            bottle_green: "Üveg zöld",
            heather_grey: "Bordó szürke",
            sport_grey: "Sport szürke",
            sunflower: "Napraforgó sárga",
            orange: "Narancssárga",
            fuchsia: "Fukszia",
            light_pink: "Világos rózsaszín",
            pink: "Rózsaszín",
            graphite: "Grafitszürke",
            natural: 'Természetes'
            
          }[color];
        row2 = `Szín: ${colorName}`
    }
    if (size) {
        const sizeName = size.toUpperCase()
        row2 = row2.concat(`, Méret: ${sizeName}`)
    }

    return [row1, row2]
}

export const getSummerizers = createSelector(
    (state: PlanState) => state.itemType,
    (state: PlanState) => state.designType,
    (state: PlanState) => state.color,
    (state: PlanState) => state.size,
    (itemType, designType, color, size) => {
        return getItemNames(itemType, designType, color, size);
    }
)
