import React from 'react';
import {LinearProgress, Stack} from "@mui/material";

const LinearProgressIndicator = () => {
    return (
        <Stack direction="column" alignItems="center" justifyContent="center"
               sx={{ width: '100%', height: "100%" }}
        >
            <LinearProgress sx={{width: "60%"}} />
        </Stack>
    );
};

export default LinearProgressIndicator;