import React, {useEffect} from 'react';
import {Badge, Button, Grid, IconButton, Menu, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import { OrderItem} from "../../redux/orderSlice";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OrderItems from "../shopping-cart/OrderItems";

const CustomShoppingCartIcon = () => {
    const navigation = useNavigate();

    const cartLength: number = useSelector<RootState, OrderItem[]>(state => state.order.items).length;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToChart = () => {
        navigation('kosar');
        handleClose();
    }

    useEffect(() => {
        const cartEll: any = document.querySelector('#cart-button');
        if (cartEll) {

            let isMobile = false
            cartEll.addEventListener('touchstart', (e: any) => {
                isMobile = true
            }, false);

            cartEll.onclick = (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                navigation('kosar');
            }

            cartEll.onmouseenter = (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                if(!isMobile) {
                    handleMouseEnter(e);
                }
            }
        }

        return () => {

        };
    }, [navigation]);

    return (
        <React.Fragment>
            <IconButton
                onClick={navigateToChart}
                // onMouseEnter={handleMouseEnter}
                id='cart-button'
            >
                <Badge badgeContent={cartLength} color="primary">
                    <ShoppingCartIcon style={{color: "black"}}/>
                </Badge>
            </IconButton>

            {/*<Tooltip*/}
            {/*    title='Bejelentkezés'*/}
            {/*    style={{fontSize: 26, marginLeft: 18, marginRight: 5, color: 'black'}}*/}
            {/*>*/}
            {/*    <IconButton*/}
            {/*        onClick={() => {navigation('bejelentkezes')}}*/}
            {/*    >*/}
            {/*        <PersonIcon />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}

            <Menu
                anchorEl={anchorEl}
                id="shopping-cart-menu"
                open={open}
                onClose={handleClose}
                onMouseLeave={handleClose}
                disableScrollLock={true}
                MenuListProps={{
                    onMouseLeave: handleClose,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 400,
                        maxWidth: 750,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        borderRadius: 3,
                        overflowY: "auto",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Grid container spacing={2} sx={{pt: 1, pb: 1, pl: 2, pr: 2}}>
                    {cartLength === 0 ? (
                        <Grid item xs={12}>
                            <Typography
                                variant='subtitle2'
                                sx={{textAlign: 'center'}}
                            >
                                A kosár tartalma üres
                            </Typography>
                            <Button
                                onClick={navigateToChart}
                                variant='contained'
                            >
                                <Typography
                                    variant='subtitle2'
                                    sx={{fontSize: 14}}
                                >
                                    Megrendelem
                                </Typography>
                            </Button>
                        </Grid>
                    ) : (

                        <Grid item xs={12}>
                            <Typography
                                variant='subtitle2'
                                sx={{textAlign: 'center', marginBottom: 2}}
                            >
                                A kosár tartalma
                            </Typography>

                            <OrderItems textFontSize={13} titleFontSize={16} buttonFontSize={14} smallDesign={true} orderButtonHandler={navigateToChart}/>

                        </Grid>
                    )}

                </Grid>
            </Menu>
        </React.Fragment>
    );
};

export default CustomShoppingCartIcon;
