import axios, {AxiosError, AxiosResponse} from "axios";
import ReactGA from "react-ga4";

export interface ContactUsRequest {
    username: string,
    email: string,
    message: string
}

export interface ContactUsResponse {
    status: string
}

export const sendContactEmail = (request: ContactUsRequest, onSuccess: () => void, onError: () => void): any => {
    return async (dispatch: any) => {

        axios.post('/contact/email', request)
            .then((res: AxiosResponse<ContactUsResponse>) => {
                onSuccess();

                //User send contact email
                ReactGA.event({category: "conversion", action: "userSendContactEmail"});
            })
            .catch((err: AxiosError<any>) => {
                onError();
            });
    }
}