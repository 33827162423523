// CanvasContext.tsx
import React, { createContext, useContext, useRef, useEffect } from 'react';
import { fabric } from 'fabric';

interface CanvasContextProps {
    canvas: fabric.Canvas | null;
}

const CanvasContext = createContext<CanvasContextProps>({ canvas: null });

export const CanvasProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const canvasRef = useRef<fabric.Canvas | null>(null);

    useEffect(() => {
        const canvas = new fabric.Canvas('c'); // Replace 'c' with the actual ID of your canvas element
        canvasRef.current = canvas;
    }, []);

    return (
        <CanvasContext.Provider value={{ canvas: canvasRef.current }}>
            {children}
        </CanvasContext.Provider>
    );
};

export const useCanvas = () => {
    return useContext(CanvasContext);
};
