import React, {useEffect} from 'react';
import Lottie from 'react-lottie-player';
import preparingAnimation from '../../assets/preparing-order.json'
import successAnimation from '../../assets/success.json'
import expiredPayment from '../../assets/payment-expired.json'
import paymentWarning from '../../assets/payment-warning.json'
import errorAnimation from '../../assets/error4.json'
import {Button, Grid, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import isEmpty from "../../utils/isEmpty";
import {PaymentStatus} from "../../redux/orderSlice";
import LinearProgressIndicator from "../LinearProgressIndicator";
import {useTheme} from "@mui/material/styles";

const OrderProcessing = ({increaseOrderStep}: { increaseOrderStep: () => void }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const paymentStatus: PaymentStatus = useSelector<RootState, PaymentStatus>(state => state.order.paymentStatus);
    const orderId: string = useSelector<RootState, string>(state => state.order.orderId);
    const gatewayUrl: string | undefined = useSelector<RootState, string | undefined>(state => state.order.gatewayUrl);
    const error: string = useSelector<RootState, string>(state => state.order.orderErrorMessage);

    useEffect(() => {
        if (paymentStatus === PaymentStatus.Succeeded) {
            increaseOrderStep();
        }
    }, [paymentStatus])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} sx={{width: '100%', display: 'flex', justifyContent: 'center', mb: 4}}>

                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{marginLeft: 4, marginRight: 4}}>
                        {(paymentStatus === PaymentStatus.OrderPreparing || paymentStatus === PaymentStatus.Prepared) && (
                            <>
                                <Lottie
                                    loop
                                    animationData={preparingAnimation}
                                    play
                                    style={{width: 180, height: 180}}
                                />
                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold', marginTop: 2}}
                                >
                                    {paymentStatus === PaymentStatus.Prepared ? (
                                        "Rendelés előkészítése, fizetés folyamatban"
                                    ) : (
                                        "Rendelés előkészítése"
                                    )}
                                </Typography>

                                {paymentStatus === PaymentStatus.Prepared && (
                                    <Typography
                                        variant='subtitle2'
                                        sx={{marginTop: 2, textAlign: 'center'}}
                                    >

                                        Ha az autómatikus átírányítás sikertelen kérjük az alábbi linkre kattinva fizesse ki rendelését. <br/>
                                        <a href={gatewayUrl} >{gatewayUrl}</a>

                                    </Typography>
                                )}

                                <Typography
                                    variant='subtitle2'
                                    sx={{marginTop: 2, textAlign: 'center'}}
                                >
                                    Kis türelmét kérjük, a rendelését előkészítjük. <br/>
                                    Email-ban fog visszaigazolást kapni ami tartalmazza a fizetéshez szükséges adatok
                                    is.
                                </Typography>
                            </>
                        )}

                        {paymentStatus === PaymentStatus.Canceled && (
                            <>
                                <Lottie
                                    loop={false}
                                    animationData={paymentWarning}
                                    play
                                    style={{width: 180, height: 180}}
                                />


                                <Typography
                                    variant='subtitle2'
                                    sx={{marginTop: 2, textAlign: 'center'}}
                                >
                                    A fizetés megszakításra került. <br/>
                                    Abban az esetben ha nem szeretne elállni a megrendelésétől, kérjük vegye fel velünk a kapcsolatot a <a style={{ color: theme.palette.primary.main, fontWeight: '600' }} href = "mailto: rendeles@aimerch.hu">rendeles@aimerch.hu</a> címen, és küldje el nekünk a megrendelés azonosítóját. (Rendelés azonosító: <a style={{color: '#0070E0'}} href={`${process.env.REACT_APP_GUI_BASE_URL}/order?orderId=${orderId}`}>{orderId}</a>)
                                </Typography>
                            </>
                        )}


                        {paymentStatus === PaymentStatus.Succeeded && (
                            <>
                                <Lottie
                                    loop={false}
                                    animationData={successAnimation}
                                    play
                                    style={{width: 180, height: 180}}
                                />
                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Rendelés és fizetés sikeres
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{marginTop: 2, textAlign: 'center'}}
                                >
                                    Abban az esetben ha a rendelésről nem érkezett visszaigazolás kérjük emailben keressen minket a <a style={{color: '#0070E0'}} href="mailto: rendeles@aimerch.hu">rendeles@aimerch.hu</a> címen.<br/>
                                    Kérjük küldje el az alábbi rendelés azonosítót. (Rendelés azonosító: <a style={{color: '#0070E0'}} href={`${process.env.REACT_APP_GUI_BASE_URL}/order?orderId=${orderId}`}>{orderId}</a>)
                                </Typography>

                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold', marginTop: 4}}
                                >
                                    Köszönjük rendelését
                                </Typography>

                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        navigation('/tervezes')
                                    }}
                                    sx={{marginTop: 2}}
                                >
                                    <Typography sx={{
                                        color: 'white',
                                    }} fontSize={20} fontWeight={600} fontFamily={'Lexend'}>
                                        Tervezek
                                    </Typography>
                                </Button>
                            </>
                        )}

                        {(paymentStatus === PaymentStatus.Expired) && (
                            <>
                                <Lottie
                                    loop={false}
                                    animationData={expiredPayment}
                                    play
                                    style={{width: 180, height: 180}}
                                />
                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Sikertelen fizetés
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{marginTop: 2, textAlign: 'center'}}
                                >
                                    Ha továbbra is szeretné megrendelni a termékeket, kérjük, vegye fel velünk a kapcsolatot a következő e-mail címen: <a style={{color: '#0070E0'}} href="mailto:rendeles@aimerch.hu">rendeles@aimerch.hu</a>. <br/>
                                    Kérjük, az e-mail tárgyában tüntesse fel a 'Sikertelen fizetés' kifejezést, és küldje el az alábbi rendelés azonosítót: {orderId}. <br/>
                                </Typography>
                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold', marginTop: 4}}
                                >
                                    Köszönjük megértését
                                </Typography>

                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        navigation('/tervezes')
                                    }}
                                    sx={{marginTop: 2}}
                                >
                                    <Typography sx={{
                                        color: 'white',
                                    }} fontSize={20} fontWeight={600} fontFamily={'Lexend'}>
                                        Tervezek
                                    </Typography>
                                </Button>
                            </>
                        )}

                        {!isEmpty(error) && (
                            <>
                                <Lottie
                                    loop={false}
                                    animationData={errorAnimation}
                                    play
                                    style={{width: 180, height: 180}}
                                />
                                <Typography
                                    variant='h5'
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Rendelés sikertelen
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{marginTop: 2, textAlign: 'center'}}
                                >
                                    Elnézést a kellemetlenségért a rendelés feldolgozása sikertelen. Kérjük vegye fel velünk a kapcsolatot a <a style={{color: '#0070E0'}} href="mailto: rendeles@aimerch.hu">rendeles@aimerch.hu</a> címen, és igyekszünk megoldani minnél hamarabb a problémát.<br/>
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default OrderProcessing;