import { Info, InfoOutlined } from "@mui/icons-material"
import { Box, Button, Checkbox, Divider, Grid, Tooltip, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { getCookie, setCookie } from "typescript-cookie"


const Cookie = () => {
    const theme = useTheme()
    const [ fadeOut, setFadeout ] = useState(false)
    const [ visible, setVisible ] = useState(false)
    const [ settings, setSettings ] = useState(false)
    const [ marketingCookies, setMarketingCookies ] = useState(true)

    useEffect(() => {
        const cookiesAccepted = getCookie('cookiesAccepted')
        if (cookiesAccepted !== 'true') setVisible(true)
    }, [])

    useEffect(() => {
        let timer: NodeJS.Timeout
        if (fadeOut) {
            timer = setTimeout(() => setVisible(false), 1000)
        }
    
        return(() => clearTimeout(timer))
    }, [ fadeOut ])

    const acceptCookies = () => {
        setCookie('cookiesAccepted', true, { expires: 30 })
        setFadeout(true)
    }

    const openSettings = () => {
        setSettings(settings => !settings)
    }

    const visibleStyle = {
        opacity: 1
    }

    const fadeOutStyle = {
        opacity: 0,
        transition: 'opacity 0.5s ease-out'
    }

    return (
        <>{visible && <Box style={fadeOut ? fadeOutStyle : visibleStyle} position='fixed'
            bottom={0} 
            left={0} 
            width='100%' 
            height={settings ? 95 : 45}
            zIndex={99}
            bgcolor='white'
            sx={{
                [theme.breakpoints.down('md')]: {
                    height: settings ? 130 : 80
                },
                [theme.breakpoints.only('xs')]: {
                    height: settings ? 150 :  90
                }
            }}
        >
            <Grid container bgcolor={`${theme.palette.primary.main}88`}
                width='100%' 
                height='100%'
                zIndex={1000}
                alignItems='center'
                >
                <Grid item xs={12} md={6} lg={7} display='flex' justifyContent='start' sx={{
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                }}>
                    <Typography textAlign='center' fontSize={12} sx={{
                        [theme.breakpoints.up('md')]: {
                            marginLeft: 4
                        },
                        [theme.breakpoints.down('md')]: {
                            marginLeft: 1,
                            marginRight: 1
                        }
                    }}>Weboldalunk használatával jóváhagyod a cookie-k használatát a cookie-kkal kapcsolatos irányelv értelmében.</Typography>
                </Grid>
                <Grid item xs={12} md={3} display='flex' justifyContent='end' sx={{
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                }}>
                    <Button sx={{fontSize: 12}} onClick={openSettings}>Beállítások</Button>
                    <Button variant='contained' sx={{
                        paddingLeft: 1, 
                        paddingRight: 1, 
                        paddingTop: 0.3, 
                        paddingBottom: 0.3, 
                        fontSize: 12,
                        [theme.breakpoints.up('md')]: {
                            marginRight: 4
                        }
                    }} onClick={acceptCookies}>Elfogadom</Button>
                </Grid>
                {settings && <><Grid item xs={12}><Divider sx={{color: 'black'}} variant='middle' /></Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center'}}>
                    <Checkbox checked disabled size='small' sx={{marginLeft: 2}} />
                    <Typography fontSize={12}>Szükséges cookie-k&nbsp;</Typography>
                    <Tooltip enterTouchDelay={0} title='Ezek a cookie-k biztosítják a webáruház működőképességét'>
                        <InfoOutlined fontSize='small' />
                    </Tooltip>
                </Grid>
                <Grid item sx={{display: 'flex', alignItems: 'center' }}>
                    <Checkbox sx={{marginLeft: 1}} size='small' checked={marketingCookies} onChange={() => setMarketingCookies(current => !current)}/>
                    <Typography fontSize={12}>Marketing cookie-k&nbsp;</Typography>
                    <Tooltip enterTouchDelay={0} title='Ezek a cookie-k segítenek abban, hogy az érdeklődésednek megfelelő reklámokat jelníthessünk meg'>
                        <InfoOutlined fontSize='small' />
                    </Tooltip>
                </Grid></>}
            </Grid>
        </Box>}</>
    )
}

export default Cookie
