import { useSelector } from "react-redux"
import {OptionParams, ColorOption, ImagePaths, ItemType, ItemTypeOption, ProductDetails} from "../../../types"
import { CustomSelect } from "./CustomSelect"
import { RootState } from "../../../redux/store"
import { productPriceSelector } from "../../../redux/productSelectors"

//TODO !!IMPORTANT!!! to refactor currently product are also stored in the planning.ts.
// When creatingnew product need to create it in that file too
// Item images are stored in ItemTypeSelect.tsx

//Images from this site http://polonagyker.hu/products

//Man simple tshirt
import whiteTShirt from '../../../assets/products/tshirt/white.png'
import blackTShirt from '../../../assets/products/tshirt/black.png'
import redTShirt from '../../../assets/products/tshirt/red.png'
import royalblueTShirt from '../../../assets/products/tshirt/royal-blue.png'
import kellyGreenTShirt from '../../../assets/products/tshirt/kelly-green.png'
import bottleGreenTShirt from '../../../assets/products/tshirt/bottle-green.png'
import greyTShirt from '../../../assets/products/tshirt/grey.png'
import sunflowerTShirt from '../../../assets/products/tshirt/sunflower.png'
import orangeTShirt from '../../../assets/products/tshirt/orange.png'

//Female simple tshirt
import blackFemaleTShirt from '../../../assets/products/tshirt/female/black.png'
import fuchsiaFemaleTShirt from '../../../assets/products/tshirt/female/fuchsia.png'
import heatherGreyFemaleTShirt from '../../../assets/products/tshirt/female/heather-grey.png'
import kellyGreenFemaleTShirt from '../../../assets/products/tshirt/female/kelly-green.png'
import lightPinkFemaleTShirt from '../../../assets/products/tshirt/female/light-pink.png'
import navyBlueFemaleTShirt from '../../../assets/products/tshirt/female/navy-blue.png'
import orangeFemaleTShirt from '../../../assets/products/tshirt/female/orange.png'
import redFemaleTShirt from '../../../assets/products/tshirt/female/red.png'
import royalBlueFemaleTShirt from '../../../assets/products/tshirt/female/royal-blue.png'
import skyBlueFemaleTShirt from '../../../assets/products/tshirt/female/sky-blue.png'
import sunflowerFemaleTShirt from '../../../assets/products/tshirt/female/sunflower.png'
import whiteFemaleTShirt from '../../../assets/products/tshirt/female/white.png'

//Mug
import mug from '../../../assets/products/mug/mug.png'

//Female V-neck
import blackFemaleVNeckTShirt from '../../../assets/products/v-neck/female/black.png'
import kellyGreenFemaleVNeckTShirt from '../../../assets/products/v-neck/female/kelly-green.png'
import navyBlueFemaleVNeckTShirt from '../../../assets/products/v-neck/female/navy-blue.png'
import pinkFemaleVNeckTShirt from '../../../assets/products/v-neck/female/pink.png'
import redFemaleVNeckTShirt from '../../../assets/products/v-neck/female/red.png'
import royalBlueFemaleVNeckTShirt from '../../../assets/products/v-neck/female/royal-blue.png'
import sportGreyFemaleVNeckTShirt from '../../../assets/products/v-neck/female/sport-grey.png'
import whiteFemaleVNeckTShirt from '../../../assets/products/v-neck/female/white.jpeg'

//Hoodie
import blackHoodie from '../../../assets/products/hoodie/black.png'
import graphiteHoodie from '../../../assets/products/hoodie/graphite.png'
import heatherGreyHoodie from '../../../assets/products/hoodie/heather-gray.png'
import kellyGreenHoodie from '../../../assets/products/hoodie/kelly-green.png'
import lightPingHoodie from '../../../assets/products/hoodie/light-pink.png'
import navyBlueHoodie from '../../../assets/products/hoodie/navy-blue.png'
import orangeHoodie from '../../../assets/products/hoodie/orange.png'
import redHoodie from '../../../assets/products/hoodie/red.png'
import royalBlueHoodie from '../../../assets/products/hoodie/royal-blue.png'
import skyBlueHoodie from '../../../assets/products/hoodie/sky-blue.png'
import sunflowerHoodie from '../../../assets/products/hoodie/sunflower.png'
import whiteHoodie from '../../../assets/products/hoodie/white.png'

//Sweater
import blackSweater from '../../../assets/products/sweater/black.png'
import whiteSweater from '../../../assets/products/sweater/white.png'

//Canvas bag
import whiteCanvasBag from '../../../assets/products/canvas-bag/white.png'
import naturalCanvasBag from '../../../assets/products/canvas-bag/natural.png'

export const names: Record<ItemTypeOption, string> = {
    'tshirt': 'Póló',
    'mug': 'Bögre',
    'female_tshirt': 'Női környakas póló',
    'female_v_neck_tshirt': 'Női V-nyakú póló',
    'hoodie': 'Kapucnis pulóver',
    'sweater': 'Pulóver',
    'canvas_bag': 'Vászon táska',
    // 'mouse_pad': 'Egér pad',
    // 'puzzle': 'Puzzle',
}

export const images: Record<ItemTypeOption, ImagePaths> = {
    'tshirt': {
        'white': whiteTShirt,
        'black': blackTShirt,
        'red': redTShirt,
        'royal_blue': royalblueTShirt,
        'kelly_green': kellyGreenTShirt,
        'bottle_green': bottleGreenTShirt,
        'grey': greyTShirt,
        'sunflower': sunflowerTShirt,
        'orange': orangeTShirt
    },
    'mug': {
        'white': mug,
    },
    'female_tshirt': {
        'white': whiteFemaleTShirt,
        'black': blackFemaleTShirt,
        'fuchsia': fuchsiaFemaleTShirt,
        'heather_grey': heatherGreyFemaleTShirt,
        'kelly_green': kellyGreenFemaleTShirt,
        'light_pink': lightPinkFemaleTShirt,
        'navy_blue': navyBlueFemaleTShirt,
        'orange': orangeFemaleTShirt,
        'red': redFemaleTShirt,
        'royal_blue': royalBlueFemaleTShirt,
        'sky_blue': skyBlueFemaleTShirt,
        'sunflower': sunflowerFemaleTShirt,
    },
    'female_v_neck_tshirt': {
        'white': whiteFemaleVNeckTShirt,
        'black': blackFemaleVNeckTShirt,
        'kelly_green': kellyGreenFemaleVNeckTShirt,
        'navy_blue': navyBlueFemaleVNeckTShirt,
        'pink': pinkFemaleVNeckTShirt,
        'red': redFemaleVNeckTShirt,
        'royal_blue': royalBlueFemaleVNeckTShirt,
        'sport_grey': sportGreyFemaleVNeckTShirt,
    },
    'hoodie': {
        'white': whiteHoodie,
        'black': blackHoodie,
        'graphite': graphiteHoodie,
        'heather_grey': heatherGreyHoodie,
        'kelly_green': kellyGreenHoodie,
        'light_pink': lightPingHoodie,
        'navy_blue': navyBlueHoodie,
        'orange': orangeHoodie,
        'red': redHoodie,
        'royal_blue': royalBlueHoodie,
        'sky_blue': skyBlueHoodie,
        'sunflower': sunflowerHoodie,
    },
    'sweater': {
        'white': whiteSweater,
        'black': blackSweater
    },
    'canvas_bag': {
        'white': whiteCanvasBag,
        'natural': naturalCanvasBag
    }
    // 'mouse_pad': {
    //     'white': './tshirt/tshirt.png'
    // },
    // 'puzzle': {
    //     'white': './tshirt/tshirt.png'
    // }
}

export const colors: Record<ItemTypeOption, ProductDetails> = {
    'tshirt': {
        availableColors: ['white', 'black', 'red', 'kelly_green', 'bottle_green', 'royal_blue', 'grey', 'sunflower', 'orange'],
        colorCodes: {
            'white': '#fff',
            'black': '#141414',
            'red': '#c60a1e',
            'kelly_green': '#069552',
            'bottle_green': '#284e39',
            'royal_blue': '#0a5ca2',
            'grey': '#c3c6cb',
            'sunflower': '#f2bd17',
            'orange' : '#e35a16'
        },
        availableSizes: ['s', 'm', 'l', 'xl', 'xxl' ]
    },
    'mug': {
        availableColors: ['white'],
        colorCodes: {
            'white': '#fff',
        },
        availableSizes: []
    },
    'female_tshirt': {
        availableColors: ['white', 'black', 'fuchsia', 'heather_grey', 'kelly_green', 'light_pink', 'navy_blue', 'orange', 'red', 'royal_blue', 'sky_blue', 'sunflower'],
        colorCodes: {
            'white': '#fff',
            'black': '#000000',
            'fuchsia': '#da3372',
            'heather_grey': '#c7c4c9',
            'kelly_green': '#049658',
            'light_pink': '#ebb6ca',
            'navy_blue': '#062f58',
            'orange': '#e2520d',
            'red': '#c30c24',
            'royal_blue': '#0a5ca2',
            'sky_blue': '#91bee1',
            'sunflower': '#f6b536',
        },
        availableSizes: ['s', 'm', 'l', 'xl', 'xxl' ]
    },
    'female_v_neck_tshirt': {
        availableColors: ['white', 'black', 'kelly_green', 'navy_blue', 'pink', 'red', 'royal_blue', 'sport_grey'],
        colorCodes: {
            'white': '#fff',
            'black': '#000000',
            'kelly_green': '#0a813b',
            'navy_blue': '#021436',
            'pink': '#d8799e',
            'red': '#c8131a',
            'royal_blue': '#113f77',
            'sport_grey': '#bbbbbb',
        },
        availableSizes: ['s', 'm', 'l', 'xl', 'xxl' ]
    },
    'hoodie': {
        availableColors: ['white', 'black', 'graphite', 'heather_grey', 'kelly_green', 'light_pink', 'navy_blue', 'orange', 'red', 'royal_blue', 'sky_blue', 'sunflower'],
        colorCodes: {
            'white': '#fff',
            'black': '#000000',
            'graphite': '#5f5d5d',
            'heather_grey': '#babbbf',
            'kelly_green': '#029a59',
            'light_pink': '#eba7be',
            'navy_blue': '#183053',
            'orange': '#f55f0f',
            'red': '#cf0a21',
            'royal_blue': '#0f4187',
            'sky_blue': '#9ecae9',
            'sunflower': '#ebb506',
        },
        availableSizes: ['s', 'm', 'l', 'xl', 'xxl' ]
    },
    'sweater': {
        availableColors: ['white', 'black'],
        colorCodes: {
            'white': '#fff',
            'black': '#000000'
        },
        availableSizes: ['s', 'm', 'l', 'xl', 'xxl' ]
    },
    'canvas_bag': {
        availableColors: ['white', 'natural'],
        colorCodes: {
            'white': '#fff',
            'natural': '#d5cbbb'
        },
        availableSizes: []
    }
    // 'mouse_pad': {
    //     availableColors: ['white'],
    //     colorCodes: {
    //         'white': '#fff'
    //     }
    // },
    // 'puzzle': {
    //     availableColors: ['white'],
    //     colorCodes: {
    //         'white': '#fff'
    //     }
    // },
}

export function ItemTypeSelect({ selected, setSelected, color }: {
    selected?: ItemTypeOption
    setSelected: (value?: ItemTypeOption) => void
    color: ColorOption | undefined
}) {
    const getProductPrice = useSelector((state: RootState) => productPriceSelector(state.product))
    const itemTypeParams: OptionParams<ItemTypeOption> = {
        title: 'Termék',
        names: names,
        descriptions: {
            'tshirt': getProductPrice(ItemType.TSHIRT) ? `${getProductPrice(ItemType.TSHIRT)} Ft` : '',
            'mug': getProductPrice(ItemType.MUG) ? `${getProductPrice(ItemType.MUG)} Ft` : '',
            'female_tshirt': getProductPrice(ItemType.FEMALE_TSHIRT) ? `${getProductPrice(ItemType.FEMALE_TSHIRT)} Ft` : '',
            'female_v_neck_tshirt': getProductPrice(ItemType.FEMALE_V_NECK_TSHIRT) ? `${getProductPrice(ItemType.FEMALE_V_NECK_TSHIRT)} Ft` : '',
            'hoodie': getProductPrice(ItemType.HOODIE) ? `${getProductPrice(ItemType.HOODIE)} Ft` : '',
            'sweater': getProductPrice(ItemType.SWEATER) ? `${getProductPrice(ItemType.SWEATER)} Ft` : '',
            'canvas_bag': getProductPrice(ItemType.CANVAS_BAG) ? `${getProductPrice(ItemType.CANVAS_BAG)} Ft` : '',
            // 'mouse_pad': getProductPrice(ItemType.MOUSE_PAD) ? `${getProductPrice(ItemType.MOUSE_PAD)} Ft` : '',
            // 'puzzle': getProductPrice(ItemType.PUZZLE) ? `${getProductPrice(ItemType.PUZZLE)} Ft` : '',
        },
        images: images,
    }

    const accordionTitle = selected ? itemTypeParams.names[selected] : 'Termék kiválasztása'

    return (
        <CustomSelect
            selected={selected}
            setSelected={setSelected}
            params={itemTypeParams}
            horizontal
            accordionTitle={accordionTitle}
            itemColor={color}
        />
    )
}
