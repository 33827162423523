interface MenuItem {
    title: string;
    path: string;
    icon? : any;
}

export const MenuItems: MenuItem[] = [
    {
        title: 'Tervezés',
        path: '/tervezes',
        icon: null,
    },
    {
        title: 'Termékek',
        path: '/termekek',
        icon: null,
    },
    {
        title: 'Galéria',
        path: '/galeria',
        icon: null,
    },
    {
        title: 'Kapcsolat',
        path: '/kapcsolat',
        icon: null,
    },
]