import React, {useState} from 'react';
import {Box, Dialog, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ItemType, ItemTypeOption} from "../../types";

import femaleTShirtSizeChart from '../../assets/products/sizes/female_tshirt_size_chart.png';
import femaleVNeckTShirtSizeChart from '../../assets/products/sizes/female_vneck_tshirt_size_chart.png';
import hoodieSizeChart from '../../assets/products/sizes/hoodie_size_chart.png';
import sweaterSizeChart from '../../assets/products/sizes/sweater_size_chart.png';
import tShirtSizeChart from '../../assets/products/sizes/tshirt_size_chart.png';

const SizeTable = ({open, selectedItemType, onClose} : {open: boolean, selectedItemType: ItemTypeOption | undefined, onClose: () => void}) => {

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>
                Mérettáblázat
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: 5, justifyContent: 'space-between', gap: 5 }}>
                <img src='./tee_size_chart.png' alt='' height='250' />
                {selectedItemType === ItemType.FEMALE_TSHIRT && (<img src={femaleTShirtSizeChart} alt='' height='250' />)}
                {selectedItemType === ItemType.FEMALE_V_NECK_TSHIRT && (<img src={femaleVNeckTShirtSizeChart} alt='' height='250' />)}
                {selectedItemType === ItemType.HOODIE && (<img src={hoodieSizeChart} alt='' height='250' />)}
                {selectedItemType === ItemType.SWEATER && (<img src={sweaterSizeChart} alt='' height='250' />)}
                {selectedItemType === ItemType.TSHIRT && (<img src={tShirtSizeChart} alt='' height='250' />)}

            </Box>
        </Dialog>
    );
};

export default SizeTable;