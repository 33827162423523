import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    OrderRequest,
    RequestOrderItem,
    sendOrderRequest,
} from "../../redux/action/order";
import isEmpty from "../../utils/isEmpty";
import {useDispatch, useSelector} from "react-redux";
import {OrderItem} from "../../redux/orderSlice";
import {RootState} from "../../redux/store";
import {
    boxDeliveryPriceSelector,
    deliveryPriceSelector,
    productIdSelector
} from '../../redux/productSelectors';
import { hasMultipleColor, hasMultipleSize } from '../../types';

import smallBarionCard from '../../assets/barion/barion-card-strip-intl__small.png'

const DeliveryDetails = ({increaseOrderStep}: { increaseOrderStep: () => void }) => {

    const dispatch = useDispatch();

    const orderItems: OrderItem[] = useSelector<RootState, OrderItem[]>(state => state.order.items);

    const [deliveryType, setDeliveryType] = useState("");
    const [orderRequest, setOrderRequest] = useState<OrderRequest>({} as OrderRequest);
    const [errors, setErrors] = useState<string[]>([]);
    const [discountPrice, setDiscountPrice] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [sameAddresses, setSameAddresses] = useState(false)

    const deliveryPrice = useSelector((state: RootState) => deliveryPriceSelector(state.product))
    const boxDeliveryPrice = useSelector((state: RootState) => boxDeliveryPriceSelector(state.product))
    const productId = useSelector((state: RootState) => productIdSelector(state.product))

    const discountPercentage: number = useSelector<RootState, number>(state => state.order.discountPercentage);
    const couponCode: string = useSelector<RootState, string>(state => state.order.couponCode);


    useEffect(() => {

        let tmpTotalPrice = orderItems.reduce((prev, orderItem) => {
            return prev + (orderItem.itemPrice * orderItem.quantity + orderItem.designPrice)
        }, 0)

        let tmpDiscountPrice = tmpTotalPrice * ((100 - discountPercentage) / 100);

        if (deliveryType === "houseDelivery") {
            // TODO: Lekezelni mi van, ha null
            tmpTotalPrice += deliveryPrice!
            tmpDiscountPrice += deliveryPrice!
        }

        if (deliveryType === "boxDelivery") {
            tmpTotalPrice += boxDeliveryPrice!
            tmpDiscountPrice += boxDeliveryPrice!
        }

        setTotalPrice(tmpTotalPrice);
        setDiscountPrice(tmpDiscountPrice);

        const mappedItems: RequestOrderItem[] = orderItems.map((item: OrderItem) => {
            let variant
            if (hasMultipleColor(item.itemType) && hasMultipleSize(item.itemType)) {
                variant = {
                    color: item.tShirtColor!,
                    size: item.tShirtSize!
                }
            } else if (hasMultipleColor(item.itemType)) {
                variant = {
                    color: item.tShirtColor!,
                }
            }

            let predefinedProductId = null;

            if (item.predefinedProductId !== undefined) {
                predefinedProductId = item.predefinedProductId
            }

            return ({
                productId: productId(item.itemType),
                predefinedProductId: predefinedProductId,
                imageId: item.imageId,
                position: item.position,
                quantity: item.quantity,
                productType: item.itemType,
                variant: variant,
                itemPreviewImage: item.itemPreviewImage
            })
        })

        setOrderRequest(prevOrderRequest => ({
            ...prevOrderRequest,
            items: mappedItems,
            couponCode: couponCode
        }));

    }, [deliveryPrice, orderItems, deliveryType, productId, discountPercentage, boxDeliveryPrice, couponCode]);


    function receiveMessage(event: any) {
        if (event.origin === undefined || event.origin !== 'https://cdn.foxpost.hu') {
            return
        }

        if (event.data !== undefined) {
            let address = JSON.parse(event.data);
            handleInputChange("shippingBoxAddress")({
                target: {value: address}
            });
        }
    }

    window.addEventListener('message', receiveMessage, false);

    const handleInputChange = (inputType: string) => (event: any) => {
        const value: string = event.target.value
        switch (inputType) {
            case 'setBoxDelivery':
                setDeliveryType("boxDelivery")
                break;
            case 'setHouseDelivery':
                setDeliveryType("houseDelivery")
                break;
        }

        setOrderRequest(prevState => {
            switch (inputType) {
                case 'firstName':
                    return {...prevState, firstName: value};
                case 'lastName':
                    return {...prevState, lastName: value};
                case 'emailAddress':
                    return {...prevState, emailAddress: value};
                case 'phoneNumber':
                    return {...prevState, phoneNumber: value};
                case 'shippingZipCode':
                    return {...prevState, shippingAddress: {...prevState.shippingAddress, zipCode: parseInt(value)}}
                case 'shippingCity':
                    return {...prevState, shippingAddress: {...prevState.shippingAddress, city: value}};
                case 'shippingStreet':
                    return {...prevState, shippingAddress: {...prevState.shippingAddress, street: value}};
                case 'shippingOtherDetails':
                    return {...prevState, shippingAddress: {...prevState.shippingAddress, otherAddressDetails: value}};
                case 'shippingBoxAddress':
                    return {...prevState, shippingBoxAddress: event.target.value};
                case 'billingZipCode':
                    return {...prevState, billingAddress: {...prevState.billingAddress, zipCode: parseInt(value)}};
                case 'billingCity':
                    return {...prevState, billingAddress: {...prevState.billingAddress, city: value}};
                case 'billingStreet':
                    return {...prevState, billingAddress: {...prevState.billingAddress, street: value}};
                case 'billingOtherDetails':
                    return {...prevState, billingAddress: {...prevState.billingAddress, otherAddressDetails: value}};
                case 'setShippingAddress':
                    let state: OrderRequest
                    if (!sameAddresses) {
                        state = {...prevState, shippingAddress: {...prevState.billingAddress }}
                    } else {
                        state = {...prevState, shippingAddress: undefined}
                    }
                    setSameAddresses(current => !current)
                    return state
                default:
                    return prevState;
            }
        });

    }

    const orderButtonHandler = () => {

        let _errors: string[] = []

        if (isEmpty(orderRequest.firstName)) {
            _errors = [..._errors, 'firstName'];
        }

        if (isEmpty(orderRequest.lastName)) {
            _errors = [..._errors, 'lastName'];
        }

        if (isEmpty(orderRequest.emailAddress)) {
            _errors = [..._errors, 'emailAddress'];
        }

        if (isEmpty(orderRequest.phoneNumber)) {
            _errors = [..._errors, 'phoneNumber'];
        }

        if (isEmpty(orderRequest.billingAddress?.zipCode)) {
            _errors = [..._errors, 'billingZipCode'];
        }

        if (isEmpty(orderRequest.billingAddress?.city)) {
            _errors = [..._errors, 'billingCity'];
        }

        if (isEmpty(orderRequest.billingAddress?.street)) {
            _errors = [..._errors, 'billingStreet'];
        }

        if (isEmpty(deliveryType)) {
            _errors = [..._errors, 'deliveryType'];
        }

        if (deliveryType === "houseDelivery") {
            if (isEmpty(orderRequest.shippingAddress?.zipCode)) {
                _errors = [..._errors, 'shippingZipCode'];
            }

            if (isEmpty(orderRequest.shippingAddress?.city)) {
                _errors = [..._errors, 'shippingCity'];
            }

            if (isEmpty(orderRequest.shippingAddress?.street)) {
                _errors = [..._errors, 'shippingStreet'];
            }

        }

        if (deliveryType === "boxDelivery") {
            if (isEmpty(orderRequest.shippingBoxAddress)) {
                _errors = [..._errors, 'shippingBoxAddress'];
            }
        }

        setErrors(_errors)

        if (_errors.length === 0) {
            setErrors(_errors)
            dispatch(sendOrderRequest(orderRequest));
            increaseOrderStep();
        }

    }

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{marginBottom: 4}}>
                <Grid item xs={12} sm={8} md={8}>
                    <Grid container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography
                            variant='h5'
                            sx={{fontWeight: 'bold', marginBottom: 2}}
                        >
                            Számlázási adatok megadása
                        </Typography>


                        <Grid container spacing={2.5} sx={{width: '95%'}}>
                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <TextField
                                    required
                                    fullWidth
                                    id="second-name-required"
                                    label="Vezetéknév"
                                    placeholder="Vezetéknév"
                                    value={orderRequest.lastName || ''}
                                    onChange={handleInputChange('lastName')}
                                    error={errors.includes('lastName')}
                                    helperText={errors.includes('lastName') ? 'A mező megadása kötelező' : ''}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <TextField
                                    required
                                    fullWidth
                                    id="first-name-required"
                                    label="Keresztnév"
                                    placeholder="Keresztnév"
                                    value={orderRequest.firstName || ''}
                                    onChange={handleInputChange('firstName')}
                                    error={errors.includes('firstName')}
                                    helperText={errors.includes('firstName') ? 'A mező megadása kötelező' : ''}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email-address-required"
                                    label="Email cím"
                                    placeholder="Email cím"
                                    value={orderRequest.emailAddress || ''}
                                    onChange={handleInputChange('emailAddress')}
                                    error={errors.includes('emailAddress')}
                                    helperText={errors.includes('emailAddress') ? 'A mező megadása kötelező' : ''}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone-number-required"
                                    label="Telefonszám"
                                    placeholder="Telefonszám"
                                    value={orderRequest.phoneNumber || ''}
                                    onChange={handleInputChange('phoneNumber')}
                                    error={errors.includes('phoneNumber')}
                                    helperText={errors.includes('phoneNumber') ? 'A mező megadása kötelező' : ''}
                                />
                            </Grid>

                            <>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="zip-code-required"
                                        label="Irányítószám"
                                        placeholder="Irányítószám"
                                        type="number"
                                        value={orderRequest.billingAddress?.zipCode || ''}
                                        onChange={handleInputChange('billingZipCode')}
                                        error={errors.includes('billingZipCode')}
                                        helperText={errors.includes('billingZipCode') ? 'A mező megadása kötelező' : ''}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="city-required"
                                        label="Város"
                                        placeholder="Város"
                                        value={orderRequest.billingAddress?.city || ''}
                                        onChange={handleInputChange('billingCity')}
                                        error={errors.includes('billingCity')}
                                        helperText={errors.includes('billingCity') ? 'A mező megadása kötelező' : ''}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="street-required"
                                        label="Utca, házszám"
                                        placeholder="Utca, házszám"
                                        value={orderRequest.billingAddress?.street || ''}
                                        onChange={handleInputChange('billingStreet')}
                                        error={errors.includes('billingStreet')}
                                        helperText={errors.includes('billingStreet') ? 'A mező megadása kötelező' : ''}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <TextField
                                        fullWidth
                                        id="other"
                                        label="Egyéb (emelet...)"
                                        placeholder="Egyéb (emelet...)"
                                        value={orderRequest.billingAddress?.otherAddressDetails || ''}
                                        onChange={handleInputChange('billingOtherDetails')}
                                    />
                                </Grid>
                            </>

                            <Grid item xs={12}>
                                <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                    Szállítás
                                </Typography>

                                <Stack direction='column'>
                                    <FormLabel component="legend" sx={{mb: 1}}>A gyártási idő megnövekedése miatt, nem tudjuk garantálni a karácsonyig történő kiszállítást.</FormLabel>
                                    <FormControl
                                        required
                                        error={errors.includes('deliveryType')}
                                        component="fieldset"
                                        variant="standard"
                                    >
                                        <FormLabel component="legend">Válasszon szállítási módot</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleInputChange('setBoxDelivery')}
                                                        checked={deliveryType === "boxDelivery"}
                                                    />
                                                }
                                                label={`Csomagpont átvétel - Bankkártyás fizetés a weboldalon (+ ${boxDeliveryPrice} Ft) `}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleInputChange('setHouseDelivery')}
                                                        checked={deliveryType === "houseDelivery"}
                                                    />
                                                }
                                                label={`Házhoz szállítás - Bankkártyás fizetés a weboldalon (+ ${deliveryPrice} Ft) `}
                                            />
                                            {deliveryType === 'houseDelivery' && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleInputChange('setShippingAddress')}
                                                        checked={sameAddresses}
                                                    />
                                                }
                                                label={'Számlázási címmel azonos szállítási cím'}
                                            />}
                                        </FormGroup>
                                    </FormControl>
                                </Stack>

                                {/*<FormLabel*/}
                                {/*    sx={{fontSize: 13}}*/}
                                {/*>*/}
                                {/*    A megrendelés befejezésével megerősíted, hogy megismerted és elfogadod az <a href="https://admin.fogyasztobarat.hu/my-pdf.php?params=aszf/G6XPCOOC/aszf.pdf" target="_blank" style={{textDecoration: 'underline'}} rel="noreferrer" >Általános Szerződési Feltételek</a>.*/}
                                {/*</FormLabel>*/}
                            </Grid>

                            {deliveryType === "boxDelivery" && isEmpty(orderRequest.shippingBoxAddress) && (
                                <FormControl
                                    required
                                    error={errors.includes('shippingBoxAddress')}
                                    sx={{width: '100%'}}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{mt: 1, mb: 1}}
                                    >
                                        Válasszon csomagpontot
                                    </FormLabel>
                                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title*/}
                                    <iframe
                                        id="iframe"
                                        frameBorder="0" loading="lazy" src="https://cdn.foxpost.hu/apt-finder/v1/app/"
                                        width="100%" height="750px"
                                    >

                                    </iframe>
                                </FormControl>
                            )}

                            {deliveryType === "boxDelivery" && !isEmpty(orderRequest.shippingBoxAddress) && (
                                <Stack direction='row' sx={{mt: 1, ml: 2}} alignItems='center'>
                                    <Typography
                                        sx={{fontWeight: 'bold', mr: 1}}
                                    >
                                        Kiválasztott csomagpont:
                                    </Typography>
                                    <Typography
                                    >
                                        {orderRequest.shippingBoxAddress?.address}
                                    </Typography>
                                    <Button
                                        sx={{marginLeft: 1}}
                                        onClick={() => handleInputChange("shippingBoxAddress")({
                                            target: {value: ""}
                                        })}
                                    >
                                        Megváltoztat
                                    </Button>
                                </Stack>
                            )}


                            {deliveryType === "houseDelivery" && !sameAddresses && (
                                <>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="zip-code-required"
                                            label="Irányítószám"
                                            placeholder="Irányítószám"
                                            type="number"
                                            value={orderRequest.shippingAddress?.zipCode || ''}
                                            onChange={handleInputChange('shippingZipCode')}
                                            error={errors.includes('shippingZipCode')}
                                            helperText={errors.includes('shippingZipCode') ? 'A mező megadása kötelező' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="city-required"
                                            label="Város"
                                            placeholder="Város"
                                            value={orderRequest.shippingAddress?.city || ''}
                                            onChange={handleInputChange('shippingCity')}
                                            error={errors.includes('shippingCity')}
                                            helperText={errors.includes('shippingCity') ? 'A mező megadása kötelező' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="street-required"
                                            label="Utca, házszám"
                                            placeholder="Utca, házszám"
                                            value={orderRequest.shippingAddress?.street || ''}
                                            onChange={handleInputChange('shippingStreet')}
                                            error={errors.includes('shippingStreet')}
                                            helperText={errors.includes('shippingStreet') ? 'A mező megadása kötelező' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            fullWidth
                                            id="other"
                                            label="Egyéb (emelet...)"
                                            placeholder="Egyéb (emelet...)"
                                            value={orderRequest.shippingAddress?.otherAddressDetails || ''}
                                            onChange={handleInputChange('shippingOtherDetails')}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>

                    <Stack direction='column' sx={{pr: 2, pl: 2}}>

                        <FormLabel
                            sx={{fontSize: 13, mt: 2, mb: 2}}
                        >
                            A megrendelés befejezésével megerősíted, hogy megismerted és elfogadod az <a href="https://admin.fogyasztobarat.hu/my-pdf.php?params=aszf/G6XPCOOC/aszf.pdf" target="_blank" style={{textDecoration: 'underline'}} rel="noreferrer" >Általános Szerződési Feltételek</a>.
                        </FormLabel>

                        <Typography
                            style={{fontWeight: 'bold', fontSize: 18}}
                        >
                            Bankkártyás fizetés Barionnal
                        </Typography>

                        <img src={smallBarionCard} style={{marginRight: 8, maxWidth: '100%', marginTop: 5}} alt=''/>

                        <FormLabel
                            sx={{fontSize: 15, mt: 1, ml: 2}}
                        >
                            Fizess Barionon keresztül. Fizess bankkártyával, nem kötelező regisztrálni! Egyszerű, gyors, biztonságos.
                        </FormLabel>

                    </Stack>

                    <Stack direction='column' justifyContent='center' alignItems='center'
                        sx={{mt: 3, mr: 2}}
                    >

                        <Stack
                            direction='row'
                            justifyContent='space-around'
                            alignItems='center'
                            sx={{width: '100%', mt:1, mb: 1}}
                        >

                            <Typography
                                variant='subtitle2'
                                sx={{fontWeight: 'bold', fontSize: 16}}
                            >
                                Végösszeg:
                            </Typography>

                            {discountPercentage === 0 ? (
                                <Typography
                                    variant='subtitle2'
                                    sx={{fontWeight: 'bold', fontSize: 16}}
                                >
                                    {totalPrice} Ft
                                </Typography>
                            ) : (
                                <Stack
                                    direction='column'
                                    alignItems='center'
                                >
                                    <Typography
                                        variant='subtitle2'
                                        sx={{fontWeight: 'bold', fontSize: 15, position: "relative",
                                            "&:after": {content: '""', width: "100%", position: "absolute", right: 0, top: "50%", borderBottom: "1px solid", transform: "skewY(-10deg)"}
                                        }}
                                    >
                                        {totalPrice} Ft
                                    </Typography>

                                    <Typography
                                        variant='subtitle2'
                                        sx={{fontWeight: 'bold', fontSize: 16, color: "#E43A36"}}
                                    >
                                        {Math.floor(discountPrice)} Ft
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>


                        <Button
                            variant='contained'
                            onClick={orderButtonHandler}
                            fullWidth
                        >
                            Fizetés Barionnal
                        </Button>
                    </Stack>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default DeliveryDetails;
