import React, {useEffect, useMemo, useState} from 'react';
import HomePage from "./pages/HomePage";
import theme from "./theme/theme";
import ThemeContext from './theme/ThemeContext'
import { ThemeProvider } from "@mui/material/styles";
import Layout from "./components/Layout";
import {Route, Routes, useLocation} from "react-router-dom";
import PlaningPage from "./pages/PlanningPage";
import ShoppingCart from "./pages/ShoppingCart";
import ComingSoonPage from './pages/ComingSoon';
import NotFoundPage from './pages/404NotFoundPage';
import {useDispatch} from "react-redux";
import {orderActions} from "./redux/orderSlice";
import OrderStatusPage from "./pages/OrderStatusPage";
import LoginPage from "./pages/LoginPage";
import {usePageTracking} from "./hooks/usePageTracking";
import ReactGA from "react-ga4";
import ContactUsPage from "./pages/ContactUsPage";
import ProductsPage from "./pages/ProductsPage";
import ProductPage from "./pages/ProductPage";
import axios from "axios";
import Cookie from './components/Cookie';

function App() {
    usePageTracking();
    let token: string = process.env.REACT_APP_TRACKING_CODE ? process.env.REACT_APP_TRACKING_CODE : ""
    ReactGA.initialize(token);

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

    const dispatch = useDispatch();
    const { pathname, hash, key } = useLocation();

    // localStorage.getItem(THEME) === null ? false : localStorage.getItem(THEME) === "true"
    const [isLight, setIsLight] = useState<boolean>(true);
    const muiTheme = useMemo(() => theme(isLight), [isLight]);

    let themeContext = {
        toggleThemeContext: () => {
            let isLightFlag:boolean = isLight;
            // localStorage.setItem(THEME, String(!isLightFlag));
            setIsLight(!isLightFlag);
        },
        isLight: isLight
    }

    useEffect(() => {
        dispatch(orderActions.initializeCart());
    }, [])

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    return (
        <React.Fragment>
            <ThemeContext.Provider value={themeContext}>
                <ThemeProvider theme={muiTheme}>
                    <Layout>
                        <Routes>
                            <Route path='/' element={<HomePage />}/>
                            <Route path='/tervezes' element={<PlaningPage />}/>
                            <Route path='/kosar'  element={<ShoppingCart />}/>
                            <Route path='/termekek' element={<ProductsPage />} />
                            <Route path='/termek' element={<ProductPage />} />
                            <Route path='/galeria' element={<ComingSoonPage />} />
                            <Route path='/order' element={<OrderStatusPage />} />
                            {/*<Route path='/bejelentkezes' element={<LoginPage />} />*/}
                            <Route path='/kapcsolat' element={<ContactUsPage />} />
                            <Route path='/oauth2/redirect' element={<LoginPage />} />
                            <Route path='/*'  element={<NotFoundPage />}/>
                        </Routes>
                        <Cookie />
                    </Layout>
                </ThemeProvider>
            </ThemeContext.Provider>
        </React.Fragment>
    );
}

export default App;
