import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../redux/store"
import {getSummerizers} from "../../redux/planSelectors"
import {ShoppingCart} from "@mui/icons-material"
import {OrderItem} from "../../redux/orderSlice"
import {addOrderItem} from "../../redux/action/order"
import {designPriceSelector, getPrice, productPriceSelector} from "../../redux/productSelectors"

// @ts-ignore
import domToImage from 'dom-to-image-more'
import { ItemType, hasMultipleColor, hasMultipleSize } from "../../types";
import {useCanvas} from "./CanvasContext";

export function PlanningFooter({setOrderAlertOpen}: { setOrderAlertOpen: (value: boolean) => void }) {

    const { canvas } = useCanvas();

    const theme = useTheme()
    const dispatch = useDispatch()

    const itemType = useSelector((state: RootState) => state.plan.itemType)
    const designType = useSelector((state: RootState) => state.plan.designType)
    const selectedPlan = useSelector((state: RootState) => state.plan.selectedPlan)
    const color = useSelector((state: RootState) => state.plan.color)
    const size = useSelector((state: RootState) => state.plan.size)
    const generation = useSelector((state: RootState) => state.plan.generation)
    const premiumGeneration = useSelector((state: RootState) => state.plan.premiumGeneration)

    const getProductPrice = useSelector((state: RootState) => productPriceSelector(state.product))
    const getDesignPrice = useSelector((state: RootState) => designPriceSelector(state.product))

    const [summarizer1, summarizer2] = useSelector((state: RootState) => getSummerizers(state.plan))
    const price = useSelector((state: RootState) => getPrice(state))
    const priceString = () => {
        if (price === 0) return '-'
        else return `${price} Ft`
    }

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const addToCartHandler = async () => {

        const imageRect: HTMLElement | null = document.getElementById("imageRect");
        const deleteIcon: any = document.getElementById('remove-icon');
        const previewNode = document.getElementById('edited-product');

        let itemPreviewImage: string = "";

        //Remove dashed border
        if (imageRect) {
            imageRect.style["border"] = "none";
        }

        //Do not have remove icon
        previewNode?.removeChild(deleteIcon)

        //Do not have active objects
        canvas?.getActiveObjects().forEach((o) => {
            canvas.discardActiveObject().renderAll();
        })

        try {
            const dataUrl: string = await domToImage.toPng(previewNode);
            itemPreviewImage = dataUrl;

            //Add the removed remove icon
            previewNode?.appendChild(deleteIcon)

            //Add the removed border
            if (imageRect) {
                imageRect.style["border"] = !color || color === 'white' || color === 'grey' ? '2px dashed #00000044' : '2px dashed #ffffff44';
            }

        } catch (error) {
            console.error('oops, something went wrong!', error);
        }

        const productImage = document.getElementsByClassName('product')[0]

        let selectedPlanNumber: number = selectedPlan ? +selectedPlan : 0;
        let imageId: string = "";
        let imageURL: string = "";

        if (selectedPlanNumber <= 2) {
            imageId =  generation.images[selectedPlanNumber - 1]?.id
            imageURL =  generation.images[selectedPlanNumber - 1]?.link
        }

        if (selectedPlanNumber >= 3) {
            imageId =  premiumGeneration.images[selectedPlanNumber - 3]?.id
            imageURL =  premiumGeneration.images[selectedPlanNumber - 3]?.link
        }

        // const design = document.getElementById('design') as HTMLElement
        const orderItem: OrderItem = {
            //TODO: biztonsagosra csinalni
            itemPrice: getProductPrice(itemType)!,
            designPrice: getDesignPrice(itemType)!,
            quantity: 1,
            imageId: imageId,
            imageURL: imageURL,
            itemType: itemType as ItemType,
            tShirtSize: size ?? null,
            tShirtColor: color,
            productName: summarizer2 === '' ? `${summarizer1}` : `${summarizer1} (${summarizer2})`,
            itemPreviewImage: itemPreviewImage,
            position: {
                image: {
                    width: productImage.getBoundingClientRect().width,
                    height: productImage.getBoundingClientRect().height,
                },
                itemDetails: {

                    //TODO megbeszél
                    // width: design.getBoundingClientRect().width,
                    // height: design.getBoundingClientRect().height,
                    // top: design.getBoundingClientRect().top - productImage.getBoundingClientRect().top,
                    // left: design.getBoundingClientRect().left - productImage.getBoundingClientRect().left

                    width: 10,
                    height: 10,
                    top: 10,
                    left: 10
                }
            },
        }

        dispatch(addOrderItem(orderItem));
    }

    return (
        <Grid container width='100%' height='100%' sx={{
            paddingLeft: 10,
            paddingRight: 10,
            [theme.breakpoints.down('sm')]: {
                paddingRight: 5,
                paddingLeft: 5
            }
        }} >
            <Grid item xs={false} xl={1}/>
            <Grid item xs={6} xl={5}
                  sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'start'
                }}>
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <Typography sx={{fontSize: 18, fontWeight: '600', [theme.breakpoints.down('sm')]: {fontSize: 16}}}>Összesítés:&nbsp;</Typography>
                    {isSmallScreen && <Typography sx={{fontSize: 16, fontWeight: '600', whiteSpace: 'nowrap'}}>{priceString()}</Typography>}
                </Box>
                {!isSmallScreen && <><Typography sx={{fontSize: 14}}>
                    {summarizer1}
                </Typography>
                <Typography sx={{fontSize: 14}}>
                    {summarizer2}
                </Typography></>}
            </Grid>
            <Grid item xs={6} xl={5} sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                height: '100%',
                maxWidth: '500px !important',
                [theme.breakpoints.down('md')]: {
                    marginLeft: 0,
                    marginRight: 0,
                }

            }}>
                {!isSmallScreen && <Typography sx={{fontSize: 20, marginRight: 2, fontWeight: '500', [theme.breakpoints.down('sm')]: {fontSize: 16}}}>{priceString()}</Typography>}
                <Button
                    id="add-to-cart-footer-button"
                    variant='contained'
                    sx={{
                        backgroundColor: 'white',
                        color: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: '#ffffff88'
                        },
                        '&:disabled': {
                            backgroundColor: '#ccc'
                        }
                    }}
                        disabled={!itemType || !designType || !selectedPlan || (hasMultipleColor(itemType) && !color) || (hasMultipleSize(itemType) && !size)}
                        onClick={() => {
                            setOrderAlertOpen(true)
                            addToCartHandler()
                        }}>
                    <ShoppingCart/>
                    <Typography sx={{
                        color: 'inherit',
                        [theme.breakpoints.down('sm')]: {fontSize: 14}
                    }} color={theme.palette.primary.main} fontSize={18}>Kosárba</Typography>
                </Button>
            </Grid>
            <Grid item xs={false} xl={1}/>
        </Grid>
    )
}
