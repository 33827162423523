import React from 'react';
import {orderActions, OrderItem} from "../../redux/orderSlice";
import {useDispatch} from "react-redux";
import {Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTheme} from "@mui/material/styles";
import ImageModal from "../ImageModal";

const OrderItemRow = (props: { item: OrderItem, index: number, textFontSize: number, smallDesign: boolean}) => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const handleInputChange = (inputType: string, itemIndex: number) => (event: any) => {
        switch (inputType) {
            case 'quantity':
                dispatch(orderActions.editQuantity({newQuantity: parseInt(event.target.value), itemIndex: itemIndex}));
                break;
            case 'remove':
                dispatch(orderActions.removeItem(itemIndex));
                break;
        }
    }

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: "100%"}}>
            <Stack direction='column' alignItems='center' sx={{width: '100%'}}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{width: '100%'}}>

                    {/*<img width="120px" height="120px" src={props.item.itemPreviewImage}/>*/}

                    <ImageModal imageSrc={props.item.itemPreviewImage} />

                    <Typography
                        variant="subtitle2"
                        sx={{fontSize: props.textFontSize, width: 300,
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 12,
                                ml: 1
                            }
                        }}
                    >
                        {props.item.productName}
                    </Typography>
                    <TextField
                        id="quantity-number"
                        label="Darab"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{".MuiInputBase-input": {fontSize: props.textFontSize}, width: 65, mr: 2}}
                        value={props.item.quantity}
                        onChange={handleInputChange('quantity', props.index)}
                    />
                    <Typography
                        sx={{fontSize: props.textFontSize, fontWeight: 'bold',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 12,
                            }
                        }}
                    >
                        {props.item.itemPrice} Ft
                    </Typography>
                    <IconButton
                        sx={{mr: 2}}
                        onClick={handleInputChange('remove', props.index)}
                    >
                        <DeleteIcon sx={{color: "black"}}/>
                    </IconButton>
                </Stack>

                {props.item.designPrice !== 0 && (
                    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{width: '100%'}}>
                        <img width="120px" height="10px" src={props.item.imageURL} style={{visibility: 'hidden'}}/>
                        <Typography
                            variant="subtitle2"
                            style={{fontSize: props.textFontSize, width: 300}}
                        >
                            Egyedi minta
                        </Typography>
                        <TextField
                            id="quantity-number"
                            label="Darab"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{".MuiInputBase-input": {fontSize: props.textFontSize}, width: 65, mr: 2, visibility: 'hidden'}}
                            value={props.item.quantity}
                            onChange={handleInputChange('quantity', props.index)}
                        />
                        <Typography
                            sx={{fontSize: props.textFontSize, fontWeight: 'bold'}}
                        >
                            {props.item.designPrice} Ft
                        </Typography>
                        <IconButton
                            sx={{mr: 2, visibility: 'hidden'}}
                        >
                            <DeleteIcon sx={{color: "black"}}/>
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default OrderItemRow;
