import { Box, Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Lottie from 'react-lottie-player'
import NotFoundAnimation from '../assets/404.json'

const NotFoundPage = () => {
    const theme = useTheme()
    const navigation = useNavigate()

    return (
        <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 64px)',
            flexDirection: 'column',
            paddingRight: 20,
            paddingLeft: 20
        }}>
            <Lottie
                loop
                animationData={NotFoundAnimation}
                play
                style={{ width: 260 }}
            />
            <Typography fontSize={30} color={theme.palette.primary.main} fontWeight={600}>Hoppá! A keresett oldal nem található.</Typography>
            <Button variant='contained' onClick={() => navigation('/')} sx={{
                marginTop: 3,
                marginBottom: 5
            }}>
                <Typography>
                    Vissza a főoldalra
                </Typography>
            </Button>
        </Box>
    )
}

export default NotFoundPage
