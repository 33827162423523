import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./swiper-sytle.css";

// import required modules
import { Pagination } from "swiper";

import detectiveDuck from '../../assets/products/preview/detective-duck.png'
import capibara from '../../assets/products/preview/capibara.png'
import dino from '../../assets/products/preview/dino.png'
import giraffe from '../../assets/products/preview/giraffe.png'

const images = [
    {
        label: '',
        imgPath: detectiveDuck,
    },
    {
        label: '',
        imgPath: dino,
    },
    {
        label: '',
        imgPath: capibara,
    },
    {
        label: '',
        imgPath: giraffe,
    }
];

function ImageStepper() {

    return (
        <>

            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={60}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {images.map((image, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div
                                style={{width: 500}}
                            >
                                <img src={image.imgPath} alt=''
                                     style={{width: 450}}
                                />
                            </div>

                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}

export default ImageStepper;
