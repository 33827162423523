import React, { useState } from 'react';
import CustomAppBar from "./CustomAppBar";
import CustomDrawer from "./CustomDrawer";

const Navigation = () => {

    const [navigationOpen, setNavigationOpen] = useState<boolean>(false);

    const handleOpenNavMenu = () => {
        setNavigationOpen(preState => !preState);
    };

    return (
        <React.Fragment>
            <CustomAppBar navigationOpenClicked={handleOpenNavMenu}/>
            <CustomDrawer open={navigationOpen} onClose={() => setNavigationOpen(false)}/>
        </React.Fragment>
    );
};

export default Navigation;
