import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogTitle, IconButton, Stack, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import {getDiscount} from "../../redux/action/order";
import {RootState} from "../../redux/store";

const CouponCodeDialog = ({open, onClose} : {open: boolean, onClose: () => void}) => {

    const dispatch: any = useDispatch();

    const [couponCheckInProgress, setCouponCheckInProgress] = useState<boolean>(false);
    const [couponCode, setCouponCode] = useState<string>("");
    const [codeValid, setCodeValid] = useState<boolean | null>(null);

    const totalPrice: number = useSelector<RootState, number>(state => state.order.totalPrice);

    useEffect(() => {
        if (open) {
            setCodeValid(null);
        }
    }, [open])

    const inputChange = (e: any) => {
        setCouponCode(e.target.value);
    }

    const codeValidHandler = (valid: boolean) => {
        if (valid) {
            onClose();
        } else {
            setCodeValid(valid)
        }
        setCouponCheckInProgress(false);
    }

    const validateCouponButtonHandler = () => {
        setCouponCheckInProgress(true)
        dispatch(getDiscount(couponCode, totalPrice, codeValidHandler));
    }

    return (
        <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={"xs"}>
            <Stack
                direction='column'
                sx={{ml: 4, mr: 4, mb: 3}}
            >

                <DialogTitle>
                    <Typography
                        textAlign='center'
                        sx={{fontWeight: 'bold'}}
                    >
                        Kupon kód megadása
                    </Typography>
                    <Typography
                        textAlign='center'
                        sx={{fontSize: 15}}
                    >
                        Kedvezmény nem vonatkozik a szállításra.
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => onClose()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Stack
                    sx={{mt: 1}}
                >
                    <TextField
                        id="outlined-basic"
                        label="Kuponkód"
                        variant="outlined"
                        onChange={inputChange}
                        value={couponCode}
                        error={codeValid != null && !codeValid}
                        helperText={codeValid != null && !codeValid && "Helytelen kupon kód."}
                    />

                    <LoadingButton
                        sx={{mt: 2}}
                        variant='outlined'
                        loading={couponCheckInProgress}
                        onClick={validateCouponButtonHandler}
                    >
                        Ellenőrzés
                    </LoadingButton>
                </Stack>

            </Stack>

        </Dialog>
    );
};

export default CouponCodeDialog;