import {useSelector} from "react-redux"
import {RootState} from "../../redux/store"
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react"
import {fabric} from "fabric"
import isEmpty from "../../utils/isEmpty";
import { ItemType } from "../../types";
import {useCanvas} from "./CanvasContext";

const DesignCanvas = forwardRef((props, ref) => {

    const { canvas } = useCanvas();

    const itemType = useSelector((state: RootState) => state.plan.itemType)
    const color = useSelector((state: RootState) => state.plan.color)
    const selectedPlan = useSelector((state: RootState) => state.plan.selectedPlan)
    const generation = useSelector((state: RootState) => state.plan.generation)
    const premiumGeneration = useSelector((state: RootState) => state.plan.premiumGeneration)
    const text = useSelector((state: RootState) => state.plan.text)

    const canvasContainerRef = useRef<HTMLDivElement>(null)
    const [ canvasDimensions, setCanvasDimensions ] = useState({ width: 0, height: 0 })


    //Parent component be able to call remove method
    useImperativeHandle(ref, (): any => ({
        callRemoveObject() {
            removeSelectedObject()
        }
    }));

    // window.addEventListener('click', function(e){
    //     // @ts-ignore
    //     if (document.getElementById('c').contains(e.target)){
    //         // Clicked in box
    //     } else{
    //         console.log("Outside")
    //         canvas?.getActiveObjects().forEach((o) => {
    //             canvas.discardActiveObject().renderAll();
    //         })
    //     }
    // });

    //Check if object is inside square
    const constrainObjectWithinBounds = useCallback((obj: fabric.Object) => {
        // const canvasWidth = canvas?.getWidth() || 0;
        // const canvasHeight = canvas?.getHeight() || 0;
        //
        // // Calculate boundaries based on the rectangle's position and dimensions
        // const leftBoundary = 0;
        // const topBoundary = 0;
        // const rightBoundary = canvasWidth;
        // const bottomBoundary = canvasHeight;
        //
        // const objWidth = obj.width && obj.scaleX ? obj.width * obj.scaleX : null
        // const objHeight = obj.height && obj.scaleY ? obj.height * obj.scaleY : null
        //
        // // Check if the object is going out of bounds
        // if (obj.left && obj.left < leftBoundary) {
        //     obj.left = leftBoundary;
        // }
        // if (obj.top && obj.top < topBoundary) {
        //     obj.top = topBoundary;
        // }
        // if (obj.left && objWidth && obj.left + objWidth > rightBoundary) {
        //     obj.left = rightBoundary - objWidth;
        // }
        // if (obj.top && objHeight && obj.top + objHeight > bottomBoundary) {
        //     obj.top = bottomBoundary - objHeight;
        // }

        return () => {
            canvas?.clear();
        }
    }, [canvas])

    //Remove selected object
    const removeSelectedObject = useCallback(() => {
        if (!canvasContainerRef.current || !canvas) return

        canvas.getActiveObjects().forEach((o) => {
            canvas.remove(o);
        })
        canvas.discardActiveObject().renderAll()
    }, [ canvas ])

    useEffect(() => {
        window.addEventListener('keydown', (event) => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                removeSelectedObject()
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas])

    useEffect(() => {
        const updateSize = () => {
            if (canvasContainerRef.current) {
                setCanvasDimensions({
                    width: canvasContainerRef.current.offsetWidth,
                    height: canvasContainerRef.current.offsetHeight
                })
            }
        }
      
        window.addEventListener('resize', updateSize)
        updateSize()
      
        return () => {
            window.removeEventListener('resize', updateSize)
        }
    }, [selectedPlan, text, itemType]); 

    //Initialize canvas
    useEffect(() => {
        if (!canvasContainerRef.current || !canvas) return
        canvas.setWidth(canvasDimensions.width)
        canvas.setHeight(canvasDimensions.height)
        canvas.renderAll()

        canvas.on('object:modified', (e) => {
            const modifiedObject = e.target;
            if (modifiedObject instanceof fabric.Object) {
                constrainObjectWithinBounds(modifiedObject);
                canvas.renderAll();
            }
        });

        //TODO make it working when object is rotated
        if (canvas.width && canvas.height) {
            //Create vertical line
            const verticalRedLine = new fabric.Line([
                canvas.width / 2, 0,
                canvas.width / 2, canvas.height
            ], {
                strokeDashArray: [5, 5],
                stroke: '#206868',
            });

            verticalRedLine.selectable = false;
            verticalRedLine.evented = false;

            //Create horizontal line
            const horizontalRedLine = new fabric.Line([
                0, canvas.height / 2,
                canvas.width, canvas.height / 2

            ], {
                strokeDashArray: [5, 5],
                stroke: '#206868',
                strokeWidth: 1,
            })

            horizontalRedLine.selectable = false;
            horizontalRedLine.evented = false;

            const snapZone = 10;
            canvas.on('object:moving', (options: fabric.IEvent<MouseEvent>) => {

                if (options?.target?.left && options.target.width && options.target.scaleX && canvas.width && options.target.angle === 0) {

                    let objectMiddleHorizontalPosition = options.target.left + (options.target.width * options.target.scaleX) / 2;

                    //If in the zone move it to the center
                    if (objectMiddleHorizontalPosition > canvas.width / 2 - snapZone && objectMiddleHorizontalPosition < canvas.width / 2 + snapZone) {

                        options.target.set({
                            left: canvasDimensions.width / 2 - (options.target.width * options.target.scaleX) / 2,
                        }).setCoords();

                        canvas.add(verticalRedLine);

                        document.addEventListener("mouseup", () => {
                            canvas.remove(verticalRedLine);
                        });

                    } else {
                        canvas.remove(verticalRedLine);
                    }
                }

                //If in the zone move it to the center
                if (options?.target?.top && options.target.height && options.target.scaleY && canvas.height && options.target.angle === 0) {
                    let objectMiddleVerticalPosition = options.target.top + (options.target.height * options.target.scaleY) / 2;

                    if (objectMiddleVerticalPosition > canvas.height / 2 - snapZone && objectMiddleVerticalPosition < canvas.height / 2 + snapZone) {

                        options.target.set({
                            top: canvas.height / 2 - (options.target.height * options.target.scaleY) / 2,
                        }).setCoords();

                        canvas.add(horizontalRedLine);

                        document.addEventListener("mouseup", () => {
                            canvas.remove(horizontalRedLine);
                        });

                    } else {
                        canvas.remove(horizontalRedLine);
                    }
                }

            });
        }

        return () => {
            canvas.off('object:moving')
            canvas.off('object:modified')
        }

    }, [canvas, canvasDimensions, constrainObjectWithinBounds])

    //Add image to canvas
    useEffect(() => {
        if (!canvas || (!generation.images[0] && !premiumGeneration.images[1])) return
        canvas.remove(...canvas.getObjects().filter(obj => obj.type === 'image'))
        if (!selectedPlan) return
        let imageURL: string
        switch(selectedPlan) {
            case '1': imageURL =  generation.images[0].link
                break
            case '2': imageURL =  generation.images[1].link
                break
            case '3': imageURL =  premiumGeneration.images[0].link
                break
            case '4': imageURL =  premiumGeneration.images[1].link
                break
        }
        fabric.Image.fromURL(imageURL, image => {
            image.scaleToHeight(100);
            image.scaleToWidth(100);
            canvas.centerObject(image)
            canvas.add(image)
            canvas.renderAll()
        })
    }, [canvas, generation.images, premiumGeneration.images, selectedPlan])

    //Add text to canvas
    useEffect(() => {
        if (!canvas || !text) return
        let newText = new fabric.IText(text.value)
        newText.set({
            hasControls: true,  // Enables resizing handles
            hasRotatingPoint: true,  // Enables rotation control
            fontFamily: text.font,
            fill: text.color
        });
        newText.scaleToHeight(20)
        canvas.centerObject(newText)
        canvas.add(newText)
        newText.bringToFront()
    }, [canvas, text])

    return (
        <div id='imageRect' style={{
            position: 'absolute',
            border: !color || color === 'white' || color === 'grey' ? '2px dashed #00000044' : '2px dashed #ffffff44',
            padding: '2px',
            width: itemType === ItemType.HOODIE || itemType === ItemType.CANVAS_BAG ? '35%' : '28%',
            height: itemType === ItemType.HOODIE || itemType === ItemType.CANVAS_BAG ? '35%' : '48%',
            left: '50%',
            top: itemType === ItemType.HOODIE ? '40%' : itemType === ItemType.CANVAS_BAG ? '64%' : '48%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
        }}>
            <div id='my-canvas-container' ref={canvasContainerRef} style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                userSelect: 'none'
            }}>
                <canvas id='c' style={{
                    position: 'absolute',
                    width: 50,
                    height: 50,
                    top: 0,
                    left: 0,
                    userSelect: 'none',
                    cursor: 'default'
                }}></canvas>
            </div>
        </div>
    )
});

export default DesignCanvas;
