import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ColorOption, ItemType, SizeOption} from "../types";


export interface OrderItem {
    predefinedProductId?: string
    itemPrice: number
    quantity: number,
    imageId: string,
    imageURL: string,
    itemType: ItemType,
    designPrice: number,
    tShirtSize: SizeOption | null,
    tShirtColor: ColorOption | null,
    position : Position | null,
    productName: string,
    itemPreviewImage: string
}

export interface Position {
    image: {
        width: number
        height: number
    },
    itemDetails: {
        top: number
        left: number
        width: number
        height: number
    }
}

export enum PaymentStatus {

    //Order states
    GetStatus = 'GetStatus',
    NotSent = 'NotSent',
    OrderPreparing = 'OrderPreparing',
    OrderSuccess = 'OrderSuccess',
    OrderError = 'OrderError',

    Prepared = 'Prepared',
    Started = 'Started',
    InProgress = 'InProgress',
    Waiting = 'Waiting',
    Reserved = 'Reserved',
    Authorized = 'Authorized',
    Canceled = 'Canceled',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    PartiallySucceeded = 'PartiallySucceeded',
    Expired = 'Expired'
}

export enum OrderProgress {
    ORDER_PAYMENT_SUCCESS = "ORDER_PAYMENT_SUCCESS",
    ORDER_PRODUCTION = "ORDER_PRODUCTION",
    ORDER_PREPARATION = "ORDER_PREPARATION",
    ORDER_POSTED = "ORDER_POSTED"
}

export interface OrderState {
    items: OrderItem[],
    paymentStatus: PaymentStatus,
    orderId: string,
    gatewayUrl?: string,
    firstName?: string,
    lastName?: string,
    address?: string,
    personalPickup?: boolean,
    orderProgress?: OrderProgress,
    orderDate?: string,
    totalPrice: number,
    discountPercentage: number,
    orderErrorMessage: string,
    couponCode: string,
}

const initialOrderState: OrderState = {
    items: [] as OrderItem[],
    paymentStatus: PaymentStatus.NotSent,
    orderId: "",
    gatewayUrl: "",
    orderErrorMessage: "",
    totalPrice: 0,
    discountPercentage: 0,
    couponCode: ""
}

const updateTotalPrice = (state: OrderState) => {
    state.totalPrice = state.items.reduce((prev, orderItem) => {
        return prev + (orderItem.itemPrice * orderItem.quantity + orderItem.designPrice);
    }, 0)
}

const orderSlice = createSlice({
    name: 'order',
    initialState: initialOrderState,
    reducers: {
        addItem(state, action: PayloadAction<OrderItem>) {
            const savedCart: string | null = localStorage.getItem('cart');
            if(savedCart != null) {
                state.items = JSON.parse(savedCart);
            }
            state.items.push(action.payload);
            localStorage.setItem("cart", JSON.stringify(state.items))

            updateTotalPrice(state);
        },
        editQuantity(state, action: PayloadAction<{newQuantity: number, itemIndex: number }>) {
            state.items[action.payload.itemIndex].quantity = action.payload.newQuantity;
            localStorage.setItem("cart", JSON.stringify(state.items))

            updateTotalPrice(state);
        },
        removeItem(state, action:PayloadAction<number>) {
            state.items.splice(action.payload, 1);
            localStorage.setItem("cart", JSON.stringify(state.items))

            updateTotalPrice(state);
        },
        initializeCart(state, action: PayloadAction) {
            const savedCart: string | null = localStorage.getItem('cart');
            if(savedCart != null) {
                state.items = JSON.parse(savedCart);
            } else {
                state.items = [];
            }

            updateTotalPrice(state);
        },
        setPaymentStatus(state, action: PayloadAction<{paymentStatus: PaymentStatus, orderId: string, gatewayUrl?: string}>) {
            state.orderErrorMessage = "";
            state.paymentStatus = action.payload.paymentStatus;
            state.orderId = action.payload.orderId;
            state.gatewayUrl = action.payload.gatewayUrl;
        },
        setOrderState(state, action: PayloadAction<{paymentStatus: PaymentStatus, orderId: string, firstName: string, lastName: string, address: string, personalPickup: boolean, orderProgress: OrderProgress, orderDate: string, totalPrice: number}>) {
            state.paymentStatus = action.payload.paymentStatus;
            state.orderId = action.payload.orderId;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.address = action.payload.address;
            state.personalPickup = action.payload.personalPickup;
            state.orderProgress = action.payload.orderProgress;
            state.orderDate = action.payload.orderDate;
            state.totalPrice = action.payload.totalPrice;
        },
        setOrderId(state, action: PayloadAction<string>) {
            state.orderId = action.payload;
        },
        setOrderErrorMessage(state, action: PayloadAction<string>) {
            state.orderErrorMessage = action.payload;
        },
        clearOrder(state, action: PayloadAction ) {
            localStorage.setItem("cart", JSON.stringify([]));
            state.items = [];

            updateTotalPrice(state);
        },
        clearOrderError(state, action: PayloadAction) {
            state.orderErrorMessage = "";
        },
        setDiscount(state, action: PayloadAction<{discountPercentage: number, couponCode: string }>) {
            state.discountPercentage = action.payload.discountPercentage;
            state.couponCode = action.payload.couponCode;
        }
    }
});

export const orderActions = orderSlice.actions;
export default orderSlice;
