import { configureStore } from '@reduxjs/toolkit'
import orderSlice from './orderSlice'
import planSlice from './planSlice'
import productSlice from './productSlice';

const store = configureStore({
    reducer: {
        order: orderSlice.reducer,
        plan: planSlice.reducer,
        product: productSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export default store;
