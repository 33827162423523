import React, {useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {
    Alert,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Input, Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Footer from "../components/Footer";
import {useDispatch} from "react-redux";
import {ContactUsRequest, sendContactEmail} from "../redux/action/contact";
import LoadingButton from '@mui/lab/LoadingButton';
import {Helmet} from "react-helmet";

const ContactUsPage = () => {

    const dispatch = useDispatch();

    const theme = useTheme();

    const [errorContactDetails, setErrorContactDetails] = useState<ContactUsRequest>({username: "", email: "", message: ""});
    const [messageIsSending, setMessageIsSending] = useState<boolean>(false);
    const [contactDetails, setContactDetails] = useState<ContactUsRequest>({username: "", email: "", message: ""});
    const [snackbarProperties, setSnackbarProperties] = useState({open: false, success: false, message: ""});

    const handleInputChange = (inputType: string) => (event: any) => {
        switch (inputType) {
            case "username":
                setContactDetails((prevState) => {
                    return { ...prevState, username: event.target.value };
                });
                break
            case "emailAddress":
                setContactDetails((prevState) => {
                    return { ...prevState, email: event.target.value };
                });
                break
            case "message":
                setContactDetails((prevState) => {
                    return { ...prevState, message: event.target.value };
                });
                break
        }
    }

    const handleSubmit = () => {

        if (contactDetails.username.trim() === "") {
            setErrorContactDetails((prevState) => {
                return { ...prevState, username: "A mező megadása kötelező" };
            });
        } else {
            setErrorContactDetails((prevState) => {
                return { ...prevState, username: "" };
            });
        }

        if (contactDetails.email.trim() === "") {
            setErrorContactDetails((prevState) => {
                return { ...prevState, email: "A mező megadása kötelező" };
            });
        } else {
            setErrorContactDetails((prevState) => {
                return { ...prevState, email: "" };
            });
        }

        if (contactDetails.message.trim() === "") {
            setErrorContactDetails((prevState) => {
                return { ...prevState, message: "A mező megadása kötelező" };
            });
        } else {
            setErrorContactDetails((prevState) => {
                return { ...prevState, message: "" };
            });
        }

        if (errorContactDetails.email === "" && errorContactDetails.username === "" && errorContactDetails.message === "") {
            setMessageIsSending(true);
            setErrorContactDetails({username: "", email: "", message: ""});
            dispatch(sendContactEmail(contactDetails, onSuccessHandler, onErrorHandler))
        }
    }

    const onSuccessHandler = () => {
        setContactDetails({username: "", email: "", message: ""});
        setErrorContactDetails({username: "", email: "", message: ""});
        setMessageIsSending(false);
        setSnackbarProperties((prevState) => {
            return { ...prevState, open: true, success: true, message: "Köszönjük megkeresését, üzenetét sikeresen fogadtuk. Ígyekszünk a lehető leghamarabb válaszolni." };
        });
    }

    const onErrorHandler = () => {
        setMessageIsSending(false);
        setSnackbarProperties((prevState) => {
            return { ...prevState, open: true, success: false, message: "Sajnáljuk, de technikai hiba miatt az üzenet nem sikerült elküldeni. Kérjük, próbálja újra később. Köszönjük!" };
        });
    }

    return (
        <Grid container className='container' sx={{
            color: theme.palette.text.primary,
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 64px)',
            // background: "linear-gradient(135deg, rgba(175,222,220,0.7) 0%, rgba(199,191,189,0.7) 100%)"
        }}>

            <Helmet>
                <title>Kapcsolat - AI Merch - Egyedi póló, bögre és ajándékok az A.I. segítségével</title>
                <meta name="description" content="Bármi kérdés esetén nyugodtan bátran fordulj hozzánk a kapcsolat@aimerch.hu címen." />
            </Helmet>

            <Stack
                direction='column'
                sx={{margin: 5}}
            >

                <Typography
                    variant='h5'
                    sx={{fontWeight: 'bold', marginBottom: 2}}
                >
                    Elérhetőségeink
                </Typography>

                <Typography>
                    Ha bármilyen kérdés felmerül bátran fordulj hozzánk a
                    <a style={{color: '#0070E0', marginLeft: 3, marginRight: 3}} href="mailto: kapcsolat@aimerch.hu">kapcsolat@aimerch.hu</a>
                    címen.
                </Typography>

                <Typography
                    variant='h6'
                    sx={{fontWeight: 'bold', marginBottom: 2, marginTop: 2}}
                >
                    Vedd fel velünk a kapcsolatot írásban az űrlap kitöltésével
                </Typography>

                <FormControl
                    required={true}
                    sx={{marginBottom: 3}}
                    error={!!errorContactDetails.username}
                >
                    <FormLabel>
                        Név
                    </FormLabel>
                    <Input
                        sx={{marginTop: '5px !important'}}
                        id="input-name"
                        placeholder="Név"
                        onChange={handleInputChange("username")}
                        value={contactDetails.username}
                    />
                </FormControl>

                <FormControl
                    sx={{marginBottom: 3}}
                    required={true}
                    error={!!errorContactDetails.email}
                >
                    <FormLabel>
                        Email cím
                    </FormLabel>
                    <Input
                        id="input-email"
                        placeholder="Email cím"
                        onChange={handleInputChange("emailAddress")}
                        value={contactDetails.email}
                    />
                </FormControl>

                <FormControl
                    sx={{marginBottom: 3}}
                    required={true}
                    error={!!errorContactDetails.message}
                >
                    <FormLabel sx={{marginBottom: 1}}>
                        Megjegyzés
                    </FormLabel>

                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        onChange={handleInputChange("message")}
                        value={contactDetails.message}
                    />
                </FormControl>

                <LoadingButton
                    loading={messageIsSending}
                    variant='contained'
                    onClick={handleSubmit}
                >
                    <span>Elküldöm</span>
                </LoadingButton>
            </Stack>

            <Footer />

            <Snackbar
                open={snackbarProperties.open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => {
                    setSnackbarProperties((prevState) => {
                        return { ...prevState, open: false };
                    });
                }}

            >
                <Alert severity={snackbarProperties.success ? "success" : "error"} sx={{ width: '100%' }}>
                    {snackbarProperties.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default ContactUsPage;