import React, {useState} from 'react';
import {Box, Dialog, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 500,
//     bgcolor: 'white',
//     boxShadow: 24,
//     p: 4,
//     borderRadius: 6
// };


const ImageModal = ({imageSrc} : {imageSrc: string}) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>

            <Box
                sx={{'&:hover': {cursor: "pointer", backgroundColor: 'rgba(216,220,220,0.58)'}, borderRadius: 6}}
            >
                <img width="120px" height="120px" src={imageSrc} onClick={handleOpen} />
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { borderRadius: 8 } }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Stack
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={imageSrc} onClick={handleOpen} />
                </Stack>
            </Dialog>
        </React.Fragment>
    );
};

export default ImageModal;