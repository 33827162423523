import {useEffect, useRef, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getProducts, getSelectedProduct} from "../redux/action/product";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {colors, images, ItemTypeSelect} from "../components/planning/selectors/ItemTypeSelect";
import {RootState} from "../redux/store";
import {ColorOption, ItemType, ItemTypeOption, SizeOption, hasMultipleColor, hasMultipleSize} from "../types";
import {ColorSelect} from "../components/planning/selectors/ColorSelect";
import {SizeSelect} from "../components/planning/selectors/SizeSelect";
import {PredefinedProduct, productActions} from "../redux/productSlice";
import {useTheme} from "@mui/material/styles";
import Footer from "../components/Footer";
import isEmpty from "../utils/isEmpty";
import {fabric} from "fabric";
import SizeTable from "../components/planning/SizeTable";
import {OrderItem} from "../redux/orderSlice";
import {addOrderItem} from "../redux/action/order";
import {getItemNames} from "../redux/planSelectors";

// @ts-ignore
import domToImage from 'dom-to-image-more'
import {productPriceSelector} from "../redux/productSelectors";
import { OrderAlertDialog } from '../components/planning/OrderAlertDialog';
import {Helmet} from "react-helmet";

const ProductPage = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [searchParams]: [URLSearchParams, Function] = useSearchParams();

    const canvasContainerRef = useRef<HTMLDivElement>(null)

    const [isSizeTableOpen, setSizeTableOpen] = useState<boolean>(false);
    const [itemType, setItemType] = useState<ItemTypeOption>(ItemType.TSHIRT);
    const [color, setColor] = useState<ColorOption>("white");
    const [size, setSize] = useState<SizeOption | undefined>("s");
    const [canvas, setCanvas] = useState<fabric.Canvas>()
    const [canvasWidth, setCanvasWidth] = useState<number>(0)
    const [canvasHeight, setCanvasHeight] = useState<number>(0)
    const [image, setImage] = useState<fabric.Image>()

    const [orderAlertOpen, setOrderAlertOpen] = useState(false)


    const selectedProduct: PredefinedProduct = useSelector<RootState, PredefinedProduct>(rootState => rootState.product.selectedProduct);
    const getProductPrice = useSelector((state: RootState) => productPriceSelector(state.product))

    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])

    useEffect(() => {
        const productId = searchParams.get("productId");

        if (productId != null) {
            dispatch(getSelectedProduct(productId))
        }

        if (isEmpty(canvas)) {
            setCanvas(new fabric.Canvas('c'))
        }

        return () => {
            dispatch(productActions.clearSelectedProduct())
        }
    }, [canvas, dispatch, searchParams])

    useEffect(() => {
        const updateCanvasSize = () => {
            const width = canvasContainerRef.current ? canvasContainerRef.current.offsetWidth : 0
            const height = canvasContainerRef.current ? canvasContainerRef.current.offsetHeight : 0
            setCanvasWidth(width)
            setCanvasHeight(height)
        }
        updateCanvasSize()

        window.addEventListener('resize', updateCanvasSize)

        return () => window.removeEventListener('resize', updateCanvasSize);
    }, [selectedProduct, itemType])

    useEffect(() => {
        if (!canvas) return

        if (!isEmpty(selectedProduct)) {
            fabric.Image.fromURL(selectedProduct.fileURL, image => {
                setImage(image)
                image.scaleToHeight(canvas.getWidth());
                image.scaleToWidth(canvas.getWidth());
                image.set({ selectable: false});

                canvas.centerObject(image)
                canvas.add(image)
                canvas.renderAll()
            })
        }

        return () => {
            canvas.clear()
            canvas.off('object:moving')
            canvas.off('object:scaling')
            canvas.off('object:modified')
        }
    }, [canvas, selectedProduct])

    useEffect(() => {
        if (!canvas) return
        canvas.setWidth(canvasWidth)
        canvas.setHeight(canvasHeight)
        if (image) {
            image.scaleToWidth(canvasWidth)
            image.scaleToHeight(canvasWidth)
            canvas.centerObject(image)
        }
    }, [canvasWidth, canvasHeight, canvas, image, selectedProduct])

    useEffect(() => {
        if (!colors[itemType].availableColors.includes(color)) setColor('white')
    }, [color, itemType])

    const addToCartHandler = async () => {

        const node = document.getElementById('edited-product');
        let itemPreviewImage: string = "";

        try {
            const dataUrl: string = await domToImage.toPng(node);
            itemPreviewImage = dataUrl;
        } catch (error) {
            console.error('oops, something went wrong!', error);
        }

        const [summarizer1, summarizer2] = getItemNames(itemType, undefined, color, size)

        const orderItem: OrderItem = {
            //TODO: biztonsagosra csinalni
            predefinedProductId: selectedProduct.id,
            itemPrice: getProductPrice(itemType)!,
            designPrice: 0,
            quantity: 1,
            imageId: selectedProduct.localImageId,
            imageURL: selectedProduct.fileURL,
            itemType: itemType as ItemType,
            tShirtSize: size ?? null,
            tShirtColor: color,
            productName: summarizer2 === '' ? `${selectedProduct.name + summarizer1}` : `${selectedProduct.name + summarizer1} (${summarizer2})`,
            itemPreviewImage: itemPreviewImage,
            position: null
        }

        dispatch(addOrderItem(orderItem));
    }

    return (
        <div>

            <Helmet>
                <title>{`Egyedi ${selectedProduct?.name !== undefined ? selectedProduct?.name : ""} minta - AI Merch`}</title>
                <meta name="description" content={`Egyedi ${selectedProduct?.name !== undefined ? selectedProduct?.name : ""} mintás póló, bögre és egyéb ajándékok. Használd egyedi mesterséges intelligencia tervezőnket.`} />
            </Helmet>

            <Grid container sx={{p: 4, minHeight: 'calc(100vh - 64px - 197.17px)'}}>
                <Grid item xs={false} xl={1}/>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={{
                    display: 'flex',
                    zIndex: 4,
                    padding: 2,
                    paddingRight: 5,
                    paddingLeft: 5,
                    width: '100%',
                    justifyContent: 'end',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center !important'
                    }
                }}>
                    <Grid
                        item
                        sx={{
                            position: 'sticky',
                            top: 80,
                            height: 'fit-content',
                            width: '100%',
                            // margin: 3,
                            marginLeft: 5,
                            // padding: 1,
                            borderRadius: 8,
                            backgroundColor: '#ffffff66',
                            boxShadow: '#BFBFBE 0 -13px 50px',
                            [theme.breakpoints.only('xs')]: {
                                marginRight: 10
                            },
                            [theme.breakpoints.between('xs', 'lg')]: {
                                maxWidth: 500,
                            },
                            [theme.breakpoints.only('lg')]: {
                                maxWidth: 650,
                            },
                            [theme.breakpoints.only('xl')]: {
                                maxWidth: 800,
                            }
                        }}
                    >
                        <Grid item xs={12}>
                            <div
                                id="edited-product"
                            >
                                <img src="./tee_and_mug.png" width='100%' alt="" hidden={!!itemType}/>

                                {itemType && color &&
                                    <img src={colors[itemType].availableColors.includes(color) ? images[itemType][color] : images[itemType]['white']} width='100%' className="product" alt="" />}

                                {itemType && (
                                    <div id='imageRect' style={{
                                        position: 'absolute',
                                        padding: '2px',
                                        width: itemType === ItemType.CANVAS_BAG || itemType === ItemType.MUG ? '35%' : '28%',
                                        height: itemType === ItemType.CANVAS_BAG || itemType === ItemType.MUG ? '35%' : '48%',
                                        left: '50%',
                                        top: itemType === ItemType.HOODIE ? '40%' : itemType === ItemType.CANVAS_BAG ? '64%' : '48%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                    }}>
                                        <div id='my-canvas-container' ref={canvasContainerRef} style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            userSelect: 'none'
                                        }}>
                                            <canvas id='c' style={{
                                                position: 'absolute',
                                                width: 50,
                                                height: 50,
                                                top: 0,
                                                left: 0,
                                                userSelect: 'none',
                                                cursor: 'default'
                                            }}></canvas>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    [theme.breakpoints.down('md')]: {
                        alignItems: 'center'
                    },
                    paddingRight: 5,
                    paddingLeft: 5,
                }}>
                    <Box width='100%' maxWidth='500px'>
                        <Typography
                            variant='subtitle2'
                            fontWeight='bold'
                            fontSize={18}
                            textAlign='center'
                        >
                            {selectedProduct.name}
                        </Typography>

                        <ItemTypeSelect
                            selected={itemType}
                            setSelected={value => value && setItemType(value)}
                            color={color}
                        />
                        <Box width='100%'>

                            {hasMultipleColor(itemType) && <ColorSelect
                                selected={color}
                                setSelected={value => value && setColor(value)}
                                itemType={itemType}
                            />}
                            {hasMultipleSize(itemType) && <SizeSelect
                                selected={size}
                                setSelected={value => setSize(value)}
                                openSizeChart={() => setSizeTableOpen(true)}
                                itemType={itemType}
                            />}
                        </Box>

                        <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{ml: 2, mr: 2}}
                        >
                            <Typography
                                sx={{fontWeight: 'bold', fontSize: 18}}
                            >
                                Ár: {getProductPrice(itemType)} Ft
                            </Typography>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    addToCartHandler()
                                    setOrderAlertOpen(true)
                                }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    fontSize={16}
                                >
                                    Kosárba teszem
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={false} xl={1} />
            </Grid>

            <SizeTable
                open={isSizeTableOpen}
                selectedItemType={itemType}
                onClose={() => setSizeTableOpen(false)}
            />

            <OrderAlertDialog orderAlertOpen={orderAlertOpen} setOrderAlertOpen={setOrderAlertOpen} predefined />

            <Stack
                sx={{width: '100%', mt: 2, position: 'relative', bottom: 0}}
            >
                <Footer/>
            </Stack>

        </div>
    );
};

export default ProductPage;
