import { colors } from "../components/planning/selectors/ItemTypeSelect";
import {ColorOption, DesignTypeOption, ItemType, ItemTypeOption, PlanNumberOption, SizeOption} from "../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface PlanState {
    itemType?: ItemTypeOption
    designType?: DesignTypeOption
    selectedPlan?: PlanNumberOption
    color: ColorOption
    size?: SizeOption
    prompt: string
    generation: Generation,
    premiumGeneration: Generation
    generationState: GenerationState
    premiumGenerationState: GenerationState
    text?: Text
}

export enum GenerationState {
    NotStarted,
    Started,
    Warning,
    Error,
    Done
}

export interface Generation {
    generationId?: string
    images: Image[]
    eta?: number
    progress: number
}

export interface Image {
    id: string
    link: string
}

export interface Text {
    value: string
    color: string
    font: string
}

const initialPlanState: PlanState = {
    itemType: ItemType.TSHIRT,
    designType: 'custom',
    selectedPlan: undefined,
    color: 'white',
    size: undefined,
    prompt: '',
    generation: { images: [], progress: 0 },
    generationState: GenerationState.NotStarted,
    premiumGeneration: { images: [], progress: 0 },
    premiumGenerationState: GenerationState.NotStarted
}

const planSlice = createSlice({
    name: 'plan',
    initialState: initialPlanState,
    reducers: {
        setItemType(state, action: PayloadAction<{itemType: ItemTypeOption | undefined, color: ColorOption | undefined}>) {
            const {itemType, color} = action.payload
            if (itemType && color && colors[itemType].availableColors.includes(color)) {
                state.color = color
            } else {
                state.color = 'white'
            }
            state.itemType = action.payload.itemType
            state.size = undefined
        },
        setDesignType(state, action: PayloadAction<DesignTypeOption | undefined>) {
            state.designType = action.payload
        },
        setPrompt(state, action: PayloadAction<string>) {
            state.prompt = action.payload
        },
        setSelectedPlan(state, action: PayloadAction<PlanNumberOption | undefined>) {
            state.selectedPlan = action.payload
        },
        setColor(state, action: PayloadAction<ColorOption>) {
            state.color = action.payload
        },
        setSize(state, action: PayloadAction<SizeOption | undefined>) {
            state.size = action.payload
        },
        setGenerationState(state, action: PayloadAction<{state: GenerationState, premium?: boolean}>) {
            if (action.payload.premium) state.premiumGenerationState = action.payload.state
            else state.generationState = action.payload.state
        },
        addGeneration(state, action: PayloadAction<{ id?: string, images: Image[], premium?: boolean, prompt: string }>) {
            if (action.payload.premium) {
                state.premiumGeneration.images = action.payload.images
                state.premiumGeneration.generationId = action.payload.id
            } else {
                state.generation.images = action.payload.images
                state.generation.generationId = action.payload.id
            }
            state.prompt = action.payload.prompt
        },
        resetGeneration(state, action: PayloadAction<{premium?: boolean}>) {
            const reset = { prompt: state.prompt, images: [], progress: 0 }
            if (action.payload.premium) state.premiumGeneration = reset
            else state.generation = reset
        },
        setEta(state, action: PayloadAction<number>) {
            state.generation.eta = action.payload
        },
        setProgress(state, action: PayloadAction<{progress: number, premium?: boolean}>) {
            if (action.payload.premium) state.premiumGeneration.progress = action.payload.progress
            state.generation.progress = action.payload.progress
        },
        setText(state, action: PayloadAction<Text | undefined>, ) {
            state.text = action.payload
        },
        resetState(state) {
            return initialPlanState
        }
    }
})

export const planActions = planSlice.actions
export default planSlice
