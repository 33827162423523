import React from 'react';
import {Button, Divider, Grid, Stack, Typography} from "@mui/material";
import {OrderItem} from "../../redux/orderSlice";
import OrderItemRow from "./OrderItemRow";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

const OrderItems = (props: {textFontSize: number, titleFontSize: number, smallDesign: boolean, buttonFontSize: number, orderButtonHandler: () => void}) => {

    const theme = useTheme();
    const navigation = useNavigate();

    const cart: OrderItem[] = useSelector<RootState, OrderItem[]>(state => state.order.items);
    const totalPrice: number = useSelector<RootState, number>(state => state.order.totalPrice);
    const discountPercentage: number = useSelector<RootState, number>(state => state.order.discountPercentage);

    return (
        <Grid container>
            {cart.map((item: OrderItem, index: number) => {
                return (
                    <Grid item xs={12} key={index}>
                        <OrderItemRow item={item} index={index} textFontSize={props.textFontSize} smallDesign={props.smallDesign}/>
                        <Divider sx={{mt: 2, mb: 2}}/>
                    </Grid>
                );
            })}

            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end', mb: 10}}>

                <Stack direction='column' justifyContent='center' alignItems='center'>

                    {discountPercentage === 0 ? (
                        <Typography
                            variant='subtitle2'
                            sx={{fontWeight: 'bold', fontSize: props.titleFontSize}}
                        >
                            {totalPrice} Ft
                        </Typography>
                    ) : (
                        <Stack
                            direction='column'
                            alignItems='center'
                        >
                            <Typography
                                variant='subtitle2'
                                sx={{fontWeight: 'bold', fontSize: 15, position: "relative",
                                    "&:after": {content: '""', width: "100%", position: "absolute", right: 0, top: "50%", borderBottom: "1px solid", transform: "skewY(-10deg)"}
                                }}
                            >
                                {totalPrice} Ft
                            </Typography>

                            <Typography
                                variant='subtitle2'
                                sx={{fontWeight: 'bold', fontSize: props.titleFontSize, color: "#E43A36"}}
                            >
                                {Math.floor(totalPrice * ((100 - discountPercentage) / 100))} Ft
                            </Typography>
                        </Stack>
                    )}

                    <Button
                        onClick={props.orderButtonHandler}
                        variant='contained'
                    >
                        <Typography
                            variant='subtitle2'
                            sx={{fontSize: props.buttonFontSize}}
                        >
                            Megrendelem
                        </Typography>
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default OrderItems;
