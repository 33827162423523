import React from 'react';
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {getSocialLoginUrl} from "../utils/helper";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const theme = useTheme();

    return (
        <React.Fragment>
            <Stack
                alignItems='center'
                sx={{
                    height: 'calc(100vh - 64px)',
                    width: '100%',
                    backgroundImage: 'url(http://localhost:3000/logo.png)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Stack direction='column' alignItems='center'>
                    <Paper
                        sx={{borderRadius: 3, mt: 8, p: 2}}
                    >
                        <Box
                            sx={{m: 4, maxWidth: 350}}
                        >
                            <Typography
                                sx={{fontWeight: 'bold', fontSize: 20, mb: 4, textAlign: 'center'}}
                            >
                                Bejelentkezés
                            </Typography>
                            <TextField
                                fullWidth={true}
                                id="username-input-id"
                                label="Felhasználó név"
                                variant="standard"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircleIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth={true}
                                id="password-input-id"
                                label="Jelszó"
                                variant="standard"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 1}}
                            />

                            <Box
                                sx={{textAlign: 'right', mb: 2, color: '#212B36'}}
                            >
                                Elfelejtett jelszó?
                            </Box>

                            <Button
                                fullWidth={true}
                                variant='contained'
                            >
                                Bejelentkezés
                            </Button>

                            <Divider
                                sx={{mt: 4, mb: 4}}
                            >
                                vagy lépj be a fiókodba
                            </Divider>

                            <Stack direction='row' justifyContent='center'>
                                <IconButton
                                    href={getSocialLoginUrl('google')}
                                >
                                    <GoogleIcon />
                                </IconButton>
                                <IconButton
                                    // href={getSocialLoginUrl('')}
                                >
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton
                                    href={getSocialLoginUrl('github')}
                                >
                                    <GitHubIcon />
                                </IconButton>
                            </Stack>

                            <Stack direction='column' justifyContent='center'>
                                <Box
                                    sx={{textAlign: 'center', mt: 3, color: '#212B36'}}
                                >
                                    vagy jelentkezz be
                                </Box>
                                <Button>
                                    Regisztrálás
                                </Button>
                            </Stack>

                        </Box>
                    </Paper>
                </Stack>
            </Stack>
        </React.Fragment>
    );
};

export default LoginPage;