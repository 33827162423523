import React, {useEffect, useState} from 'react';
import {Box, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import {ConstantParams} from "../config/Constants";
import { FaTiktok, FaInstagram } from 'react-icons/fa';
import {useTheme} from "@mui/material/styles";

import smallBarionCard from '../assets/barion/barion-card-strip-intl__small.png'

const Footer = () => {

    const theme = useTheme();


    return (
        <Box
            sx={{
                width: "100%",
                marginTop: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? "rgb(32 104 104 / 73%)"
                        : theme.palette.grey[800],
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{display: 'flex', pt: 2, pb: 2, pl: 2, pr: 2}}
            >
                <Grid item xs={12} sm={5} md={5}
                    sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    <Stack
                        direction='column'
                        justifyContent='center'
                        sx={{
                            width: '100%',
                            [theme.breakpoints.down('sm')]: {
                                alignItems: 'center'
                            }
                        }}
                    >
                        <Stack direction='row'>
                            <img src="/logo-no-background.png" style={{height: 32, marginRight: 8}} alt=''/>
                            <Typography
                                variant="h6"
                                noWrap
                                component={NavLink}
                                to="/"
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    textDecoration: 'none',
                                }}
                            >
                                {ConstantParams.COMPANY_NAME}
                            </Typography>
                        </Stack>

                        <Typography
                            sx={{
                                fontSize: 12,
                                mt: 0.5
                            }}
                        >
                            AI tervezte termékek rendelése
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mr: 2,
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                                mt: 0.5
                            }}
                        >
                            @2023 aimerch.hu
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={7} md={7}
                    sx={{display: 'flex'}}
                >
                    <Stack
                        direction='row'
                        sx={{width: '100%'}}
                        justifyContent='space-around'
                    >
                        <Stack direction='column'>
                            <Typography
                                variant="subtitle2"
                                sx={{fontSize: 16, fontWeight: 'bold'}}
                            >
                                Termék
                            </Typography>

                            <Link to="/tervezes" color="primary">
                                <Typography
                                    sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                >
                                    Tervező
                                </Typography>
                            </Link>

                            <Link to="/#gyakori-kerdesek" color="primary">
                                <Typography
                                    sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                >
                                    Kérdések
                                </Typography>
                            </Link>
                        </Stack>

                        <Stack
                            direction='column'
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{fontSize: 16, fontWeight: 'bold'}}
                            >
                                AI Merch
                            </Typography>

                            <Link to="/kapcsolat" color="primary">
                                <Typography
                                    sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                >
                                    Rólunk
                                </Typography>
                            </Link>

                            <Link to="/kapcsolat" color="primary">
                                <Typography
                                    sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                >
                                    Kapcsolat
                                </Typography>
                            </Link>

                            <Link to="/" color="primary">
                                <Typography
                                    sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                >
                                    Blog
                                </Typography>
                            </Link>
                        </Stack>

                        <Stack
                            direction='column'
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{fontSize: 16, fontWeight: 'bold'}}
                            >
                                Kövess minket
                            </Typography>

                            <a
                                href="https://www.instagram.com/aimerch.hu" target="_blank"
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                >
                                    <FaInstagram style={{marginRight: 5}}/>
                                    <Typography
                                        sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                    >
                                        Instagram
                                    </Typography>
                                </Stack>
                            </a>

                            <a
                                href="https://www.tiktok.com/@aimerch.hu" target="_blank"
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                >
                                    <FaTiktok style={{marginRight: 5}}/>
                                    <Typography
                                        sx={{fontSize: 14, '&:hover': { textDecoration: 'underline' }}}
                                    >
                                        Tiktok
                                    </Typography>
                                </Stack>
                            </a>
                        </Stack>
                    </Stack>
                </Grid>

                <Stack
                    direction='row'
                    sx={{mt: 2, mb: 1, width: '100%', flexWrap: 'wrap'}}
                >
                    <img src={smallBarionCard} style={{marginRight: 8, maxWidth: '100%', marginTop: 5}} alt=''/>
                    <img src="/foxpost_logo_red.png" style={{height: 32, marginRight: 8, marginTop: 5}} alt=''/>
                </Stack>
            </Grid>
        </Box>
    );
};

export default Footer;
