import {
    orderActions,
    OrderItem,
    OrderProgress,
    PaymentStatus,
    Position
} from "../orderSlice";
import axios, {AxiosError, AxiosResponse} from "axios"
import {RequestError} from "./GeneralResponses";
import ReactGA from "react-ga4";
import {ColorOption, ItemType, SizeOption} from "../../types";
import {green} from "@mui/material/colors";

export const addOrderItem = (orderItem: OrderItem): any => {
    return async (dispatch: any) => {
        dispatch(orderActions.addItem(orderItem));
    }
}

export interface OrderAddress {
    zipCode?: number;
    city?: string;
    street?: string;
    otherAddressDetails?: string;
}

export interface ShippingBoxAddress {
    place_id: number,
    operator_id: string,
    name: string,
    vapt: string,
    olapt: string,
    japt: string,
    ssapt: string,
    sdapt: string,
    group: string,
    address: string,
    zip: string,
    city: string,
    street: string,
    findme: string,
    geolat: number,
    geolng: number,
    open: {
        hetfo: string,
        kedd: string,
        szerda: string,
        csutortok: string,
        pentek: string,
        szombat: string,
        vasarnap: string
    }
}

export interface TShirtVariant {
    color: ColorOption
    size: SizeOption
}

export type RequestOrderItem = {
    productId: string
    imageId?: string
    position?: Position | null
    quantity: number
    productType: ItemType
    variant?: any,
    itemPreviewImage: string
}


export interface OrderRequest {
    firstName: string
    lastName: string
    emailAddress: string
    phoneNumber: string
    personalPickup: boolean
    shippingBoxAddress: ShippingBoxAddress
    shippingAddress?: OrderAddress
    billingAddress: OrderAddress
    items: RequestOrderItem[],
    couponCode: string
}

interface OrderResponse {
    orderId: string
    gatewayUrl: string
    emailSentSuccess: boolean
    paymentStatus: PaymentStatus
}

export const sendOrderRequest = (orderRequest: OrderRequest): any => {
    return async (dispatch: any) => {

        dispatch(orderActions.setPaymentStatus({paymentStatus: PaymentStatus.OrderPreparing, orderId: ""}))

        axios.post('/order', orderRequest)
            .then((res: AxiosResponse<OrderResponse>) => {
                dispatch(orderActions.setPaymentStatus({
                    paymentStatus: PaymentStatus.OrderSuccess,
                    orderId: res.data.orderId
                }))
                dispatch(orderActions.clearOrder());
                window.location.replace(res.data.gatewayUrl);

                // Send a custom event
                ReactGA.event({
                    category: "conversion",
                    action: "purchase",
                    // label: "your label", // optional
                    // value: 99, // optional, must be a number
                    // nonInteraction: true, // optional, true/false
                    // transport: "xhr", // optional, beacon/xhr/image
                });
            })
            .catch((err: AxiosError<RequestError>) => {
                dispatch(orderActions.setPaymentStatus({paymentStatus: PaymentStatus.OrderError, orderId: ""}))
                dispatch(orderActions.setOrderErrorMessage("Hiba rendelés feldolgozása közben."));
            });
    }
}

interface PaymentStatusResponse {
    paymentStatus: PaymentStatus;
    orderId: string;
    gatewayUrl: string;
}

export const getOrderStatusByPaymentId = (paymentId: string, onRequestFinished: () => void): any => {
    return async (dispatch: any) => {

        dispatch(orderActions.setPaymentStatus({paymentStatus: PaymentStatus.GetStatus, orderId: ""}))

        axios.get('/order/payment/' + paymentId)
            .then((res: AxiosResponse<PaymentStatusResponse>) => {
                dispatch(orderActions.setPaymentStatus({
                    paymentStatus: res.data.paymentStatus,
                    orderId: res.data.orderId,
                    gatewayUrl: res.data.gatewayUrl
                }));

                if (res.data.paymentStatus === PaymentStatus.Prepared) {
                    window.location.replace(res.data.gatewayUrl);
                }
                onRequestFinished();
            })
            .catch((err: AxiosError<RequestError>) => {
                onRequestFinished();
            });
    }
}

interface OrderStatusResponse {
    paymentStatus: PaymentStatus;
    orderId: string;
    gatewayUrl: string;
    firstName: string;
    lastName: string;
    address: string;
    personalPickup: boolean;
    orderProgress: OrderProgress;
    orderDate: string;
    totalPrice: number;
}

export const getOrderStatusByOrderId = (orderId: string): any => {
    return async (dispatch: any) => {

        axios.get('/order/' + orderId)
            .then((res: AxiosResponse<OrderStatusResponse>) => {
                dispatch(orderActions.setOrderState({
                    paymentStatus: res.data.paymentStatus,
                    orderId: res.data.orderId,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    address: res.data.address,
                    personalPickup: res.data.personalPickup,
                    orderProgress: res.data.orderProgress,
                    orderDate: res.data.orderDate,
                    totalPrice: res.data.totalPrice
                }));

                if (res.data.paymentStatus === PaymentStatus.Prepared) {
                    window.location.replace(res.data.gatewayUrl);
                }

                //User checked the order status
                ReactGA.event({category: "conversion", action: "checkOrderStatus"});

            })
            .catch((err: AxiosError<RequestError>) => {
            });
    }
}

interface CountDiscountRequest {
    couponCode: string;
    grandTotal: number;
}

export interface CountDiscountResponse {
    validCode: boolean;
    couponCode: string;
    originalGrandTotal: number;
    discountGrandTotal: number;
    savedAmount: number;
    discountPercentage: number;
}

export const getDiscount = (couponCode: string, grantTotal: number, onCouponValid: (valid: boolean) => void): any => {
    return async (dispatch: any) => {

        const request: CountDiscountRequest = {
            couponCode: couponCode,
            grandTotal: grantTotal
        }

        axios
            .post('/discount/count', request)
            .then((res: AxiosResponse<CountDiscountResponse>) => {

                onCouponValid(res.data.validCode)

                if (res.data.validCode) {
                    dispatch(orderActions.setDiscount({
                        discountPercentage: res.data.discountPercentage,
                        couponCode: res.data.couponCode
                    }))
                }

                // Send a custom event
                ReactGA.event({
                    category: "conversion",
                    action: "countDiscount",
                    // label: "your label", // optional
                    // value: 99, // optional, must be a number
                    // nonInteraction: true, // optional, true/false
                    // transport: "xhr", // optional, beacon/xhr/image
                });
            })
            .catch((err: AxiosError<RequestError>) => {
            });
    }
}
