import { Box, Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Lottie from 'react-lottie-player'
import underConstructionAnimation from '../assets/under-construction.json'

const ComingSoonPage = () => {
    const theme = useTheme()
    const navigation = useNavigate()

    return (
        <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 64px)',
            flexDirection: 'column',
            paddingRight: 20,
            paddingLeft: 20
        }}>
            <Lottie
                loop
                animationData={underConstructionAnimation}
                play
                style={{ width: 320 }}
            />
            <Typography fontSize={40} color={theme.palette.primary.main} fontWeight={600} marginBottom={1}>Hamarosan!</Typography>
            <Typography fontSize={18} textAlign='center'>
                Weboldalunk jelenleg még fejlesztés alatt áll, így csak egy minimális funkcionalitás érhető el!<br />
                Ha bármilyen hibát észlesz, vagy egyéb megjegyzésed van a honlappal kapcsolatban, 
                kérlek jelezd felénk a <a style={{ color: theme.palette.primary.main, fontWeight: '600' }} href = "mailto: rendeles@aimerch.hu">rendeles@aimerch.hu</a> címen! 
                Köszönjük a segítséged!
            </Typography>
            <Button variant='contained' onClick={() => navigation('/')} sx={{
                marginTop: 3
            }}>
                <Typography>
                    Vissza a főoldalra
                </Typography>
            </Button>
        </Box>
    )
}

export default ComingSoonPage
