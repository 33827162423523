import {createTheme, responsiveFontSizes, Theme} from '@mui/material/styles'
import colors from './colors'

declare module '@mui/material/styles' {
    interface Theme {
        custom: {

        };
    }

    interface Palette {
        customButton: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        customButton?: PaletteOptions['primary'];
    }

    interface ThemeOptions {
        custom?: {

        };
    }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        customButton: true;
    }
}

const theme = (isLight: boolean) => {
    let muiTheme:Theme = createTheme({
        palette: {
            mode: isLight ? 'light' : 'dark',
            background: {
                default: isLight ? colors.lightBackground : colors.darkBackground,
                paper: isLight ? colors.white : colors.lightDarkBackground,
            },
            divider: isLight ? colors.lightDivider : colors.darkDivider,
            text: {
                primary: isLight ? colors.lightText1 : colors.primaryText,
                secondary: isLight ? colors.lightText2 : colors.darkText2,
                disabled: isLight ? colors.lightText3 : colors.darkText3
            },
            primary: {
                main: isLight ? colors.primary : colors.primaryDark,
                dark: isLight ? "rgb(41, 41, 81)" : "#a7a7a9",
                light: colors.primaryLight
            },
            secondary: {
                main: colors.secondary,
                dark: colors.secondaryDark,
                light: colors.secondaryLight
            },
            customButton: {
                main: 'red',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: "Lexend",
            button: {
                textTransform: 'none',
            },
        },
        spacing: (factor:number) => `${0.5 * factor}rem`,
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: isLight ? colors.lightAppBar : colors.darkAppBar,
                        color: isLight ? colors.lightText1 : colors.darkText1,
                    },
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: isLight ? colors.lightHighContrastText : colors.darkHighContrastText,
                        ":focus": { // increase the specificity for the pseudo class
                            color: "blue"
                        }
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 10
                        // backgroundColor: isLight ? colors.lightButtonPrimaryBackgroundColor : colors.darkButtonPrimaryBackgroundColor,
                        // color: isLight ? colors.lightButtonPrimaryTextColor : colors.darkButtonPrimaryTextColor,
                        // ":hover": {
                        //     backgroundColor: isLight ? "rgb(41, 41, 81)" : "#a7a7a9"
                        // },
                    }
                }
            }
        },
        custom: {

        },
    })

    return muiTheme = responsiveFontSizes(muiTheme)
}

export default theme
