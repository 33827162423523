import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close'
import { DesignTypeOption } from "../../types"


export function OrderAlertDialog({ orderAlertOpen, setOrderAlertOpen, resetState, predefined }:
{
    orderAlertOpen: boolean
    setOrderAlertOpen: (value: boolean) => void
    resetState?: () => void
    predefined?: boolean
}) {
    const navigation = useNavigate()

    const text = predefined
    ? 'Folytasd a megrendelést az adataid megadásával, vagy vásárolj további termékeket!'
    : 'Folytasd a megrendelést az adataid megadásával, vagy folytasd a tervezést!' 

    const buttonText = predefined ? 'Tovább a vásárolok' : 'Tovább tervezek'
    

    return (
        <Dialog open={orderAlertOpen} onClose={() => setOrderAlertOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>Rendelésed sikeresen bekerült a kosárba!</Typography>
                <IconButton
                aria-label="close"
                onClick={() => setOrderAlertOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box sx={{ 
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                maxWidth: 400,
                paddingLeft: 5,
                paddingRight: 5
            }}>
                <Typography sx={{textAlign: 'center'}}>{text}</Typography>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: 3
                }}>
                    <Button variant='contained' onClick={() => {
                        setOrderAlertOpen(false)
                    }}><Typography>{buttonText}</Typography></Button>
                    <Button variant='contained' onClick={() => {
                        if (resetState !== undefined) resetState()
                        setOrderAlertOpen(false)
                        navigation('/kosar')
                    }}><Typography>Megrendelés</Typography></Button>
                </Box>
            </Box>
        </Dialog>

    )
}

