import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Dialog,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material"
import {ColorOption, OptionParams} from "../../../types"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import isEmpty from "../../../utils/isEmpty";
import { Close, OpenInFullRounded } from "@mui/icons-material";

function handleChange<T>(event: React.MouseEvent<HTMLElement>, nextView: T, setter: (value: T) => void) {
    setter(nextView)
}

export function CustomSelect<Options extends string>({ selected, setSelected, params, horizontal, color, SizeChartButton, noMargin, image, accordionTitle, itemColor, nrInRow }:
    {
        selected: Options | undefined
        setSelected: (option: Options | undefined) => void
        params: OptionParams<Options>
        horizontal?: boolean
        color?: boolean
        SizeChartButton?: JSX.Element,
        noMargin?: boolean,
        image?: boolean,
        accordionTitle?: string,
        itemColor?: ColorOption | undefined,
        nrInRow?: number
    }) {
        const [ expandAccordion, setExpandAccordion ] = useState(false)
        const [ fullScreenImage, setFullScreenImage ] = useState<string | undefined>(undefined)

        const keys = Object.keys(params.names) as Array<Options>
        const optionButtons = keys.map((option: Options) => {

            let itColor: ColorOption = 'white';

            if (itemColor !== undefined && !isEmpty(params?.images?.[option]?.[itemColor])) {
                itColor = itemColor;
            } else {
                itColor = 'white';
            }

            return (
                <ToggleButton
                    value={option}
                    key={option}
                    sx={{
                        flexDirection: horizontal ? 'column': 'row',
                        justifyContent: horizontal ? 'center' : 'space-between',
                        paddingLeft: horizontal ? 0 : 2,
                        backgroundColor: color ? `${params.descriptions![option]} !important` : '#ffffff66',
                        paddingTop: image ? 0 : 1.5,
                        paddingBottom: image ? 0 : 1.5
                    }}
                >
                    {!image ? <>

                        {params.images && <img style={{ borderRadius: 15 }} src={itemColor ? params.images[option][itColor] : ''} alt='' height='80'/>}

                        <Typography sx={{
                            fontSize: 15,
                            color: color ? '#00000000 !important' : 'black'}}>
                                {params.names[option] }
                        </Typography>

                        {params.descriptions && params.descriptions[option] !== '' && !color &&
                            <Typography sx={{
                                fontSize: 15,
                                marginRight: horizontal ? 0 : 2}}>
                                    {params.descriptions[option]}
                            </Typography>}
                    </> : <><Box position="relative" style={{ width: '100%', padding: 0, borderRadius: 13, overflow: 'hidden' }}>
                        <img
                            style={{ width: '100%', height: '100%', padding: 0 }}
                            src={params.names[option]}
                            alt=""
                        />
                        <OpenInFullRounded
                            style={{ 
                                position: 'absolute', 
                                top: 0, 
                                right: 0, 
                                color: 'white',
                                margin: '4px',
                                padding: '4px',
                                backdropFilter: 'blur(2px)',
                                borderRadius: 8
                            }}
                            onClick={(event) =>{event.stopPropagation(); setFullScreenImage(params.names[option])} }
                        />
                    </Box></>}
                </ToggleButton>
            )
        })

        return (
            <Box sx={{
                width: '100%',
                marginBottom: noMargin ? 0 : 4
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {params.title !== '' ? <Typography sx={{
                        fontSize: 19,
                     }}>{params.title}</Typography> : ''}
                    {SizeChartButton}
                </Box>

                {accordionTitle ? (
                    <Accordion
                        sx={{
                            backgroundColor: '#ffffff66',
                            border: '2px solid #ccc',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            borderRadius: '15px !important',
                            marginTop: '10px !important',
                            '&:before': {backgroundColor: 'transparent !important'}
                        }}
                        expanded={expandAccordion}
                        onChange={() => setExpandAccordion(!expandAccordion)} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{accordionTitle}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <ToggleButtonGroup
                                value={selected}
                                orientation={horizontal ? 'horizontal' : 'vertical'}
                                exclusive
                                aria-label="Platform"
                                className="toggleButtonGroup"
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    '& .MuiToggleButton-root': {
                                        flexBasis: 'calc(33.33% - 8px)',
                                        marginRight: '8px',
                                        marginBottom: '8px'}
                                }}
                                onChange={(event, next) => {
                                    handleChange<Options>(event, next, setSelected);
                                    setExpandAccordion(false);
                                }}
                            >
                                {optionButtons}
                            </ToggleButtonGroup>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <ToggleButtonGroup
                        value={selected}
                        orientation={horizontal ? 'horizontal' : 'vertical'}
                        exclusive
                        aria-label="Platform"
                        className="toggleButtonGroup"
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& .MuiToggleButton-root': {
                                flexBasis: `calc(${nrInRow ? 100/ nrInRow : 33.33}% - 8px)`,
                                marginRight: '8px',
                                marginBottom: '8px'}
                        }}
                        onChange={(event, next) => handleChange<Options>(event, next, setSelected)}
                    >
                        {optionButtons}
                    </ToggleButtonGroup>
                )}
                {fullScreenImage &&
                    <Dialog
                        open={!isEmpty(fullScreenImage)}
                        onClick={() => setFullScreenImage(undefined)}
                        PaperProps={{
                            style: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <Box
                            sx={{p: 1, backgroundColor: "rgba(255,255,255,0) !important"}}
                        >
                            <img
                                style={{width: '100%', padding: 0, borderRadius: 20, border: '2px solid #ccc',}}
                                src={fullScreenImage}
                                alt="" />
                            <Close style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: 'white',
                                padding: '15px'
                            }} onClick={() => setFullScreenImage(undefined)}/>
                        </Box>
                    </Dialog>
                }
            </Box>
        )
    }
