import { createSelector } from "@reduxjs/toolkit"
import { Product, ProductState } from "./productSlice"
import { RootState } from "./store"
import {ItemType} from "../types";

const getProduct = (products: Product[], productType?: ItemType) => products.filter(product => product.productType === productType)[0]

export const productPriceSelector = createSelector(
    (state: ProductState) => state.products,
    (products) => (productType?: ItemType) => getProduct(products, productType)?.price || undefined
)

export const designPriceSelector = createSelector(
    (state: ProductState) => state.products,
    (products) => (productType?: ItemType) => getProduct(products, productType)?.generationPrice || undefined
)

export const deliveryPriceSelector = createSelector(
    (state: ProductState) => state.products,
    (products) => getProduct(products, ItemType.DELIVERY).price
)

export const boxDeliveryPriceSelector = createSelector(
    (state: ProductState) => state.products,
    (products) => getProduct(products, ItemType.BOX_DELIVERY).price
)

export const productIdSelector = createSelector(
    (state: ProductState) => state.products,
    (products) => (productType?: ItemType) => getProduct(products, productType).id
)

export const getPrice = createSelector(
    (state: RootState) => state.plan.itemType,
    (state: RootState) => state.plan.designType,
    (state: RootState) => state.product.products,
    (itemType, designType, products) => {
        if (!itemType || !designType) return 0

        const productPrice = getProduct(products, itemType)?.price

        const designPrice = designType === 'custom' ? getProduct(products, itemType)?.generationPrice : 0

        if (!productPrice || !designPrice) return 0

        return productPrice + designPrice
    }
)
