import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid, Stack,
    Step, StepButton,
    StepLabel,
    Stepper,
} from "@mui/material";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import ProductOverview from "../components/shopping-cart/ProductOverview";
import DeliveryDetails from "../components/shopping-cart/DeliveryDetails";
import OrderProcessing from "../components/shopping-cart/OrderProcessing";
import isEmpty from "../utils/isEmpty";
import {orderActions} from "../redux/orderSlice";
import {getOrderStatusByPaymentId} from "../redux/action/order";
import { getProducts } from '../redux/action/product';
import Footer from "../components/Footer";
import LinearProgressIndicator from "../components/LinearProgressIndicator";
import {useTheme} from "@mui/material/styles";
import {Helmet} from "react-helmet";

const steps = [
    'Rendelés áttekintése',
    'Szállítási adatok és fizetés',
    'Visszaigazolás',
];

const ShoppingCart = () => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const [orderStep, setOrderStep] = useState<number>(-1);
    const [searchParams, setSearchParams]: [URLSearchParams, Function] = useSearchParams();
    const [getOrderStatusLoading, setGetOrderStatusLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getProducts())
    }, [ dispatch ])

    useEffect(() => {

        let paymentId = searchParams.get("paymentId");

        if(!isEmpty(paymentId) && paymentId !== null) {
            setOrderStep(3);
            setGetOrderStatusLoading(true);
            dispatch(getOrderStatusByPaymentId(paymentId, onOrderStatusRequestFinished));
        } else {
            setOrderStep(0);
        }
    }, [searchParams])

    const handleStep = (step: number) => () => {
        if (step === 0 && orderStep !== 2) {
            setOrderStep(step);
        }
    };

    const increaseOrderStep = () => {
        if (orderStep < 3) {
            setOrderStep(step => {
                return step + 1;
            })
        }
    };

    const onOrderStatusRequestFinished = () => {
        setGetOrderStatusLoading(false);
    }

    return (

        <Grid container sx={{height: "calc(100vh - 64px)"}}>

            <Helmet>
                <title>Kosár - AI Merch - Egyedi póló, bögre és ajándékok az A.I. segítségével</title>
                <meta name="description" content="Egyedi póló, bögre és egyéb ajándékok. Használd egyedi mesterséges intelligencia tervezőnket, hogy létrehozz egy teljesen egyedi pólót, bögrét vagy egyéb ajándék terméket." />
            </Helmet>

            <Stack
                sx={{ width: '100%', mt: 4 }}
                alignItems='center'
            >
                <Box sx={{ width: '98%',
                        [theme.breakpoints.down('sm')]: {
                            width: '96%',
                        },
                    }}
                >
                    <Stepper activeStep={orderStep} alternativeLabel style={{marginBottom: 40}}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <StepLabel>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                {orderStep === 0 && (
                    <ProductOverview increaseOrderStep={increaseOrderStep}/>
                )}

                {orderStep === 1 && (
                    <DeliveryDetails increaseOrderStep={increaseOrderStep}/>
                )}

                {(orderStep === 2 || orderStep === 3) && (
                    (getOrderStatusLoading) ?
                        (
                            <LinearProgressIndicator />
                        ) : (
                            <OrderProcessing increaseOrderStep={increaseOrderStep}/>
                        )
                )}

            </Stack>

            <Footer />
        </Grid>
    );
};

export default ShoppingCart;
